import React, { useEffect, useMemo } from 'react';

import { ButtonBase, Divider, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { blueGrey, deepPurple } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import {
  IconArrowRight,
  IconCalendar,
  IconCalendarOff,
  IconClock,
  IconPaw,
  IconUser,
  IconX,
} from '@tabler/icons-react';
import { getQueryKey } from '@trpc/react-query';
import { format } from 'date-fns';
import { useAtom } from 'jotai';

import { invalidateAllQueriesStartingWith } from '~/api/mutations';
import { trpc } from '~/lib/trpc';
import { API_ENDPOINT } from '~/utils/networkUtils';

import { displayMainCalendarAtom, mainDrawerOpenAtom } from '../atoms/atoms';
import { RouterLink } from '../components/RouterLink';
import { Scrollbar } from '../components/Scrollbar';
import { StatusPill } from '../components/StatusPill';
import CalendarWrapperUpsertEventDialog from '../components/calendar/CalendarWrapperUpsertEventDialog';
import { useDialog } from '../hooks/use-dialog';
import useGetNotifications from '../hooks/use-get-notifications';
import { paths } from '../paths';
import { TenantSwitch } from './TenantSwitch';

const SIDE_NAV_WIDTH = 280;

export const useCssVars = (color: string) => {
  const theme: any = useTheme();

  return useMemo(() => {
    switch (color) {
      case 'blend-in':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        }
        return {
          '--nav-bg': theme.palette.background.default,
          '--nav-color': theme.palette.text.primary,
          '--nav-border-color': theme.palette.neutral[100],
          '--nav-logo-border': theme.palette.neutral[100],
          '--nav-section-title-color': theme.palette.neutral[400],
          '--nav-item-color': theme.palette.text.secondary,
          '--nav-item-hover-bg': theme.palette.action.hover,
          '--nav-item-active-bg': theme.palette.action.selected,
          '--nav-item-active-color': theme.palette.text.primary,
          '--nav-item-disabled-color': theme.palette.neutral[400],
          '--nav-item-icon-color': theme.palette.neutral[400],
          '--nav-item-icon-active-color': theme.palette.primary.main,
          '--nav-item-icon-disabled-color': theme.palette.neutral[400],
          '--nav-item-chevron-color': theme.palette.neutral[400],
          '--nav-scrollbar-color': theme.palette.neutral[900],
        };

      case 'discrete':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[900],
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        }
        return {
          '--nav-bg': theme.palette.neutral[50],
          '--nav-color': theme.palette.text.primary,
          '--nav-border-color': theme.palette.divider,
          '--nav-logo-border': theme.palette.neutral[200],
          '--nav-section-title-color': theme.palette.neutral[500],
          '--nav-item-color': theme.palette.neutral[500],
          '--nav-item-hover-bg': theme.palette.action.hover,
          '--nav-item-active-bg': theme.palette.action.selected,
          '--nav-item-active-color': theme.palette.text.primary,
          '--nav-item-disabled-color': theme.palette.neutral[400],
          '--nav-item-icon-color': theme.palette.neutral[400],
          '--nav-item-icon-active-color': theme.palette.primary.main,
          '--nav-item-icon-disabled-color': theme.palette.neutral[400],
          '--nav-item-chevron-color': theme.palette.neutral[400],
          '--nav-scrollbar-color': theme.palette.neutral[900],
        };

      case 'evident':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        }
        return {
          '--nav-bg': theme.palette.neutral[800],
          '--nav-color': theme.palette.common.white,
          '--nav-border-color': 'transparent',
          '--nav-logo-border': theme.palette.neutral[700],
          '--nav-section-title-color': theme.palette.neutral[400],
          '--nav-item-color': theme.palette.neutral[400],
          '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
          '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
          '--nav-item-active-color': theme.palette.common.white,
          '--nav-item-disabled-color': theme.palette.neutral[500],
          '--nav-item-icon-color': theme.palette.neutral[400],
          '--nav-item-icon-active-color': theme.palette.primary.main,
          '--nav-item-icon-disabled-color': theme.palette.neutral[500],
          '--nav-item-chevron-color': theme.palette.neutral[600],
          '--nav-scrollbar-color': theme.palette.neutral[400],
        };

      default:
        return {};
    }
  }, [theme, color]);
};

export const SideNav = ({ color = 'evident' }) => {
  const updateDialog = useDialog();
  const [mainDrawerOpen] = useAtom(mainDrawerOpenAtom);
  const [, setDisplayMainCalendar] = useAtom(displayMainCalendarAtom);

  const cssVars = useCssVars(color);

  const { data: notifications } = useGetNotifications();

  const deleteStoreNotificationMutation =
    trpc.store.deleteStoreNotification.useMutation({
      onSuccess: async () => {
        invalidateAllQueriesStartingWith(
          getQueryKey(trpc.store.getStoreNotifications)[0],
        );
      },
    });

  useEffect(() => {
    setDisplayMainCalendar(!updateDialog.open);
  }, [updateDialog.open]);

  return (
    <Drawer
      anchor='left'
      open={mainDrawerOpen}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          borderRightColor: 'var(--nav-border-color)',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
          color: 'var(--nav-color)',
          width: SIDE_NAV_WIDTH,
          overflow: 'hidden',
        },
      }}
      variant='persistent'
    >
      <TenantSwitch />
      <Box
        sx={{
          flexGrow: 1,
          mx: 1,
          p: 1,
          backgroundColor: 'neutral.100',
          borderRadius: 4,
          overflow: 'hidden', // Prevent this container from scrolling
          mb: 1,
        }}
      >
        <Typography m={1} variant='h6' sx={{ color: 'neutral.800' }}>
          Notifications
        </Typography>

        <Scrollbar
          sx={{
            flexGrow: 1,
            height: `calc(100% - 40px)`, // Adjust based on the height of your header
            '& .simplebar-content': {
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              height: '100%',
            },
          }}
        >
          <Stack
            direction='column'
            spacing={2}
            sx={{ width: '100%', flexGrow: 1 }}
          >
            {notifications?.length === 0 && (
              <Stack
                direction='column'
                alignItems='center'
                spacing={2}
                px={1}
                py={4}
                borderRadius={2}
                style={{
                  fontSize: 60,
                  backgroundColor: 'rgba(229, 233, 241, 1)',
                }}
              >
                <IconCalendarOff
                  stroke={1.5}
                  size={64}
                  color={deepPurple.A400}
                />

                <Typography variant='h6' sx={{ color: deepPurple.A400 }}>
                  No notifications
                </Typography>
              </Stack>
            )}

            {notifications?.map((notification) => (
              <Stack
                key={notification.id}
                spacing={1}
                pl={2}
                pr={1}
                py={1}
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: 2,
                  overflow: 'hidden',
                  boxShadow: 8,
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: 6,
                    backgroundColor: deepPurple.A400,
                  },
                }}
              >
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Typography
                    variant='body1'
                    fontWeight={700}
                    color={deepPurple.A400}
                    sx={{ ml: 0.5 }}
                  >
                    {notification.title}
                  </Typography>

                  <IconButton
                    onClick={() => {
                      deleteStoreNotificationMutation.mutate(notification.id);
                    }}
                    sx={{ mr: -0.5 }}
                  >
                    <IconX size={20} />
                  </IconButton>
                </Stack>

                <Stack direction='row' alignItems='center' spacing={1} my={0.5}>
                  <Chip
                    icon={<IconCalendar />}
                    label={format(
                      new Date(notification.appointment.start),
                      'dd/MM/yy',
                    )}
                    variant='outlined'
                    sx={{ px: 0.5 }}
                  />
                  <Chip
                    icon={<IconClock />}
                    label={format(
                      new Date(notification.appointment.start),
                      'HH:mm',
                    )}
                    variant='outlined'
                    sx={{ px: 0.5 }}
                  />
                </Stack>
                <Divider sx={{ borderColor: blueGrey['50'] }} />
                <Link
                  color={blueGrey[800]}
                  component={RouterLink}
                  href={paths.appointments.details(notification.appointment.id)}
                  variant='subtitle2'
                >
                  {notification.appointment.title}
                </Link>
                <Divider sx={{ borderColor: blueGrey['50'] }} />
                <Stack
                  direction='row'
                  alignItems='center'
                  gap={0.5}
                  divider={<span style={{ color: blueGrey[50] }}>|</span>}
                >
                  <Stack direction='row' alignItems='center' gap={0.4}>
                    <IconPaw size={16} color={deepPurple.A400} />

                    <Link
                      color={blueGrey[800]}
                      component={RouterLink}
                      href={paths.pets.details(notification.appointment.pet.id)}
                      variant='subtitle2'
                    >
                      {notification.appointment.pet?.name}
                    </Link>
                  </Stack>

                  <Stack direction='row' alignItems='center' gap={0.2}>
                    <IconUser size={16} color={deepPurple.A400} />

                    <Link
                      color={blueGrey[800]}
                      component={RouterLink}
                      href={paths.clients.details(
                        notification.appointment.client.id,
                      )}
                      variant='subtitle2'
                    >
                      {notification.appointment.client.last_name}
                    </Link>
                  </Stack>
                </Stack>
                <Divider />
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <StatusPill
                    type='appointment'
                    status={notification.appointment.status}
                  />

                  <IconButton
                    onClick={() => {
                      updateDialog.handleOpen({
                        appointmentId: notification.appointment.id,
                        notificationId: notification.id,
                      });
                    }}
                    sx={{ mr: -0.5 }}
                  >
                    <IconArrowRight size={20} color={deepPurple.A400} />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
          </Stack>

          <Box sx={{ p: 1, textAlign: 'center' }}>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              width='100%'
              pt={2}
              gap={1}
              marginTop='auto'
            >
              <Typography variant='body2' color='text.secondary'>
                Powered by
              </Typography>

              <ButtonBase
                onClick={() => {
                  window.open(API_ENDPOINT, '_blank');
                }}
              >
                <img src='/full-logo.png' style={{ height: 24 }} alt='logo' />
              </ButtonBase>
            </Stack>
          </Box>
        </Scrollbar>
      </Box>

      {updateDialog.open && (
        <CalendarWrapperUpsertEventDialog
          event={updateDialog.data}
          onClose={updateDialog.handleClose}
          open={updateDialog.open}
          isNotification
        />
      )}
    </Drawer>
  );
};
