import { useCallback } from 'react';

import { CALENDAR_VIEW_TYPE } from '../utils/local-enums';

export function useCalendar(
  calendarRef: React.RefObject<any>,
  setDate: (date: Date) => void,
  setView?: (view: CALENDAR_VIEW_TYPE) => void,
) {
  const handleViewChange = useCallback((view: CALENDAR_VIEW_TYPE) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      setView?.(view);

      if (view === CALENDAR_VIEW_TYPE.TIME_GRID_WORK_WEEK) {
        view = CALENDAR_VIEW_TYPE.TIME_GRID_WEEK;
      }

      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(view);
    }
  }, []);

  const handleDateToday = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  }, []);

  const handleDatePrev = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  }, []);

  const handleDateNext = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  }, []);

  const handleDateChange = useCallback((newDate: Date) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.gotoDate(newDate);
      setDate(calendarApi.getDate());
    }
  }, []);

  const handleDeleteEvent = useCallback((eventId: string) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.getEventById(eventId).remove();
    }
  }, []);

  return {
    handleViewChange,
    handleDateToday,
    handleDatePrev,
    handleDateNext,
    handleDateChange,
    handleDeleteEvent,
  };
}
