import React, { useCallback, useMemo, useState } from 'react';

import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  CircularProgress,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { IconChevronDown } from '@tabler/icons-react';
import { UseQueryResult } from '@tanstack/react-query';
import { TRPCClientErrorLike } from '@trpc/client';
import { BuildProcedure } from '@trpc/server';
import { FormikProps } from 'formik';
import { Paths } from 'type-fest';

import { highlightAutocompleteMatch } from './FormLocalAutocompleteField';
import useFormUtils from './useFormUtils';

type InferProcedureOutput<TProcedure> = TProcedure extends {
  useQuery: (...args: any[]) => UseQueryResult<any, infer TError>;
}
  ? TError extends TRPCClientErrorLike<infer TProcedureError>
    ? TProcedureError extends BuildProcedure<any, any, infer TOutput>
      ? TOutput extends Array<infer TItem>
        ? TItem
        : never
      : never
    : never
  : never;

interface FormRemoteAutocompleteFieldProps<
  TValue,
  TPath,
  TProcedure extends {
    useQuery: (...args: any[]) => UseQueryResult<any, any>;
  },
> {
  trpcProcedure: TProcedure;
  extraTrpcParams?: Parameters<TProcedure['useQuery']>[0];
  name: Paths<TPath>;
  value: TValue;
  label: string;
  formik: FormikProps<TPath>;
  getOptionLabel: (option: InferProcedureOutput<TProcedure> | TValue) => string;
  getOptionKey: (o: InferProcedureOutput<TProcedure>) => string;
  afterInputChange?: (value: InferProcedureOutput<TProcedure> | null) => void;
  textFieldProps?: TextFieldProps;
  sx?: SxProps;
}

export default function FormRemoteAutocompleteField<
  TValue,
  TPath,
  TProcedure extends {
    useQuery: (...args: any[]) => UseQueryResult<any, any>;
  },
>({
  trpcProcedure,
  extraTrpcParams,
  name,
  value,
  label,
  formik,
  getOptionLabel,
  getOptionKey,
  afterInputChange,
  textFieldProps,
  sx,
}: FormRemoteAutocompleteFieldProps<TValue, TPath, TProcedure>) {
  const [changedSinceSelection, setChangedSinceSelection] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { data, isFetching } = trpcProcedure.useQuery(
    {
      query: changedSinceSelection ? inputValue : '',
      ...extraTrpcParams,
    },
    {
      enabled: isOpen,
      keepPreviousData: true,
      retry: false,
    },
  );

  const fieldNameStr = name.toString();
  const { touched, error, helperText } = useFormUtils(formik, fieldNameStr);

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
  ) => {
    setChangedSinceSelection(true);
    setInputValue(newInputValue);
  };

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: InferProcedureOutput<TProcedure> | string | null,
      reason: AutocompleteChangeReason,
    ) => {
      setChangedSinceSelection(false);
      if (reason === 'blur') {
        return;
      }

      formik.setFieldValue(fieldNameStr, value);

      if (typeof value !== 'string') {
        afterInputChange?.(value);
      }
    },
    [],
  );

  const handleOptionLabel = useCallback(
    // HACK: We cast the label to be coming either from the Option (DB response) or the value passed (from Formik)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (option: any): string => {
      return getOptionLabel(option);
    },
    [getOptionLabel],
  );

  const handleBlur = useCallback(async () => {
    await formik.setFieldTouched(fieldNameStr);
  }, [changedSinceSelection, formik, fieldNameStr, inputValue]);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const items = (data ?? []) as InferProcedureOutput<TProcedure>[];

  return useMemo(
    () => (
      <Autocomplete
        value={value as any}
        open={isOpen}
        disableListWrap
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        options={items}
        getOptionLabel={handleOptionLabel}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={error}
            helperText={helperText}
            onBlur={formik?.handleBlur}
            fullWidth
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: 14,
              },
            }}
            {...textFieldProps}
          />
        )}
        renderOption={(props, option) => (
          <Box
            component='li'
            {...props}
            key={getOptionKey(option)}
            style={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Stack direction='column' alignItems='flex-start'>
              <Typography variant='subtitle2'>
                {highlightAutocompleteMatch(
                  handleOptionLabel(option),
                  inputValue,
                  'subtitle2',
                )}
              </Typography>

              {option.subtitle && (
                <Typography variant='caption' color='text.secondary'>
                  {option.subtitle}
                </Typography>
              )}
            </Stack>
          </Box>
        )}
        popupIcon={
          isFetching ? (
            <CircularProgress size={20} sx={{ ml: 1.5 }} />
          ) : (
            <IconChevronDown />
          )
        }
        sx={sx}
      />
    ),
    [
      value,
      fieldNameStr,
      error,
      touched,
      helperText,
      inputValue,
      items,
      isOpen,
      isFetching,
    ],
  );
}
