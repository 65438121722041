import { trpc } from '~/lib/trpc';
import { globalSingleton } from '~/singletons/globalSingleton';

export default function useGetNotifications() {
  return trpc.store.getStoreNotifications.useQuery(undefined, {
    keepPreviousData: true,
    enabled: !!globalSingleton.currentStore.id,
    refetchInterval: 30000, // 30 seconds
  });
}
