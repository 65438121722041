import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'jotai';
import { AuthConsumer } from 'lupa-shared-ui/src/contexts/auth/AuthContext';
import { useFrontendFeatureFlag } from 'lupa-shared-ui/src/hooks/use-feature-flags';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';

import { trpc, trpcClient } from '~/lib/trpc';

import './App.css';
import { sharedAtomStore } from './atoms/atoms';
import DevModeBanner from './components/DevModeBanner';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';
import { SplashScreen } from './components/SplashScreen';
import { Toaster } from './components/Toaster';
import { routes } from './routes';
import { globalSingleton } from './singletons/globalSingleton';
import { createTheme } from './theme';

/**
 * BLUF: we need this for Vizzly to work.
 *
 * Vizzly has a dependency that has a peer-dependency on a polyfill that defines
 * 'global'. Rather then messing with installing these peer-dependencies, we can
 * define this variable ourselves
 *
 * https://stackoverflow.com/a/73208485
 */
window.global = window.global ?? {};

const customTheme = createTheme({
  colorPreset: 'purple',
  contrast: 'high',
  paletteMode: 'light',
  responsiveFontSizes: true,
});

export default function App() {
  const router = createBrowserRouter(routes);
  const { value: showReactQueryDevtools } =
    useFrontendFeatureFlag('SHOW_DEV_TOOLS');

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <trpc.Provider
        client={trpcClient}
        queryClient={globalSingleton.queryClient}
      >
        <Provider store={sharedAtomStore}>
          <QueryClientProvider client={globalSingleton.queryClient}>
            <AuthConsumer>
              {(auth) => {
                const showSplashScreen = !auth.isInitialized;

                return (
                  <ThemeProvider theme={customTheme}>
                    <CssBaseline />
                    {showSplashScreen ? (
                      <SplashScreen />
                    ) : (
                      <RouterProvider router={router} />
                    )}
                    <Toaster />
                    <DevModeBanner />
                  </ThemeProvider>
                );
              }}
            </AuthConsumer>
            {showReactQueryDevtools && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
        </Provider>
      </trpc.Provider>
    </Sentry.ErrorBoundary>
  );
}
