import React from 'react';

import {
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import { produce } from 'immer';

import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from '~/components/StyledToggleButton';
import { EditHealthPlanAllowanceUsageRow } from '~/components/appointments/invoices/EditHealthPlanAllowanceUsageRow';
import { AutocompleteSearch } from '~/components/ui/AutocompleteSearch';
import NumberInput from '~/components/ui/NumberInput';
import { TrpcRouterOutputs, trpc } from '~/lib/trpc';
import { globalSingleton } from '~/singletons/globalSingleton';
import {
  getBillingInfoFromSearchedService,
  getNewUnitPriceFromFinalPrice,
} from '~/utils/billing-utils';
import { DISCOUNT_TYPE } from '~/utils/enums';
import {
  RemainingAllowanceQuantityDataType,
  getAllowanceDiscount,
} from '~/utils/healthplan-utils';
import { formatCurrency, getCurrencySymbol } from '~/utils/i18n';
import { BillingServiceDataType } from '~/validators/appointmentValidators';

interface ServicesListProps {
  services: BillingServiceDataType[];
  onServiceChange: (index: number, service: BillingServiceDataType) => void;
  onServiceRemove: (index: number) => void;
  showUnitPrice: boolean;
  shouldShowVat: boolean;
  hasDiscountsPermission: boolean;
  availableServiceAllowances: Record<
    string,
    RemainingAllowanceQuantityDataType[]
  >;
  setAvailableServiceAllowances: React.Dispatch<
    React.SetStateAction<Record<string, RemainingAllowanceQuantityDataType[]>>
  >;
}

export default function AppointmentsInvoiceServicesList({
  services,
  onServiceChange,
  onServiceRemove,
  showUnitPrice,
  shouldShowVat,
  hasDiscountsPermission,
  availableServiceAllowances,
  setAvailableServiceAllowances,
}: ServicesListProps) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 300 }}>Service</TableCell>
          <TableCell align='center'>Quantity</TableCell>
          {showUnitPrice && (
            <TableCell align='center'>
              <Stack direction='column' alignItems='center'>
                <span>Unit Price</span>
                <Typography
                  variant='caption'
                  color='text.secondary'
                  sx={{ width: 100 }}
                >
                  (incl. VAT)
                </Typography>
              </Stack>
            </TableCell>
          )}
          <TableCell align='center'>Discount</TableCell>
          {shouldShowVat && <TableCell align='center'>VAT</TableCell>}
          <TableCell align='center'>
            <Stack direction='column'>
              <span>Final Price</span>
              <Typography variant='caption' color='text.secondary'>
                (incl. VAT)
              </Typography>
            </Stack>
          </TableCell>
          <TableCell align='center'>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {services.map((service, idx) => (
          <React.Fragment key={service.id}>
            <TableRow
              sx={{
                '& td, & th': {
                  borderBottom: 'none',
                  borderTop: '1px solid',
                  borderColor: 'divider',
                  paddingTop: (theme) => theme.spacing(2),
                },
                '&:first-of-type td, &:first-of-type th': {
                  borderTop: 'none',
                  borderBottom: 'none',
                },
              }}
            >
              <TableCell style={{ width: 300 }}>
                <AutocompleteSearch<{
                  ItemType: TrpcRouterOutputs['store']['searchServices'][0];
                }>
                  trpcFunction={trpc.store.searchServices}
                  label='Service'
                  getOptionLabel={(option) => option.name ?? option}
                  value={service}
                  showTooltip
                  onSelect={(item) => {
                    onServiceChange(
                      idx,
                      getBillingInfoFromSearchedService(item),
                    );
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                <NumberInput
                  value={service.quantity}
                  isInt={false}
                  showArrows
                  onChange={(updateQuantity) => {
                    onServiceChange(
                      idx,
                      produce(service, (draft) => {
                        draft.quantity = updateQuantity;

                        if (
                          draft.allowance_usage &&
                          draft.allowance_usage.length > 0
                        ) {
                          draft.allowance_usage[0].quantity = Math.min(
                            updateQuantity,
                            draft.allowance_usage[0].quantity,
                          );
                        } else {
                          draft.allowance_usage = null;
                        }
                      }),
                    );
                  }}
                  style={{ width: 60 }}
                />
              </TableCell>
              {showUnitPrice && (
                <TableCell align='center'>
                  {formatCurrency(service.unit_price)}
                </TableCell>
              )}
              <TableCell align='center'>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='center'
                  gap={0.5}
                >
                  <StyledToggleButtonGroup
                    color='primary'
                    value={service.discount_type}
                    disabled={
                      !hasDiscountsPermission ||
                      !service.name ||
                      (service.allowance_usage != null &&
                        service.allowance_usage.length > 0)
                    }
                    exclusive
                    onChange={(e, value) => {
                      if (value !== null) {
                        onServiceChange(idx, {
                          ...service,
                          discount_type: value,
                          discount: 0,
                        });
                      }
                    }}
                    sx={{ height: 30 }}
                  >
                    <StyledToggleButton value={DISCOUNT_TYPE.TOTAL} width={28}>
                      £
                    </StyledToggleButton>
                    <StyledToggleButton
                      value={DISCOUNT_TYPE.PERCENTAGE}
                      width={28}
                    >
                      %
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                  <NumberInput
                    value={service.discount}
                    isInt={false}
                    disabled={
                      !hasDiscountsPermission ||
                      (service.allowance_usage != null &&
                        service.allowance_usage.length > 0)
                    }
                    onChange={(value: number) => {
                      let newDiscount = Math.max(0, value);
                      if (service.discount_type === DISCOUNT_TYPE.PERCENTAGE) {
                        newDiscount = Math.min(newDiscount, 100);
                      }
                      onServiceChange(idx, {
                        ...service,
                        discount: newDiscount,
                      });
                    }}
                    InputProps={{
                      endAdornment:
                        service.discount_type === DISCOUNT_TYPE.PERCENTAGE ? (
                          <InputAdornment position='end'>%</InputAdornment>
                        ) : null,
                      startAdornment:
                        service.discount_type === DISCOUNT_TYPE.TOTAL ? (
                          <InputAdornment position='start'>£</InputAdornment>
                        ) : null,
                    }}
                    style={{ width: 60 }}
                  />
                </Stack>
              </TableCell>
              {shouldShowVat && (
                <TableCell align='center'>
                  <NumberInput
                    value={service.vat_percentage}
                    isInt={false}
                    onChange={(value) => {
                      onServiceChange(idx, {
                        ...service,
                        vat_percentage: value,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    style={{ width: 60 }}
                  />
                </TableCell>
              )}
              <TableCell align='center'>
                {hasDiscountsPermission ? (
                  <NumberInput
                    value={service.price}
                    isInt={false}
                    onChange={(value) => {
                      const newUnitPrice = getNewUnitPriceFromFinalPrice(
                        value,
                        service,
                      );

                      onServiceChange(idx, {
                        ...service,
                        unit_price: newUnitPrice,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          {getCurrencySymbol()}
                        </InputAdornment>
                      ),
                    }}
                    style={{ width: 100 }}
                  />
                ) : (
                  formatCurrency(service.price)
                )}
              </TableCell>
              <TableCell align='center'>
                <IconButton color='error' onClick={() => onServiceRemove(idx)}>
                  <IconTrash />
                </IconButton>
              </TableCell>
            </TableRow>

            {globalSingleton.currentStore.health_plan_enabled && (
              <EditHealthPlanAllowanceUsageRow
                storeItemId={service.service_id ?? ''}
                storeItemCategory={service.service_detail?.category ?? ''}
                itemDetails={service}
                availableAllowances={availableServiceAllowances}
                onAllowanceApplied={({
                  allowanceUsed,
                  availableAllowances,
                }) => {
                  setAvailableServiceAllowances(availableAllowances);
                  if (allowanceUsed) {
                    const discount = getAllowanceDiscount({
                      allowanceUsage: allowanceUsed,
                      unitPrice: service.unit_price,
                    });

                    onServiceChange(idx, {
                      ...service,
                      allowance_usage: [allowanceUsed],
                      discount: discount.value,
                      discount_type: discount.type,
                    });
                  } else if (
                    service.allowance_usage != null &&
                    service.allowance_usage.length > 0
                  ) {
                    onServiceChange(idx, {
                      ...service,
                      allowance_usage: [],
                      discount: 0,
                      discount_type: DISCOUNT_TYPE.PERCENTAGE,
                    });
                  }
                }}
              />
            )}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
}
