import React, { useState } from 'react';

import {
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from '~/components/StyledToggleButton';
import NumberInput from '~/components/ui/NumberInput';
import { DISCOUNT_TYPE } from '~/utils/enums';
import { getCurrencySymbol } from '~/utils/i18n';

import AppointmentsBillingSummaryRow from './AppointmentsBillingSummaryRow';

interface AppointmentsBillingSummaryProps {
  applyDiscount: boolean;
  discountType: DISCOUNT_TYPE;
  discountValue: number;
  hasDiscountsPermission: boolean;
  totalNetPreDiscount: number;
  totalDiscount: number;
  totalGross: number;
  totalVat: number;
  totalPrice: number;
  onApplyDiscountChange: (
    event: React.SyntheticEvent,
    checked: boolean,
  ) => void;
  onDiscountTypeChange: (
    event: React.MouseEvent<HTMLElement>,
    newDiscountType: DISCOUNT_TYPE,
  ) => void;
  onDiscountValueChange: (newValue: number) => void;
}

export default function AppointmentsBillingSummary({
  applyDiscount,
  discountType,
  discountValue,
  hasDiscountsPermission,
  totalNetPreDiscount,
  totalDiscount,
  totalGross,
  totalVat,
  totalPrice,
  onApplyDiscountChange,
  onDiscountTypeChange,
  onDiscountValueChange,
}: AppointmentsBillingSummaryProps) {
  const [billingSummaryExpanded, setBillingSummaryExpanded] = useState(false);

  return (
    <Stack direction='column' alignItems='flex-end'>
      <Stack
        direction='row'
        alignItems='center'
        gap={1}
        sx={{
          border: '1px solid #e0e0e0',
          borderRadius: 1,
          py: 0.5,
          px: 1,
          mb: 2,
        }}
      >
        <FormControlLabel
          control={<Checkbox checked={applyDiscount} />}
          disabled={!hasDiscountsPermission}
          onChange={onApplyDiscountChange}
          label='Additional Discount'
        />
        <StyledToggleButtonGroup
          color='primary'
          value={discountType}
          exclusive
          onChange={onDiscountTypeChange}
          sx={{ height: 30 }}
          disabled={!applyDiscount || !hasDiscountsPermission}
        >
          <StyledToggleButton value={DISCOUNT_TYPE.TOTAL} width={28}>
            £
          </StyledToggleButton>
          <StyledToggleButton value={DISCOUNT_TYPE.PERCENTAGE} width={28}>
            %
          </StyledToggleButton>
        </StyledToggleButtonGroup>

        <NumberInput
          value={discountValue}
          isInt={false}
          onChange={onDiscountValueChange}
          InputProps={{
            endAdornment:
              discountType === DISCOUNT_TYPE.PERCENTAGE ? (
                <InputAdornment position='end'>%</InputAdornment>
              ) : null,
            startAdornment:
              discountType === DISCOUNT_TYPE.TOTAL ? (
                <InputAdornment position='start'>
                  {getCurrencySymbol()}
                </InputAdornment>
              ) : null,
          }}
          max={totalPrice}
          style={{ width: 80 }}
          disabled={!applyDiscount || !hasDiscountsPermission}
        />
      </Stack>

      <Stack direction='column' spacing={1} width={300}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h6' gutterBottom>
            Billing Summary
          </Typography>

          <IconButton
            size='small'
            onClick={() => setBillingSummaryExpanded(!billingSummaryExpanded)}
          >
            {billingSummaryExpanded ? <IconChevronUp /> : <IconChevronDown />}
          </IconButton>
        </Stack>

        <Collapse in={billingSummaryExpanded}>
          <Stack direction='column' spacing={1}>
            <AppointmentsBillingSummaryRow
              label='Net Total'
              sublabel='(pre DISCOUNT)'
              value={totalNetPreDiscount}
            />
            <AppointmentsBillingSummaryRow
              label='Discount'
              value={totalDiscount}
              isDiscount
            />
            <AppointmentsBillingSummaryRow
              label='Subtotal'
              sublabel='(excl. VAT)'
              value={totalGross}
            />
            <AppointmentsBillingSummaryRow label='VAT' value={totalVat} />
          </Stack>
        </Collapse>

        <AppointmentsBillingSummaryRow
          label='Total Price'
          sublabel='(incl. VAT)'
          value={totalPrice}
          isTotal
        />
      </Stack>
    </Stack>
  );
}
