import React from 'react';

import { Button, Stack, Typography } from '@mui/material';

export default function ErrorBoundaryFallback() {
  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      flexGrow={1}
      mt={8}
      spacing={2}
    >
      <img
        src='/assets/logo.png'
        alt='logo'
        style={{ width: 80, height: 80, borderRadius: 16 }}
      />
      <Typography variant='h6'>Something went wrong</Typography>
      <Typography variant='body1'>
        Lupa OS encountered an error that it couldn't recover from.
      </Typography>
      <Typography variant='body1'>
        Our team have been notified, and will investigate the issue ASAP.
      </Typography>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Return home
      </Button>
    </Stack>
  );
}
