import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { blueGrey } from '@mui/material/colors';
import { IconChevronDown } from '@tabler/icons-react';
import { format } from 'date-fns';

import { HISTORY_CHANGE_ACTION } from '~/utils/enums';

interface HistoryChangesVisualizerProps {
  changes: {
    id: string;
    action: HISTORY_CHANGE_ACTION;
    change?: unknown;
    created_at: string;
    employee: {
      id: string;
      full_name: string;
      avatar_url: string | null;
    };
  }[];
}

export default function HistoryChangesVisualizer({
  changes,
}: HistoryChangesVisualizerProps) {
  if (changes.length === 0) {
    return (
      <Alert severity='info'>
        <AlertTitle>No changes yet</AlertTitle>
      </Alert>
    );
  }
  return (
    <div>
      {changes.map((change, index) => {
        return (
          <Accordion key={change.id} sx={{ minWidth: 800 }}>
            <AccordionSummary expandIcon={<IconChevronDown />}>
              <Stack direction='row' alignItems='center' gap={2}>
                <Typography variant='subtitle2'>{change.action}</Typography>

                <Typography variant='subtitle2'>
                  {change.employee.full_name}
                </Typography>
                <Typography variant='subtitle2' color='text.secondary'>
                  {format(new Date(change.created_at), 'MMM d, yyyy h:mm a')}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <ChangesDetails changes={changes} index={index} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

interface ChangesDetailsProps {
  changes: HistoryChangesVisualizerProps['changes'];
  index: number;
}

function ChangesDetails({ changes, index }: ChangesDetailsProps) {
  const change = changes[index].change as any;
  const objects = change.data ? change.data : change;

  return (
    <Grid container spacing={2}>
      {Object.keys(objects).map((key) => {
        let previousValue: any = null;

        if (index + 1 < changes.length) {
          for (let i = index + 1; i < changes.length; i++) {
            const change = changes[i];
            const changeObject = change.change as any;
            const changeObjects = changeObject.data
              ? changeObject.data
              : changeObject;

            if (changeObjects[key]) {
              previousValue = changeObjects[key];
              break;
            }
          }
        }

        return (
          <Grid key={key} xs={6}>
            <Stack direction='column' spacing={0.5}>
              <Typography
                variant='subtitle2'
                noWrap
                sx={{
                  color: blueGrey[500],
                }}
              >
                {key
                  .split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </Typography>

              <Stack direction='row' spacing={1}>
                {previousValue != null && (
                  <Typography
                    variant='subtitle1'
                    ml={1}
                    sx={{
                      color: 'inherit',
                    }}
                  >
                    {`${previousValue.toString()}    `}
                    <span>&#8594;</span>
                  </Typography>
                )}

                <Typography
                  variant='subtitle1'
                  ml={1}
                  sx={{
                    color: 'inherit',
                  }}
                >
                  {objects[key]?.toString()}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
}
