import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type LoadingProps = {
  style?: React.CSSProperties;
};

const Loading = ({ style }: LoadingProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
    }}
    style={style}
  >
    <CircularProgress />
  </Box>
);

export default Loading;
