import { isDeepEqual } from 'remeda';

export function getNestedValue<T>(obj: T, path: string): any {
  const keys = path.replace(/\[(\w+)\]/g, '.$1').split('.');
  return keys.reduce(
    (acc: any, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
    obj,
  );
}

type Formik = {
  errors: any;
  touched: any;
};

export const getFieldError = (
  formik: Formik,
  fieldName: string,
  showErrorBeforeTouched: boolean = false,
): boolean => {
  const error = getNestedValue(formik.errors, fieldName);
  const touched = getNestedValue(formik.touched, fieldName);
  return Boolean((touched || showErrorBeforeTouched) && error ? error : '');
};

export const getFieldHelperText = (
  formik: Formik,
  fieldName: string,
  showErrorBeforeTouched: boolean = false,
): string => {
  const error = getNestedValue(formik.errors, fieldName);
  const touched = getNestedValue(formik.touched, fieldName);
  return (touched || showErrorBeforeTouched) && error ? error : '';
};

export const getChangedValues = <T extends Record<string, unknown>>(
  values: T,
  initialValues: T,
) => {
  return Object.entries(values).reduce<Partial<T>>((acc, [key, value]) => {
    if (!isDeepEqual(value, initialValues[key])) {
      // @ts-ignore
      acc[key] = value;
    }
    return acc;
  }, {});
};
