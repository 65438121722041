import React from 'react';

import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { IconEdit } from '@tabler/icons-react';

import KeyValueTextField from './KeyValueTextField';

type BasicDetailsProps = {
  direction?: 'row' | 'column';
  title?: string;
  onEdit?: () => void;
  itemsPerColumn?: number;
  elevation?: number;
  [key: string]: any;
  testIdPrefix?: string;
};

export default function BasicDetails({
  direction = 'column',
  title,
  onEdit,
  itemsPerColumn,
  elevation = 5,
  testIdPrefix = '',
  ...props
}: BasicDetailsProps) {
  const keys = Object.keys(props);

  return (
    <Card
      elevation={elevation}
      sx={{
        backgroundColor: elevation === 0 ? 'transparent' : undefined,
      }}
    >
      <CardContent
        sx={{
          pt: 0,
          '&:last-child': { pb: 2 },
        }}
        data-testid={`${testIdPrefix}-card-content`}
      >
        {title && (
          <Stack pt={1}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              height={28}
            >
              <Typography
                variant='h6'
                color={blueGrey[500]}
                data-testid={`${testIdPrefix}-card-content-title`}
              >
                {title}
              </Typography>

              {onEdit && (
                <IconButton
                  onClick={onEdit}
                  size='small'
                  data-testid={`${testIdPrefix}-card-content-edit-button`}
                >
                  <IconEdit size={20} />
                </IconButton>
              )}
            </Stack>

            <Divider sx={{ borderColor: blueGrey[50] }} />
          </Stack>
        )}

        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          spacing={2}
        >
          {itemsPerColumn ? (
            Array.from(
              { length: Math.ceil(keys.length / itemsPerColumn) },
              (_, colIndex) => (
                <Stack
                  key={colIndex}
                  direction={direction}
                  sx={{
                    gap: direction === 'column' ? 1 : 4,
                    pt: title ? 1 : 2,
                    pb: 0,
                  }}
                >
                  {keys
                    .slice(
                      colIndex * itemsPerColumn,
                      (colIndex + 1) * itemsPerColumn,
                    )
                    .map((key) => (
                      <KeyValueTextField
                        key={key}
                        label={key}
                        value={props[key]}
                        testIdPrefix={`${testIdPrefix}-${key}`}
                      />
                    ))}
                </Stack>
              ),
            )
          ) : (
            <Stack
              direction={direction}
              sx={{
                gap: direction === 'column' ? 1.5 : 4,
                pt: title ? 1 : 2,
                pb: 0,
              }}
            >
              {keys.map((key) => (
                <KeyValueTextField
                  key={key}
                  label={key}
                  value={props[key]}
                  testIdPrefix={`${testIdPrefix}-${key}`}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
