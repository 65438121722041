import React, { useState } from 'react';

import { Button, Stack, Tooltip } from '@mui/material';
import ReactPDF, { pdf } from '@react-pdf/renderer';
import { IconChevronDown } from '@tabler/icons-react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import z from 'zod';
import { toFormikValidate, toFormikValidationSchema } from 'zod-formik-adapter';

import { trpc } from '~/lib/trpc';
import { globalSingleton } from '~/singletons/globalSingleton';
import {
  PrescriptionDispenseActions,
  prescriptionDispenseActionsSchema,
  prescriptionDispenseValidationSchema,
  prescriptionUpsertSchema,
} from '~/validators/prescriptionValidators';

import DosageLabelsPDF from '../appointments/DosageLabelsPDF';
import WrittenPrescriptionPDF from '../appointments/WrittenPrescriptionPDF';
import DropdownMenuButton from '../ui/DropdownMenuButton';
import Loading from '../ui/Loading';
import { NotFound } from '../ui/NotFound';
import FormSaveButton from '../ui/form/FormSaveButton';
import PrescriptionCancelDialog from './PrescriptionCancelDialog';
import PrescriptionFormDispenseSection from './PrescriptionFormDispenseSection';
import PrescriptionFormPrescriptionSection from './PrescriptionFormPrescriptionSection';

const WAGWORKS_STORE_ID = '593802bb-889f-4dd8-a318-c62bbbeb92b9';

export const getPrescriptionRefillsEnabled = (): boolean =>
  globalSingleton.isTestUser ||
  globalSingleton.currentStore?.features?.prescription_refills_enabled ||
  globalSingleton.currentStore?.id === WAGWORKS_STORE_ID;

export const prescriptionAndDispensesFormSchema = z.object({
  prescription: prescriptionUpsertSchema,
  dispenses: z.object({
    initial: z.array(prescriptionDispenseValidationSchema),
    modified: z.record(z.string().uuid(), prescriptionDispenseActionsSchema),
    created: z.array(prescriptionDispenseActionsSchema),
  }),
  formOptions: z.object({
    notifyReadyForPickup: z.boolean().nullable(),
  }),
});

export type PrescriptionAndDispensesFormDataType = z.infer<
  typeof prescriptionAndDispensesFormSchema
>;

export interface PrescriptionFormProps {
  initialValues: PrescriptionAndDispensesFormDataType;
  handleClose: () => void;

  billingProductContext?: {
    id: string;
    display_name: string;
  };
  onSaved: (args: {
    prescriptionId: string;
    updatesForParentForm?: {
      dispensesIdsToAddToContextBillingProduct: string[];
    };
  }) => Promise<void>;
}

/**
 * Where the parent form has unsaved new billing products, we need to pass
 * the dispense ids to the parent form so that it can update the billing product
 * with the dispense ids when the billing product is created.
 */
function getBillingProductContextUpdates({
  dispenseActions,
  billingProductContext,
}: {
  dispenseActions: Record<string, PrescriptionDispenseActions>;
  billingProductContext?: PrescriptionFormProps['billingProductContext'];
}) {
  if (billingProductContext == null) {
    return undefined;
  }

  const billingProductUpdatesToApply = Object.entries(dispenseActions).flatMap(
    ([dispenseId, actions]) => {
      const billingProductId = actions.link_to_billing_product?.id;
      return billingProductId ? [{ dispenseId, billingProductId }] : [];
    },
  );

  billingProductUpdatesToApply.forEach(({ billingProductId }) => {
    if (billingProductId !== billingProductContext.id) {
      throw new Error('Trying to link dispense to different billing product');
    }
  });

  return {
    dispensesIdsToAddToContextBillingProduct: billingProductUpdatesToApply.map(
      ({ dispenseId }) => dispenseId,
    ),
  };
}

export default function PrescriptionForm({
  initialValues,
  billingProductContext,
  onSaved,
  handleClose,
}: PrescriptionFormProps) {
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const cancelPrescriptionMutation =
    trpc.prescriptions.cancelPrescription.useMutation();

  const trpcUtils = trpc.useUtils();
  const savePrescriptionMutation =
    trpc.prescriptions.upsertPrescription.useMutation({
      onSuccess: async (result) => {
        await onSaved({
          prescriptionId: result.prescription.id,
          updatesForParentForm: getBillingProductContextUpdates({
            dispenseActions: formik.values.dispenses.modified,
            billingProductContext,
          }),
        });

        if (result.notificationResult) {
          if (result.notificationResult === 'SENT') {
            toast.success('Notification sent to client');
          } else {
            toast.error('Notification failed to send');
          }
        }

        trpcUtils.pets.getPetMedicalSummary.invalidate();
        trpcUtils.prescriptions.invalidate();

        toast.success('Prescription saved');
        handleClose();
      },
      onError: (error) => {
        toast.error(`Error: ${error.message || 'Something went wrong!'}`);
      },
    });
  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: toFormikValidationSchema(
      prescriptionAndDispensesFormSchema,
    ),
    validate: toFormikValidate(prescriptionAndDispensesFormSchema),
    onSubmit: async (values) => {
      await savePrescriptionMutation.mutateAsync(values);
    },
  });

  const petQuery = trpc.pets.getPet.useQuery({
    petId: formik.values.prescription.pet_id,
  });

  const clientQuery = trpc.clients.getClient.useQuery({
    clientId: formik.values.prescription.client_id,
  });

  const prescriberQuery = trpc.employees.getEmployee.useQuery({
    employeeId: formik.values.prescription.prescriber_employee_id,
  });

  const onPrint = async (
    document: React.ReactElement<ReactPDF.DocumentProps>,
  ) => {
    const blob = await pdf(document).toBlob();
    const url = URL.createObjectURL(blob);
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.print();
    }
  };

  const onCancelPrescription = async () => {
    if (formik.values.prescription.id == null) {
      toast.error('Error cancelling prescription');
      throw new Error('Error cancelling prescription: prescription id is null');
    }

    await cancelPrescriptionMutation.mutateAsync({
      id: formik.values.prescription.id,
    });
    trpcUtils.prescriptions.invalidate();
    formik.resetForm();
    setShowCancelDialog(false);
  };

  if (
    petQuery.isLoading ||
    clientQuery.isLoading ||
    prescriberQuery.isLoading
  ) {
    return <Loading />;
  }

  if (petQuery.isError || clientQuery.isError || prescriberQuery.isError) {
    return <NotFound message='Error loading prescription' />;
  }

  const printingPermission =
    formik.values.prescription.status === 'cancelled'
      ? {
          allowed: false,
          reason: 'Prescription is cancelled',
        }
      : formik.dirty
        ? {
            allowed: false,
            reason: 'You must save the prescription before printing',
          }
        : !formik.isValid
          ? { allowed: false, reason: "Can't print - there are errors" }
          : { allowed: true };

  const isNewPrescription = formik.values.prescription.id == null;

  return (
    <>
      <Stack direction='column' spacing={2} px={1} width={700}>
        <PrescriptionFormPrescriptionSection
          formik={formik}
          pet={petQuery.data}
          client={clientQuery.data}
          prescriber={prescriberQuery.data}
        />
        {getPrescriptionRefillsEnabled() && (
          <PrescriptionFormDispenseSection
            formik={formik}
            billingProductContext={billingProductContext}
          />
        )}

        <Stack direction='row' spacing={0} alignItems='center'>
          {!isNewPrescription && (
            <Button
              disabled={formik.values.prescription.status === 'cancelled'}
              onClick={() => {
                setShowCancelDialog(true);
              }}
              variant='outlined'
              color='error'
            >
              Cancel Prescription
            </Button>
          )}

          <Stack direction='row' spacing={1} sx={{ marginLeft: 'auto' }}>
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose();
              }}
            >
              Cancel
            </Button>

            <Tooltip
              title={
                printingPermission.allowed === false
                  ? printingPermission.reason
                  : ''
              }
            >
              <span>
                <DropdownMenuButton
                  disabled={!printingPermission.allowed}
                  size='medium'
                  variant='contained'
                  options={[
                    {
                      name: 'Written Prescription',
                      onClick: () =>
                        onPrint(
                          <WrittenPrescriptionPDF
                            prescription={formik.values.prescription}
                            pet={petQuery.data}
                            ownerOrKeeper={{
                              name: petQuery.data.owner[0].client.full_name,
                              address:
                                petQuery.data.owner[0].client.address
                                  ?.formatted_address,
                              phone: petQuery.data.owner[0].client.phone,
                              email: petQuery.data.owner[0].client.email,
                            }}
                            prescriber={{
                              name: prescriberQuery.data.full_name,
                              rcvs_number:
                                prescriberQuery.data.rcvs_registration_number,
                            }}
                          />,
                        ),
                    },
                    {
                      name: 'Dosage Label',
                      onClick: () =>
                        onPrint(
                          <DosageLabelsPDF
                            prescription={formik.values.prescription}
                            petName={petQuery.data.name}
                            clientName={petQuery.data.owner[0].client.full_name}
                            clientAddress={
                              petQuery.data.owner[0].client.address
                                ?.formatted_address
                            }
                            prescriberName={prescriberQuery.data.full_name}
                          />,
                        ),
                    },
                  ]}
                  endIcon={<IconChevronDown size={16} />}
                >
                  Print
                </DropdownMenuButton>
              </span>
            </Tooltip>

            <FormSaveButton
              disabled={
                !formik.isValid ||
                formik.values.prescription.status === 'cancelled'
              }
              formik={formik}
              loading={savePrescriptionMutation.isLoading}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {isNewPrescription ? 'Create' : 'Update'}
            </FormSaveButton>
          </Stack>
        </Stack>
      </Stack>
      {!isNewPrescription && (
        <PrescriptionCancelDialog
          open={showCancelDialog}
          isLoading={cancelPrescriptionMutation.isLoading}
          onDismiss={() => {
            setShowCancelDialog(false);
          }}
          onCancelPrescription={onCancelPrescription}
        />
      )}
    </>
  );
}
