import React, { useEffect, useMemo } from 'react';

import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { IconCircleXFilled, IconPlus } from '@tabler/icons-react';
import { addMonths, formatDistanceToNow } from 'date-fns';
import { FormikProps } from 'formik';
import { LupaFormikNumberInput } from 'lupa-shared-ui';
import { match } from 'ts-pattern';

import { globalSingleton } from '~/singletons/globalSingleton';
import {
  PrescriptionDispense,
  PrescriptionDispenseActions,
} from '~/validators/prescriptionValidators';

import DividerSection from '../ui/DividerSection';
import FormDateField from '../ui/form/FormDateField';
import { PrescriptionAndDispensesFormDataType } from './PrescriptionForm';

function applyDispenseActions(
  dispense: Partial<PrescriptionDispense>,
  actions: PrescriptionDispenseActions,
): Partial<PrescriptionDispense> {
  return {
    ...dispense,

    ...(actions.cancel && {
      is_cancelled: true,
    }),

    ...(actions.authorise && {
      authorisation: {
        given_by_employee: globalSingleton.currentEmployee,
        given_at: new Date().toISOString(),
      },
    }),

    ...(actions.link_to_billing_product && {
      billing: {
        billing_product_id: actions.link_to_billing_product.id,
      },
    }),
  };
}

function DispenseActionChip({
  state,
  label,
  tooltipTitle,
  onMarkComplete,
  onUndoMarkComplete,
}: {
  state: 'READONLY' | 'MARKING_COMPLETE' | 'DOABLE';
  label: string;
  tooltipTitle?: string;
  onMarkComplete: () => void;
  onUndoMarkComplete: () => void;
}) {
  if (state === 'READONLY') {
    return (
      <Tooltip title={tooltipTitle}>
        <span>{label}</span>
      </Tooltip>
    );
  }

  return (
    <Chip
      sx={{
        height: 'auto',
        padding: 0.5,
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal',
        },
      }}
      variant='filled'
      label={label}
      onClick={state === 'MARKING_COMPLETE' ? undefined : onMarkComplete}
      onDelete={state === 'MARKING_COMPLETE' ? onUndoMarkComplete : undefined}
      size='small'
      color={state === 'MARKING_COMPLETE' ? 'success' : 'warning'}
    />
  );
}

export default function PrescriptionFormDispenseSection({
  billingProductContext,
  formik,
}: {
  billingProductContext:
    | { id: string; prescription_dispense_ids?: string[] }
    | undefined;
  formik: FormikProps<PrescriptionAndDispensesFormDataType>;
}) {
  const { dispenses } = formik.values;

  const resolvedDispenses = useMemo(() => {
    const allDispenses = [
      ...dispenses.initial.map(
        (dispense) =>
          ({
            type: 'EXISTING',
            initialState: dispense,
            actions: dispenses.modified[dispense.id] ?? {},
            actionModificationPath: `dispenses.modified[${dispense.id}]`,
          }) as const,
      ),
      ...dispenses.created.map(
        (actions, index) =>
          ({
            type: 'NEW',
            initialState: {},
            actions,
            actionModificationPath: `dispenses.created[${index}]`,
            createdIndex: index,
          }) as const,
      ),
    ];

    return allDispenses.map((dispense) => ({
      ...dispense,
      resolvedState: applyDispenseActions(
        dispense.initialState,
        dispense.actions,
      ),
    }));
  }, [dispenses.initial, dispenses.modified, dispenses.created]);

  useEffect(() => {
    // Clear the notifyReadyForPickup flag when all authorisations are removed
    if (
      formik.values.formOptions.notifyReadyForPickup != null &&
      !resolvedDispenses.some((d) => d.actions.authorise != null)
    ) {
      formik.setFieldValue('formOptions.notifyReadyForPickup', null);
    }
  }, [resolvedDispenses]);

  const getAuthorisationLabel = (
    authorisation: NonNullable<PrescriptionDispense['authorisation']>,
  ) => {
    return `${formatDistanceToNow(new Date(authorisation.given_at), { addSuffix: true })} by ${authorisation.given_by_employee.full_name}`;
  };

  return (
    <DividerSection title='Refills'>
      <Stack direction='row' spacing={2}>
        <LupaFormikNumberInput
          name='prescription.refill_limit'
          label='Permitted Refills'
          formik={formik}
          value={formik.values.prescription.refill_limit}
          size='small'
          showArrows
          isInt
          min={0}
          sx={{ width: 180 }}
        />
        {formik.values.prescription.refill_limit > 0 && (
          <FormDateField
            name='prescription.refills_permitted_until'
            label='Refills Permitted Until'
            formik={formik}
            minDate={addMonths(new Date(), 12)}
            sx={{ width: 200 }}
            dateFormat='isoDate'
            textFieldProps={{
              variant: 'outlined',
              size: 'small',
            }}
          />
        )}
      </Stack>

      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Refill</TableCell>
              <TableCell align='center'>Authorised</TableCell>
              <TableCell align='center'>Invoiced</TableCell>
              <TableCell align='center' />
            </TableRow>
          </TableHead>
          <TableBody>
            {resolvedDispenses.map((dispense, index) => (
              <TableRow
                key={dispense.actionModificationPath}
                sx={{
                  backgroundColor: dispense.resolvedState.is_cancelled
                    ? 'rgba(255, 0, 0, 0.1)'
                    : 'inherit',
                  opacity: dispense.resolvedState.is_cancelled ? 0.7 : 1,
                }}
              >
                <TableCell align='center'>
                  {index === 0 ? 'Initial' : `#${index}`}
                </TableCell>
                <TableCell align='center'>
                  <DispenseActionChip
                    {...(dispense.resolvedState?.authorisation == null
                      ? { state: 'DOABLE', label: 'Authorise' }
                      : dispense.actions.authorise != null
                        ? { state: 'MARKING_COMPLETE', label: 'Yes' }
                        : {
                            state: 'READONLY',
                            label: getAuthorisationLabel(
                              dispense.resolvedState.authorisation,
                            ),
                          })}
                    onMarkComplete={() =>
                      formik.setFieldValue(
                        `${dispense.actionModificationPath}.authorise`,
                        {},
                      )
                    }
                    onUndoMarkComplete={() =>
                      formik.setFieldValue(
                        `${dispense.actionModificationPath}.authorise`,
                        undefined,
                      )
                    }
                  />
                </TableCell>
                <TableCell align='center'>
                  <DispenseActionChip
                    {...match({
                      alreadyMarkedComplete:
                        dispense.resolvedState?.billing != null,
                      markingComplete:
                        dispense.actions.link_to_billing_product != null,
                      markedCompleteInParentForm:
                        dispense.resolvedState.id != null &&
                        billingProductContext?.prescription_dispense_ids?.includes(
                          dispense.resolvedState.id,
                        ),
                      canBeLinkedNow: billingProductContext != null,
                    })
                      .with({ markingComplete: true }, () => ({
                        state: 'MARKING_COMPLETE' as const,
                        label: 'Adding to this invoice',
                      }))
                      .with({ alreadyMarkedComplete: true }, () => ({
                        state: 'READONLY' as const,
                        label: `Yes`,
                      }))
                      .with({ markedCompleteInParentForm: true }, () => ({
                        state: 'READONLY' as const,
                        label: `Yes (invoice not yet saved)`,
                      }))
                      .with({ canBeLinkedNow: true }, () => ({
                        state: 'DOABLE' as const,
                        label: 'Add to invoice',
                      }))
                      .with({ canBeLinkedNow: false }, () => ({
                        state: 'READONLY' as const,
                        label: 'No',
                      }))
                      .exhaustive()}
                    onMarkComplete={() =>
                      billingProductContext != null &&
                      formik.setFieldValue(
                        `${dispense.actionModificationPath}.link_to_billing_product`,
                        billingProductContext,
                      )
                    }
                    onUndoMarkComplete={() =>
                      formik.setFieldValue(
                        `${dispense.actionModificationPath}.link_to_billing_product`,
                        undefined,
                      )
                    }
                  />
                </TableCell>
                <TableCell align='center'>
                  <IconButton
                    size='small'
                    sx={{
                      opacity: dispense.type === 'NEW' ? 1 : 0,
                      pointerEvents: dispense.type === 'NEW' ? 'auto' : 'none',
                    }}
                    onClick={
                      dispense.type === 'NEW'
                        ? () => {
                            formik.setFieldValue(
                              'dispenses.created',
                              formik.values.dispenses.created.filter(
                                (_, i) => i !== dispense.createdIndex,
                              ),
                            );
                          }
                        : undefined
                    }
                  >
                    <IconCircleXFilled size={16} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={5} align='center'>
                <Button
                  startIcon={<IconPlus size={16} />}
                  size='small'
                  onClick={() => {
                    formik.setFieldValue('dispenses.created', [
                      ...formik.values.dispenses.created,
                      {},
                    ]);
                  }}
                >
                  Add refill
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={2} alignItems='left'>
          <FormControlLabel
            sx={{
              userSelect: 'none',
            }}
            control={
              <Checkbox
                checked={formik.values.prescription.next_refill_due != null}
                onChange={(e) => {
                  formik.setFieldValue(
                    'prescription.next_refill_due',
                    e.target.checked ? new Date().toISOString() : null,
                  );
                }}
              />
            }
            label='Remind client about their next refill'
          />
          {formik.values.prescription.next_refill_due != null && (
            <FormDateField
              name='prescription.next_refill_due'
              label='Next refill due'
              formik={formik}
              minDate={new Date()}
              sx={{ flex: 1 }}
              textFieldProps={{
                size: 'small',
                variant: 'outlined',
              }}
            />
          )}
        </Stack>

        {resolvedDispenses.some((d) => d.actions.authorise != null) && (
          <FormControlLabel
            sx={{
              userSelect: 'none',
            }}
            control={
              <Checkbox
                checked={
                  formik.values.formOptions.notifyReadyForPickup ?? false
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    'formOptions.notifyReadyForPickup',
                    e.target.checked,
                  );
                }}
              />
            }
            label='Notify client that prescription is ready for collection'
          />
        )}

        {resolvedDispenses.length - 1 >
          formik.values.prescription.refill_limit && (
          <Alert severity='error'>
            <AlertTitle>Refill limit exceeded</AlertTitle>
            This prescription allows {
              formik.values.prescription.refill_limit
            }{' '}
            {formik.values.prescription.refill_limit === 1
              ? 'refill'
              : 'refills'}
            , but {resolvedDispenses.length - 1}{' '}
            {resolvedDispenses.length - 1 === 1 ? 'refill has' : 'refills have'}{' '}
            been added.
          </Alert>
        )}
      </Stack>
    </DividerSection>
  );
}
