import { globalSingleton } from '../singletons/globalSingleton';
import {
  FLOW_CHART_COLUMNS,
  IN_PROGRESS_STATUS_INDEX,
} from './flow-chart-utils';

export const getAppointmentStatuses = () => {
  let columns;
  const { currentStore } = globalSingleton;

  if (currentStore.progress_statuses) {
    const updatedColumns = [...FLOW_CHART_COLUMNS];
    updatedColumns.splice(
      IN_PROGRESS_STATUS_INDEX,
      1,
      ...currentStore.progress_statuses,
    );
    columns = updatedColumns;
  } else {
    columns = FLOW_CHART_COLUMNS;
  }

  return columns.map((c) => c.value);
};
