import { useCallback, useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

export function useDialog<T = any>(urlParam?: string) {
  const [state, setState] = useState<{ open: boolean; data: T | undefined }>({
    open: false,
    data: undefined,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (urlParam) {
      const isOpen = searchParams.get(urlParam) === 'open';
      setState((s) => ({ ...s, open: isOpen }));
    }
  }, [window.location, urlParam]);

  const updateUrlParam = useCallback(
    (openState: boolean) => {
      if (openState) {
        searchParams.set(urlParam!, 'open');
      } else {
        searchParams.set(urlParam!, 'closed');
      }

      setSearchParams(searchParams);
    },
    [window.location.search, urlParam],
  );

  const handleOpen = useCallback(
    (data?: T) => {
      setState({
        open: true,
        data,
      });

      if (urlParam) {
        updateUrlParam(true);
      }
    },
    [urlParam, updateUrlParam],
  );

  const handleClose = useCallback(() => {
    setState({
      open: false,
      data: undefined,
    });

    if (urlParam) {
      updateUrlParam(false);
    }
  }, [urlParam, updateUrlParam]);

  return {
    data: state.data,
    handleClose,
    handleOpen,
    open: state.open,
  };
}
