import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';

type LoadingIconButtonProps = {
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading: boolean;
  size: 'small' | 'medium';
  children: React.ReactNode;
  loadingStyle?: React.CSSProperties;
};

export default function LoadingIconButton({
  color,
  disabled,
  onClick,
  loading,
  size,
  children,
  loadingStyle,
}: LoadingIconButtonProps) {
  return loading ? (
    <CircularProgress size={20} style={loadingStyle} sx={{ ml: 1.5 }} />
  ) : (
    <IconButton size={size} color={color} disabled={disabled} onClick={onClick}>
      <SvgIcon>{children}</SvgIcon>
    </IconButton>
  );
}
