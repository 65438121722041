const mathUtils = {
  roundNumber: (value: number, decimals = 2): number =>
    Math.round(value * 10 ** decimals) / 10 ** decimals,

  roundCurrency: (value: number): number => mathUtils.roundNumber(value, 2),

  getGrowthPercentage: (oldValue: number, newValue: number): number => {
    if (oldValue === 0) {
      return 100;
    }

    return mathUtils.roundNumber(((newValue - oldValue) / oldValue) * 100);
  },

  toPercentage: (discount: Nullish<number>): number => {
    if (discount == null) {
      return 1;
    }

    return discount / 100;
  },

  getIncreaseFactor: (n: Nullish<number>): number => {
    return 1 + mathUtils.toPercentage(n);
  },

  getDiscountsValue: (
    value: number,
    discounts: Array<Nullish<number>>,
  ): number => {
    const discountValues = discounts.map((discount) => {
      return value * mathUtils.toPercentage(discount);
    });

    return discountValues.reduce((acc, discount) => {
      return acc + discount;
    }, 0);
  },
};

export default mathUtils;
