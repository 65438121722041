import { isAfter, subMonths, subYears } from 'date-fns';
import z from 'zod';

import { getAppointmentCategoryLabel } from '~/consts/storeCategories';
import { TrpcRouterOutputs } from '~/lib/trpc';
import { globalSingleton } from '~/singletons/globalSingleton';
import { BillingItem } from '~/utils/billing-utils';
import {
  APPOINTMENT_TYPE,
  DISCOUNT_TYPE,
  HEALTH_PLAN_ALLOWANCE_ITEM_TYPE,
  HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE,
  HEALTH_PLAN_ALLOWANCE_STATUS,
  HEALTH_PLAN_ALLOWANCE_TYPE,
  PRODUCT_CATEGORY_TYPE,
} from '~/utils/enums';
import { getProductCategoryLabel } from '~/utils/get-labels';
import { AllowanceUsageDataType } from '~/validators/appointmentValidators';

export const ALLOWANCES_UNLIMITED_QUANTITY = 9999999999999;
type ClientSubscription =
  TrpcRouterOutputs['healthPlans']['getClientSubscriptions'][number];
type PetSubscription =
  TrpcRouterOutputs['healthPlans']['getPetSubscriptions'][number];
type Subscription = ClientSubscription | PetSubscription;
export type HealthPlanAllowance =
  TrpcRouterOutputs['healthPlans']['getAllHealthPlans'][number]['allowances'][number];
export type AllowanceItem =
  | {
      type:
        | HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_PRODUCT
        | HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_SERVICE;
      item: string;
    }
  | {
      type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.PRODUCT_CATEGORY;
      item: PRODUCT_CATEGORY_TYPE;
    }
  | {
      type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.SERVICE_CATEGORY;
      item: APPOINTMENT_TYPE;
    };

export const BillableItemDetailSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type BillableItemDetail = z.infer<typeof BillableItemDetailSchema>;

export type RemainingAllowanceQuantityDataType = {
  name: string | null;
  allowance_id: string;
  health_plan_id: string;
  subscription_id: string;
  subscriber_type: string;
  config: {
    value: {
      type: HEALTH_PLAN_ALLOWANCE_TYPE;
      value: number;
    };
    limitPerPeriod: number;
    period: HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE;
  };
  item: string | null;
  service: BillableItemDetail | null;
  product: BillableItemDetail | null;
  item_type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE;
  remainingQuantity: number;
};

const isWithinPeriod = (
  date: string,
  period: HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE,
): boolean => {
  const currentDate = new Date();
  const usageDate = new Date(date);
  switch (period) {
    case HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE.MONTHLY:
      return isAfter(usageDate, subMonths(currentDate, 1));
    case HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE.ANNUALLY:
      return isAfter(usageDate, subYears(currentDate, 1));
    default:
      return true; // For other period types, assume it's within the period
  }
};

export const getHealthPlanAllowanceItemTypeLabel = (
  type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE,
) => {
  switch (type) {
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.PRODUCT_CATEGORY:
      return 'Product Category';
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.SERVICE_CATEGORY:
      return 'Service Category';
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_PRODUCT:
      return 'Individual Product';
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_SERVICE:
      return 'Individual Service';
    default:
      return type;
  }
};

export function getAllowanceItemLabel({
  allowance,
}: {
  allowance: {
    item: string | null;
    service: { name: string } | null;
    product: { name: string } | null;
    item_type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE;
  } | null;
}) {
  if (allowance != null) {
    switch (allowance.item_type) {
      case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_PRODUCT:
        return allowance.product?.name;
      case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_SERVICE:
        return allowance.service?.name;
      case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.PRODUCT_CATEGORY:
        return allowance.item != null
          ? getProductCategoryLabel(allowance.item as PRODUCT_CATEGORY_TYPE)
          : '';
      case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.SERVICE_CATEGORY:
        return allowance.item != null
          ? getAppointmentCategoryLabel(allowance.item as APPOINTMENT_TYPE)
          : '';
      default:
        return 'Unknown Item Type';
    }
  }
  return 'Unknown Item';
}

export function getAllowanceNameLabel({
  allowance,
}: {
  allowance: {
    item: string | null;
    service: { name: string } | null;
    product: { name: string } | null;
    item_type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE;
  } | null;
}) {
  return allowance != null
    ? `${getHealthPlanAllowanceItemTypeLabel(allowance.item_type)}: ${getAllowanceItemLabel({ allowance })}`
    : 'Allowance';
}

export function calculateAvailableAllowances({
  clientSubscriptions,
  petSubscriptions,
}: {
  clientSubscriptions: ClientSubscription[];
  petSubscriptions: PetSubscription[];
}) {
  const allSubscriptions: Subscription[] = [
    ...(clientSubscriptions || []),
    ...petSubscriptions,
  ];
  const allowanceUsageMap: Record<string, number> = {};

  allSubscriptions.forEach((subscription) => {
    subscription.allowance_usages.forEach((usage) => {
      const { allowance_id, quantity, usage_date } = usage;
      const allowance = subscription.health_plan?.allowances.find(
        (a) => a.id === allowance_id,
      );

      if (allowance && isWithinPeriod(usage_date, allowance.config.period)) {
        allowanceUsageMap[allowance_id] =
          (allowanceUsageMap[allowance_id] || 0) + quantity;
      }
    });
  });

  const allowanceProductTypeMap: Record<
    string,
    RemainingAllowanceQuantityDataType[]
  > = {};
  const allowanceServiceTypeMap: Record<
    string,
    RemainingAllowanceQuantityDataType[]
  > = {};

  allSubscriptions.forEach((subscription) => {
    if (subscription.health_plan) {
      const activeAllowances = subscription.health_plan.allowances.filter(
        (allowance) =>
          allowance.allowance_status === HEALTH_PLAN_ALLOWANCE_STATUS.ACTIVE,
      );

      activeAllowances.forEach((allowance) => {
        const usedQuantity = allowanceUsageMap[allowance.id] || 0;
        const remainingAllowance: RemainingAllowanceQuantityDataType = {
          name: allowance.name,
          allowance_id: allowance.id,
          health_plan_id: allowance.health_plan_id,
          subscription_id: subscription.id,
          subscriber_type: subscription.subscriber_type,
          config: allowance.config,
          item: allowance.item,
          service: allowance.service,
          product: allowance.product,
          item_type: allowance.item_type,
          remainingQuantity:
            allowance.config.limitPerPeriod === ALLOWANCES_UNLIMITED_QUANTITY
              ? allowance.config.limitPerPeriod
              : Math.max(0, allowance.config.limitPerPeriod - usedQuantity),
        };
        if (
          allowance.item_type ===
            HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_PRODUCT &&
          allowance.product != null
        ) {
          allowanceProductTypeMap[allowance.product.id] =
            allowanceProductTypeMap[allowance.product.id] || [];
          allowanceProductTypeMap[allowance.product.id].push(
            remainingAllowance,
          );
        } else if (
          allowance.item_type ===
            HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.PRODUCT_CATEGORY &&
          allowance.item != null
        ) {
          allowanceProductTypeMap[allowance.item] =
            allowanceProductTypeMap[allowance.item] || [];
          allowanceProductTypeMap[allowance.item].push(remainingAllowance);
        } else if (
          allowance.item_type ===
            HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_SERVICE &&
          allowance.service != null
        ) {
          allowanceServiceTypeMap[allowance.service.id] =
            allowanceServiceTypeMap[allowance.service.id] || [];
          allowanceServiceTypeMap[allowance.service.id].push(
            remainingAllowance,
          );
        } else if (
          allowance.item_type ===
            HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.SERVICE_CATEGORY &&
          allowance.item != null
        ) {
          allowanceServiceTypeMap[allowance.item] =
            allowanceServiceTypeMap[allowance.item] || [];
          allowanceServiceTypeMap[allowance.item].push(remainingAllowance);
        }
      });
    }
  });

  return {
    productAllowances: allowanceProductTypeMap,
    serviceAllowances: allowanceServiceTypeMap,
  };
}

const calculateTotalRemainingAllowances = (
  allowances: Record<string, RemainingAllowanceQuantityDataType[]>,
): number => {
  return Object.values(allowances).reduce(
    (total, categoryAllowances) =>
      total +
      categoryAllowances.reduce(
        (sum, allowance) => sum + allowance.remainingQuantity,
        0,
      ),
    0,
  );
};

export const hasRemainingAllowances = (
  allowances: Record<string, RemainingAllowanceQuantityDataType[]>,
): boolean => {
  return calculateTotalRemainingAllowances(allowances) > 0;
};

export const hasAllowances = (
  item: BillingItem,
  availableAllowances: Record<string, RemainingAllowanceQuantityDataType[]>,
): boolean => {
  let categoryKey: string | null | undefined;
  let idKey: string | null | undefined;

  if ('product_detail' in item) {
    categoryKey = item.product_detail?.category;
    idKey = item.product_id;
  } else if ('service_detail' in item) {
    categoryKey = item.service_detail?.category;
    idKey = item.service_id;
  }

  const checkAllowances = (key: string | null | undefined): boolean =>
    Boolean(
      key && availableAllowances[key]?.some((a) => a.remainingQuantity > 0),
    );

  return checkAllowances(categoryKey) || checkAllowances(idKey);
};

export const getAllowanceDiscount = ({
  allowanceUsage,
  unitPrice,
}: {
  allowanceUsage: AllowanceUsageDataType;
  unitPrice: number;
}) => {
  if (allowanceUsage.allowance != null) {
    const { type, value } = allowanceUsage.allowance.config.value;
    switch (type) {
      case HEALTH_PLAN_ALLOWANCE_TYPE.PERCENTAGE:
        return {
          value: ((value * unitPrice) / 100.0) * allowanceUsage.quantity,
          type: DISCOUNT_TYPE.TOTAL,
        };
      case HEALTH_PLAN_ALLOWANCE_TYPE.FIXED_AMOUNT:
        return {
          value: value * allowanceUsage.quantity,
          type: DISCOUNT_TYPE.TOTAL,
        };
      default:
    }
  }
  return { value: 0, type: DISCOUNT_TYPE.PERCENTAGE };
};

export const hasValidAllowanceUsage = ({
  billingItem,
}: {
  billingItem: BillingItem;
}) => {
  return (
    globalSingleton.currentStore.health_plan_enabled &&
    billingItem.allowance_usage?.[0]?.allowance != null
  );
};
