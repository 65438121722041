import {
  createTRPCProxyClient,
  createTRPCReact,
  httpBatchLink,
} from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

import type { TrpcWorkRouter } from '@/routers/trpcWorkRouter';

import { globalSingleton } from '~/singletons/globalSingleton';
import { API_ENDPOINT } from '~/utils/networkUtils';

export type TrpcRouterInputs = inferRouterInputs<TrpcWorkRouter>;
export type TrpcRouterOutputs = inferRouterOutputs<TrpcWorkRouter>;

export const trpc = createTRPCReact<TrpcWorkRouter>();

const trpcLinks = [
  httpBatchLink({
    url: `${API_ENDPOINT}/api/trpc/work`,
    maxURLLength: 8000, // Cloudfront max is 8192, so we'll add some safety margin

    // You can pass any HTTP headers you wish here
    async headers() {
      return {
        authorization: globalSingleton.accessToken!,
        storeid: globalSingleton.currentStore?.id,
      };
    },
  }),
];

export const trpcClient = trpc.createClient({
  links: trpcLinks,
});

export const trcpProxyClient = createTRPCProxyClient<TrpcWorkRouter>({
  links: trpcLinks,
});
