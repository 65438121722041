export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const camelToCapitalizedWords = (str: string) => {
  return (
    str
      // Insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // Uppercase the first character
      .replace(/^./, (str) => {
        return str.toUpperCase();
      })
  );
};
