import React, { useEffect, useMemo, useRef, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { FormikProps } from 'formik';
import { useAtom } from 'jotai';

import { displayMainCalendarAtom } from '~/atoms/atoms';
import CalendarView, {
  CALENDAR_EVENT_TYPE,
} from '~/components/calendar/CalendarView';
import { COLORS } from '~/consts/colors';
import { trpc } from '~/lib/trpc';
import { employeeIdToColor } from '~/utils/calendar-utils';
import { CALENDAR_VIEW_TYPE } from '~/utils/local-enums';

import { AppointmentCreateForm } from './AppointmentCreate';
import { AppointmentUpdateForm } from './AppointmentUpdate';

const getInitialView = (isThreeDaysView: boolean): CALENDAR_VIEW_TYPE => {
  if (isThreeDaysView) {
    return CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_THREE_DAYS;
  }

  return CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_WEEK;
};

type AppointmentUpsertCalendarViewProps = {
  formik:
    | FormikProps<AppointmentUpdateForm>
    | FormikProps<AppointmentCreateForm>;
  isThreeDaysView: boolean;
};

export default function AppointmentUpsertCalendarView({
  formik,
  isThreeDaysView,
}: AppointmentUpsertCalendarViewProps) {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [, setDisplayMainCalendar] = useAtom(displayMainCalendarAtom);

  const [date, setDate] = useState<Date>(
    new Date(formik.values.appointment.start),
  );
  const [view, setView] = useState<CALENDAR_VIEW_TYPE>(
    getInitialView(isThreeDaysView),
  );

  const [selectedEmployees, setSelectedEmployees] = useState(
    formik.values.employee ? [formik.values.employee] : [],
  );

  useEffect(() => {
    const formikEmployee = [formik.values.employee].filter(Boolean);
    const allEmployees = [...formikEmployee, ...selectedEmployees];

    const allUniqueEmployees = Array.from(
      new Set([...allEmployees.map((e) => e.id)]),
    )
      .map((id) => allEmployees.find((e) => e.id === id))
      .filter((x) => Boolean(x));

    if (allUniqueEmployees.length === selectedEmployees.length) {
      return;
    }

    // @ts-ignore
    setSelectedEmployees(allUniqueEmployees);
  }, [formik.values.employee, selectedEmployees]);

  const calendarRef = useRef<FullCalendar>(null);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    const view = calendarApi?.view;

    if (!view) {
      return;
    }

    const appointmentStart = new Date(formik.values.appointment.start);
    if (
      appointmentStart < view.activeStart ||
      appointmentStart > view.activeEnd
    ) {
      calendarApi?.gotoDate(appointmentStart);
    }
  }, [formik.values.appointment]);

  const {
    data: { appointments = [], tasks = [], employees = {} } = {},
    isLoading,
  } = trpc.calendar.getCalendarAppointments.useQuery(
    {
      start: dateRange.startDate.toISOString(),
      end: dateRange.endDate.toISOString(),
      employeesIds: selectedEmployees.map((e) => e.id),
    },
    {
      keepPreviousData: true,
      enabled: dateRange.startDate != null && dateRange.endDate != null,
    },
  );

  useEffect(() => {
    setDisplayMainCalendar(false);

    return () => {
      setDisplayMainCalendar(true);
    };
  }, []);

  const eventsWithColor = useMemo(() => {
    const appointmentesEvents = [
      ...appointments.map((appointment) => {
        const backgroundColor = employeeIdToColor(
          appointment.employees[0].employee_id,
        );

        return {
          id: appointment.id,
          title: appointment.title,
          start: appointment.start,
          end: appointment.end,
          type: CALENDAR_EVENT_TYPE.APPOINTMENT,
          status: appointment.status,
          payment_status: appointment.payment_status,
          pet: appointment.pet,
          client: appointment.client,
          employees: appointment.employees.map(
            (employee) => employees[employee.employee_id],
          ),
          backgroundColor,
          textColor: '#222324',
          editable: false,
          resourceIds: appointment.employees.map(
            (employee) => employee.employee_id,
          ),
        };
      }),
      {
        id: formik.values.appointment.id!,
        title: formik.values.appointment.title,
        start: formik.values.appointment.start,
        end: formik.values.appointment.end,
        type: CALENDAR_EVENT_TYPE.APPOINTMENT,
        backgroundColor: COLORS.main,
        textColor: '#FFFFFF',
        client: formik.values.client
          ? {
              id: formik.values.client.id,
              last_name: null,
            }
          : undefined,
        pet: formik.values.pet
          ? {
              id: formik.values.pet.id,
              name: formik.values.pet.name ?? '',
            }
          : undefined,
        employees: formik.values.employee
          ? [
              {
                id: formik.values.employee.id,
                full_name: formik.values.employee.full_name ?? '',
                avatar_url: formik.values.employee.avatar_url,
              },
            ]
          : [],
        resourceIds: formik.values.employee ? [formik.values.employee.id] : [],
      },
    ];

    const tasksEvents = tasks.map((task) => {
      return {
        id: task.id,
        title: task.title,
        start: task.start,
        end: task.end,
        type: CALENDAR_EVENT_TYPE.BLOCK,
        employees: task.employees.map(
          (employee) => employees[employee.employee_id],
        ),
        color: 'rgba(0, 0, 0, 0.9)',
        resourceIds: task.employees.map((employee) => employee.employee_id),
      };
    });

    return [...appointmentesEvents, ...tasksEvents];
  }, [formik.values.appointment, formik.values.employee, appointments, tasks]);

  return (
    <CalendarView
      calendarRef={calendarRef}
      date={date}
      employees={employees}
      interfaceType='appointment'
      eventDrop={(arg) => {
        if (!arg.event.start || !arg.event.end) {
          return;
        }

        const currentEmployeeId = arg.event.extendedProps?.employees?.[0]?.id;
        const newEmployeeId = arg.event.getResources()?.[0]?._resource?.id;
        const isEmployeeUpdated =
          currentEmployeeId != null &&
          newEmployeeId != null &&
          currentEmployeeId !== newEmployeeId;

        if (isEmployeeUpdated) {
          const employeeExtendedProps =
            arg.event.getResources()?.[0]?._resource.extendedProps;
          formik.setFieldValue('employee', {
            id: arg.event.getResources()?.[0]?._resource?.id,
            full_name: employeeExtendedProps.full_name,
            avatar_url: employeeExtendedProps.avatar_url,
            role: employeeExtendedProps.role,
          });
        }

        formik.setFieldValue(
          'appointment.start',
          new Date(arg.event.start).toISOString(),
        );
        formik.setFieldValue(
          'appointment.end',
          new Date(arg.event.end).toISOString(),
        );
      }}
      eventResize={(arg) => {
        if (!arg.event.start || !arg.event.end) {
          return;
        }

        formik.setFieldValue(
          'appointment.start',
          new Date(arg.event.start).toISOString(),
        );
        formik.setFieldValue(
          'appointment.end',
          new Date(arg.event.end).toISOString(),
        );
      }}
      events={eventsWithColor}
      height={window.innerHeight - 250}
      initialDate={new Date(formik.values.appointment.start)}
      view={view}
      isLoading={isLoading}
      isThreeDaysView={isThreeDaysView}
      select={(arg) => {
        const duration = differenceInMinutes(
          new Date(formik.values.appointment.end),
          new Date(formik.values.appointment.start),
        );
        formik.setFieldValue(
          'appointment.start',
          new Date(arg.start).toISOString(),
        );
        formik.setFieldValue(
          'appointment.end',
          addMinutes(arg.start, duration).toISOString(),
        );
      }}
      selectedEmployees={selectedEmployees}
      setDate={setDate}
      setDateRange={setDateRange}
      setView={setView}
      setSelectedEmployees={setSelectedEmployees}
      shouldHideDays={false}
    />
  );
}
