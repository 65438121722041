import { z } from 'zod';

import isBrowser from './is-browser';

const LOCALHOST = 'localhost';
const WORK_STAGING = 'work-staging.lupapets.com';
const WORK_PRODUCTION = 'work.lupapets.com';

const IS_BROWSER = isBrowser();

export const BACKEND_NAMES = ['LOCAL', 'STAGING', 'PRODUCTION'] as const;
export type BackendName = (typeof BACKEND_NAMES)[number];
export const BACKENDS = Object.freeze({
  LOCAL: {
    displayName: 'Local',
    apiUrl: 'http://localhost:3000',
  },
  STAGING: {
    displayName: 'Staging',
    apiUrl: 'https://staging.lupapets.com',
  },
  PRODUCTION: {
    displayName: 'Production',
    apiUrl: 'https://lupapets.com',
  },
}) satisfies { [K in BackendName]: object };

export function setBackendOverride(backend: BackendName | null): void {
  if (!IS_BROWSER) {
    throw Error(`Can't set backend override outside a browser`);
  }

  if (backend === null) {
    sessionStorage.removeItem('LUPA_BACKEND_OVERRIDE');
  } else {
    if (backend === 'PRODUCTION') {
      /* eslint-disable no-alert */
      const confirmationText = "I know what I'm doing";
      const prodConfirm = window.prompt(
        `Are you sure you want to switch to using production backend? Please be very careful. Enter "${confirmationText}" to confirm.`,
      );
      if (prodConfirm?.toLowerCase() !== confirmationText.toLowerCase()) {
        alert('Cancelled');
        return;
      }
    }
    sessionStorage.setItem('LUPA_BACKEND_OVERRIDE', backend);
  }

  window.location.reload();
}

export function getBackendOverride(): BackendName | null {
  if (!IS_BROWSER) return null;

  const override = sessionStorage.getItem('LUPA_BACKEND_OVERRIDE');
  if (override) {
    return z.enum(BACKEND_NAMES).parse(override);
  }
  return null;
}

function getCurrentBackend(): BackendName {
  const override = getBackendOverride();
  if (override) {
    return override;
  }

  if (!IS_BROWSER) {
    return 'LOCAL';
  }
  if (document.location.hostname === LOCALHOST) {
    return 'LOCAL';
  }
  if (document.location.hostname === WORK_STAGING) {
    return 'STAGING';
  }
  if (document.location.hostname === WORK_PRODUCTION) {
    return 'PRODUCTION';
  }
  throw new Error(
    `Could not determine backend for hostname ${document.location.hostname}`,
  );
}

export const IS_DEV = IS_BROWSER
  ? document.location.hostname === LOCALHOST ||
    document.location.hostname === WORK_STAGING
  : process.env.NODE_ENV === 'development';

export const IS_PROD = IS_BROWSER
  ? document.location.hostname === WORK_PRODUCTION
  : process.env.NODE_ENV === 'production';

export const CURRENT_BACKEND: BackendName = getCurrentBackend();

export const API_ENDPOINT = BACKENDS[CURRENT_BACKEND].apiUrl;
