/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { useState } from 'react';

import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { IconChevronDown, IconX } from '@tabler/icons-react';
import { useAuth } from 'lupa-shared-ui/src/hooks/use-auth';
import {
  FrontendFeatureFlagsSetOverridesType,
  FrontendFeatureFlagsType,
  useFrontendFeatureFlag,
  useFrontendFeatureFlags,
} from 'lupa-shared-ui/src/hooks/use-feature-flags';
import { createPortal } from 'react-dom';
import * as R from 'remeda';
import { match } from 'ts-pattern';

import { getSupabaseConfigSync } from '~/lib/supabase';
import {
  BACKENDS,
  BACKEND_NAMES,
  BackendName,
  CURRENT_BACKEND,
  getBackendOverride,
  setBackendOverride,
} from '~/utils/networkUtils';

import PickerList from './PickerList';

function FrontendFeatureFlagManager({
  flags,
  setFlagOverrides,
}: {
  flags: FrontendFeatureFlagsType;
  setFlagOverrides: FrontendFeatureFlagsSetOverridesType;
}) {
  return (
    <>
      <Typography variant='h6'>Frontend Feature Flags</Typography>
      <PickerList
        items={R.pipe(
          flags,
          R.entries(),
          R.map(([flagName, flag]) => ({
            id: flagName,
            primaryText: flagName + (flag.override ? ' (Overriden)' : ''),
            secondaryText: `${flag.description} (Default: ${flag.default})`,
            isSelected: flag.value,
          })),
        )}
        onToggle={(key) => {
          const newTargetValue = !flags[key].value;
          // If the new target value is the same as the default value, we should clear the override
          const shouldClearOverride = flags[key].default === newTargetValue;

          setFlagOverrides((overrides) => ({
            ...overrides,
            [key]: shouldClearOverride ? undefined : newTargetValue,
          }));
        }}
        actionAppearsOn='LEFT'
        listType='TOGGLEABLE'
        rowHeight={75}
        listHeight={400}
        secondaryTypographyProps={{
          noWrap: false,
        }}
      />
    </>
  );
}

const BannerButton = styled(Button)({
  color: 'white',
  textTransform: 'none',
  padding: 0,
  marginLeft: '4px',
  minWidth: 'auto',
  font: 'inherit',
  '& .MuiButton-endIcon': {
    marginLeft: '2px',
  },
});

function OverlayBanner({
  children,
  color,
  stripeColor,
}: {
  children: React.ReactNode;
  color: string;
  stripeColor: string;
}) {
  const cornerSize = 8;
  const stripeWidth = 5;

  const bannerStyle: React.CSSProperties = {
    position: 'fixed',
    top: '0',
    left: '50%',
    height: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    background: `repeating-linear-gradient(45deg, ${color}, ${color} ${stripeWidth}px, ${stripeColor} ${stripeWidth}px, ${stripeColor} ${stripeWidth * 2}px)`,
    color: 'white',
    textAlign: 'center',
    fontFamily: 'sans-serif',
    fontWeight: '600',
    fontSize: '12px',
    borderRadius: '0 0 8px 8px ',
    transform: 'translateX(-50%)',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const beforeAfterStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    width: `${cornerSize}px`,
    height: `${cornerSize}px`,
  };

  return createPortal(
    <div style={bannerStyle}>
      <div
        style={{
          ...beforeAfterStyle,
          left: `-${cornerSize}px`,
          backgroundImage: `radial-gradient(circle at 0 100%, transparent ${cornerSize}px, ${color} ${cornerSize}px)`,
        }}
      />
      <div
        style={{
          ...beforeAfterStyle,
          right: `-${cornerSize}px`,
          backgroundImage: `radial-gradient(circle at 100% 100%, transparent ${cornerSize}px, ${color} ${cornerSize}px)`,
        }}
      />
      {children}
    </div>,
    document.body,
  );
}

function DevModeBanner() {
  const { isAuthenticated } = useAuth();
  const { flags, setOverrides: setFlagOverrides } = useFrontendFeatureFlags();
  const [showFeatureFlagManager, setShowFeatureFlagManager] = useState(false);

  const haveBackendOverride = getBackendOverride() != null;
  const { value: devtoolsFeatureFlagEnabled } =
    useFrontendFeatureFlag('SHOW_DEV_TOOLS');

  const bannerShownByDefault =
    devtoolsFeatureFlagEnabled || haveBackendOverride;
  const usingLocalhost =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';
  const allowChangingBackend =
    usingLocalhost || haveBackendOverride || isAuthenticated;

  const [showBanner, setShowBanner] = useState(bannerShownByDefault);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  if (!showBanner) return null;

  const backend = CURRENT_BACKEND;
  const supabaseUrl = getSupabaseConfigSync()?.supabase.url;

  const supabaseBackendName = match(supabaseUrl)
    .returnType<BackendName | null>()
    .with('http://127.0.0.1:54321', () => 'LOCAL')
    .with('http://localhost:54321', () => 'LOCAL')
    .with('https://bibzwlnujjpkwtbsirqy.supabase.co', () => 'STAGING')
    .with('https://auth.lupapets.com', () => 'PRODUCTION')
    .otherwise(() => null);

  const backendColours = {
    LOCAL: '#005f2b',
    STAGING: '#007f7f',
    PRODUCTION: '#c8102e',
    UNKNOWN: '#000',
  };

  let displayBackendName = BACKENDS[backend].displayName;
  if (supabaseBackendName !== backend) {
    const supabaseBackendDisplayName =
      supabaseBackendName && BACKENDS[supabaseBackendName]
        ? BACKENDS[supabaseBackendName].displayName
        : 'UNKNOWN';

    displayBackendName += ` (${supabaseBackendDisplayName} DB)`;
  }

  const handleBackendChange = (newBackend: BackendName | null) => {
    setBackendOverride(newBackend);
    setMenuAnchorEl(null);
  };

  return (
    <>
      <OverlayBanner
        color={backendColours[backend]}
        stripeColor={backendColours[supabaseBackendName ?? 'UNKNOWN']}
      >
        <Stack direction='row' gap={2}>
          <BannerButton
            onClick={(event) =>
              allowChangingBackend
                ? setMenuAnchorEl(
                    menuAnchorEl == null ? event.currentTarget : null,
                  )
                : null
            }
            endIcon={
              allowChangingBackend ? <IconChevronDown size={12} /> : undefined
            }
            sx={{
              pointerEvents: allowChangingBackend ? 'auto' : 'none',
            }}
          >
            Env: {displayBackendName}
          </BannerButton>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
            MenuListProps={{ dense: true }}
          >
            {BACKEND_NAMES.map((backendName) => (
              <MenuItem
                key={backendName}
                onClick={() => handleBackendChange(backendName)}
              >
                {BACKENDS[backendName].displayName}
              </MenuItem>
            ))}
            <Divider />
            <MenuItem onClick={() => handleBackendChange(null)}>
              Reset to Default
            </MenuItem>
          </Menu>
          <BannerButton
            onClick={() => setShowFeatureFlagManager(true)}
            endIcon={
              allowChangingBackend ? <IconChevronDown size={12} /> : undefined
            }
          >
            Flags (
            {
              Object.values(flags).filter((flag) => flag.override !== undefined)
                .length
            }
            )
          </BannerButton>
          <IconButton
            onClick={() => setShowBanner(false)}
            sx={{
              color: 'white',
              marginRight: -1,
              padding: 0,
            }}
          >
            <IconX size={12} />
          </IconButton>
        </Stack>
      </OverlayBanner>
      <Dialog
        open={showFeatureFlagManager}
        onClose={() => setShowFeatureFlagManager(false)}
      >
        <Stack direction='column' sx={{ width: '600px', padding: 2 }}>
          <Stack direction='row' justifyContent='flex-end'>
            <IconButton onClick={() => setShowFeatureFlagManager(false)}>
              <IconX size={20} />
            </IconButton>
          </Stack>
          <FrontendFeatureFlagManager
            flags={flags}
            setFlagOverrides={setFlagOverrides}
          />
        </Stack>
      </Dialog>
    </>
  );
}

export default DevModeBanner;
