import React from 'react';

import { Stack, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

import { camelToCapitalizedWords } from '../../utils/capitalize';

interface KeyValueTextFieldProps {
  label: string;
  value: string | number | null;
  sx?: any;
  viewMode?: 'light' | 'dark';
  testIdPrefix?: string;
}

export default function KeyValueTextField({
  label,
  value,
  sx,
  viewMode = 'light',
  testIdPrefix = '',
}: KeyValueTextFieldProps) {
  return (
    <Stack direction='column' spacing={0.5} sx={sx}>
      <Typography
        variant='subtitle2'
        noWrap
        sx={{
          color:
            viewMode === 'light' ? blueGrey[500] : 'rgba(255, 255, 255, 0.8)',
        }}
        data-testid={`${testIdPrefix}-key-value-text-key`}
      >
        {camelToCapitalizedWords(label)}
      </Typography>
      <Typography
        variant='subtitle1'
        ml={1}
        sx={{
          color: viewMode === 'light' ? 'inherit' : 'white',
        }}
        data-testid={`${testIdPrefix}-key-value-text-value`}
      >
        {value || 'Not Available'}
      </Typography>
    </Stack>
  );
}
