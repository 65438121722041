import React from 'react';

import { Stack, Typography } from '@mui/material';

import ClientDetailsSection from '~/components/clients/ClientDetailsSection';
import { trpc } from '~/lib/trpc';

import { AppointmentsListTable } from '../appointments/AppointmentsListTable';
import CardSection from '../ui/CardSection';
import Loading from '../ui/Loading';
import { NotFound } from '../ui/NotFound';
import PetDetailsSection from './PetDetailsSection';

type PetAndClientDetailsProps = {
  petId: string;
  clientId: string;
};

export default function PetAndClientDetails({
  petId,
  clientId,
}: PetAndClientDetailsProps) {
  const {
    data: healthPlanSubscriptions,
    isLoading: isLoadingClientSubscriptions,
  } = trpc.healthPlans.getClientSubscriptions.useQuery({ clientId: clientId! });
  const { data: petData, isLoading: isPetLoading } = trpc.pets.getPet.useQuery({
    petId,
  });
  const { data: clientData, isLoading: isClientLoading } =
    trpc.clients.getClient.useQuery({
      clientId,
    });

  if (isPetLoading || isClientLoading || isLoadingClientSubscriptions) {
    return <Loading style={{ width: 500, height: 500 }} />;
  }

  if (!clientData || !petData) {
    return <NotFound message='Pet not found!' showGoBack />;
  }

  const clientPet = petData.owner[0];

  return (
    <Stack direction='column' spacing={2}>
      <ClientDetailsSection
        client={clientData}
        healthPlanSubscriptions={healthPlanSubscriptions}
      />

      <PetDetailsSection
        pet={petData}
        clientId={clientId}
        petNotes={clientPet?.pet_notes}
        registeredAt={clientPet?.created_at}
      />

      <CardSection title='Appointments'>
        {clientData.appointments.length === 0 && (
          <Typography>No appointments records</Typography>
        )}

        {clientData.appointments.length > 0 && (
          <AppointmentsListTable
            count={clientData.appointments.length}
            items={clientData.appointments}
            isEditable={false}
          />
        )}
      </CardSection>
    </Stack>
  );
}
