import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { addMinutes } from 'date-fns';
import { useFormik } from 'formik';
import { matchIsValidTel } from 'mui-tel-input';
import toast from 'react-hot-toast';
import { z } from 'zod';
import { toFormikValidate, toFormikValidationSchema } from 'zod-formik-adapter';

import { invalidateAppointmentQueries } from '~/api/mutations';
import { COLORS } from '~/consts/colors';
import { trpc } from '~/lib/trpc';
import {
  APPOINTMENT_NOTIFICATION_REASON,
  APPOINTMENT_STATUS,
  APPOINTMENT_TYPE,
  DISCOUNT_TYPE,
  PET_SEX,
} from '~/utils/enums';
import {
  billingProductValidationSchema,
  billingServiceValidationSchema,
} from '~/validators/appointmentValidators';

import { useRouter } from '../../../hooks/use-router';
import { paths } from '../../../paths';
import AppointmentUpsertCalendarView from './AppointmentUpsertCalendarView';
import AppointmentUpsertConfirmSection from './AppointmentUpsertConfirmSection';
import AppointmentUpsertDetailsSection from './AppointmentUpsertDetailsSection';
import AppointmentUpsertInvoicingView from './AppointmentUpsertInvoicingView';

type InitialValuesParamsType = {
  range?: {
    start: string;
    end: string;
  };
  defaultClient?: {
    id: string;
    full_name: string;
  };
  defaultPet?: {
    id: string;
    name: string;
  };
  defaultEmployee?: {
    id: string;
    full_name: string;
    role: string;
  };
};

export type AppointmentCreateForm = z.infer<typeof validationSchema>;
export type AppointmentCreateFormInitialValues = ReturnType<
  typeof useGetInitialValues
>;

const useGetInitialValues = ({
  range,
  defaultClient,
  defaultPet,
  defaultEmployee,
}: InitialValuesParamsType) => {
  return useMemo(() => {
    const mainEmployee = defaultEmployee ?? null;
    const pet = defaultPet ?? null;
    const client = defaultClient ?? null;

    return {
      newClient: null,
      client,

      newPet: null,
      pet,

      employee: mainEmployee ?? null,
      appointment: {
        pet,
        price: 0,
        visit_type: {
          id: '',
          name: '',
          category: APPOINTMENT_TYPE.OTHER,
        },
        title: '',
        start: range?.start
          ? new Date(new Date(range.start).setSeconds(0, 0)).toISOString()
          : new Date(new Date().setMinutes(0, 0, 0)).toISOString(),
        end: range?.end
          ? new Date(new Date(range.end).setSeconds(0, 0)).toISOString()
          : addMinutes(
              new Date(new Date().setMinutes(0, 0, 0)),
              60,
            ).toISOString(),
        status: APPOINTMENT_STATUS.REQUESTED,
        notification_reason: null,
        multi_id: null,
      },

      totalPrice: 0,
      apply_discount: false,
      discount: 0,
      discount_type: DISCOUNT_TYPE.PERCENTAGE,
      services: [],
      products: [],
      prescribed_products: [],
      notifyUser: true,
      notificationReason: null,
    } as AppointmentCreateForm;
  }, [range, defaultClient, defaultPet, defaultEmployee]);
};

const validationSchema = z
  .object({
    client: z
      .object({
        id: z.string().min(1, 'Client is required'),
      })
      .nullish(),
    newClient: z
      .object({
        first_name: z.string().max(100).min(1, 'First name is required'),
        last_name: z.string().max(100).min(1, 'Last name is required'),
        email: z
          .union([z.literal(''), z.string().email('Invalid email address')])
          .nullish(),
        phone: z
          .string()
          .refine((value) => !value || matchIsValidTel(value), {
            message: 'Invalid phone number',
          })
          .nullish(),
      })
      .nullish(),
    pet: z
      .object(
        {
          id: z.string().min(1, 'Pet is required'),
          name: z.string(),
          owner_full_name: z.string(),
        },
        {
          message: 'Pet is required',
        },
      )
      .nullish(),
    newPet: z
      .object({
        name: z.string().max(100).min(1, 'Pet name is required'),
        species: z.string().max(100).min(1, 'Pet species is required'),
        breed: z.string().max(100).min(1, 'Pet breed is required'),
        sex: z.nativeEnum(PET_SEX),
        weight: z.number().nullish(),
        dob: z.string().nullish(),
      })
      .nullish(),
    employee: z.object(
      {
        id: z.string().min(1, 'Employee is required'),
        full_name: z.string(),
        avatar_url: z.string().nullish(),
      },
      {
        message: 'Employee is required',
      },
    ),
    appointment: z.object({
      id: z.string().min(1, 'Appointment is required').optional(),
      visit_type: z.object(
        {
          id: z.string().min(1, 'Appointment type is required'),
          name: z.string().min(1, 'Appointment type is required'),
          category: z.nativeEnum(APPOINTMENT_TYPE),
        },
        {
          message: 'Appointment type is required',
        },
      ),
      status: z.string(),
      title: z.string().min(1, 'Title is required'),
      location: z
        .string()
        .max(255, 'Location must be 255 characters or shorter')
        .optional(),
      client_notes: z.string().nullish(),
      booking_notes: z.string().nullish(),
      start: z.string().datetime({
        offset: true,
        message: 'Start date is required',
      }),
      end: z.string().datetime({
        offset: true,
        message: 'End date is required',
      }),
      multi_id: z.string().uuid().nullable(),
    }),

    totalPrice: z.number(),
    apply_discount: z.boolean(),
    discount: z.number(),
    discount_type: z.nativeEnum(DISCOUNT_TYPE),
    services: z.array(billingServiceValidationSchema),
    products: z.array(billingProductValidationSchema),
    prescribed_products: z.array(billingProductValidationSchema).optional(),

    notifyUser: z.boolean(),
    notificationReason: z.nativeEnum(APPOINTMENT_NOTIFICATION_REASON).nullish(),
  })
  .superRefine((data, ctx) => {
    if (!data.client && !data.newClient) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['client'],
        message: 'Client cannot be empty.',
      });
    }

    if (!data.pet && !data.newPet) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['pet'],
        message: 'Pet cannot be empty.',
      });
    }
  });

type AppointmentCreateProps = {
  range?: {
    start: string;
    end: string;
  };
  defaultClient?: {
    id: string;
    full_name: string;
  };
  defaultPet?: {
    id: string;
    name: string;
    owner_full_name: string;
  };
  defaultEmployee?: {
    id: string;
    full_name: string;
    role: string;
  };

  handleClose?: () => void;
  isNotification?: boolean;
};

export default function AppointmentCreate({
  range,
  isNotification = false,
  handleClose,
  defaultClient,
  defaultPet,
  defaultEmployee,
}: AppointmentCreateProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [notifyUserChangedManually, setNotifyUserChangedManually] =
    useState(false);
  const [autofilledAppointmentId, setAutofilledAppointmentId] =
    useState<Optional<string>>(undefined);

  const router = useRouter();

  const upsertAppointmentMutation =
    trpc.appointments.createAppointment.useMutation({
      onSuccess: (upsertedAppointmentId) => {
        invalidateAppointmentQueries(upsertedAppointmentId, isNotification);

        toast.success('Appointment created');
        formik.resetForm();

        router.replace(paths.calendar.index);
        handleClose?.();
      },
      onError: () => {
        toast.error('Something went wrong!');
        handleClose?.();
      },
    });

  const initialValues = useGetInitialValues({
    range,
    defaultClient,
    defaultPet,
    defaultEmployee,
  });

  const formik = useFormik<AppointmentCreateForm>({
    initialValues,
    enableReinitialize: false,
    validationSchema: toFormikValidationSchema(validationSchema),
    validate: toFormikValidate(validationSchema),
    onSubmit: async () => {
      await upsertAppointmentMutation.mutateAsync({
        data: formik.values,
      });
    },
  });

  return (
    <Grid container spacing={2} flexGrow={1}>
      <Grid xs={4}>
        <Stack
          direction='column'
          spacing={2}
          sx={{
            backgroundColor: '#1C2536',
            px: 2,
            py: 2,
            borderRadius: 4,
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Stack direction='row' gap={2} justifyContent='space-between'>
            <Box
              sx={{
                backgroundColor: COLORS.main,
                height: 8,
                flexGrow: 1,
                borderRadius: 4,
              }}
            />
            <Box
              sx={{
                backgroundColor:
                  activeStep === 1 ? COLORS.main : 'rgba(255, 255, 255, 0.2)',
                height: 8,
                flexGrow: 1,
                borderRadius: 4,
              }}
            />
          </Stack>

          {activeStep === 0 && (
            <AppointmentUpsertDetailsSection
              formik={formik}
              action='create'
              setActiveStep={setActiveStep}
              showCreateClient={showCreateClient}
              setShowCreateClient={setShowCreateClient}
              setAutofilledAppointmentId={setAutofilledAppointmentId}
              handleClose={handleClose}
            />
          )}

          {activeStep === 1 && (
            <AppointmentUpsertConfirmSection
              formik={formik}
              setActiveStep={setActiveStep}
              initialValues={initialValues}
              notifyUserChangedManually={notifyUserChangedManually}
              setNotifyUserChangedManually={setNotifyUserChangedManually}
            />
          )}
        </Stack>
      </Grid>

      <Grid xs={8}>
        {activeStep === 0 && (
          <AppointmentUpsertCalendarView isThreeDaysView formik={formik} />
        )}

        {activeStep === 1 && (
          <AppointmentUpsertInvoicingView
            formik={formik}
            autofilledAppointmentId={autofilledAppointmentId}
          />
        )}
      </Grid>
    </Grid>
  );
}
