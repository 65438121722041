import { useMemo } from 'react';

import { FormikProps } from 'formik';

import {
  getFieldError,
  getFieldHelperText,
  getNestedValue,
} from '~/utils/formik-utils';

export default function useFormUtils(
  formik: FormikProps<any>,
  path: string,
): {
  value: any;
  touched: boolean;
  error: boolean;
  helperText: string;
} {
  return useMemo(() => {
    const value = getNestedValue(formik.values, path);
    const touched = getNestedValue(formik.touched, path);
    const error = getFieldError(formik, path);
    const helperText = error ? getFieldHelperText(formik, path) : '';

    return {
      value,
      touched,
      error,
      helperText,
    };
  }, [formik, path]);
}
