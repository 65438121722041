import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { MobileNavItem } from './MobileNavItem';

const renderItems = ({ depth = 0, items, pathname }: any) =>
  items.reduce(
    (acc: any, item: any) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname,
      }),
    [],
  );

const reduceChildRoutes = ({ acc, depth, item, pathname }: any) => {
  const pathSegments = pathname.split('?')[0].split('/');
  const itemPathSegments = item.path.split('?')[0].split('/');
  let active = false;

  if (item.path === '/' && pathname === '/') {
    active = true;
  } else if (
    item.path !== '/' &&
    pathname !== '/' &&
    pathSegments[1] === itemPathSegments[1]
  ) {
    active = true;
  }

  if (item.items) {
    acc.push(
      <MobileNavItem
        active={active}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        open={active}
        title={item.title}
      >
        <Stack
          component='ul'
          spacing={0.5}
          sx={{
            listStyle: 'none',
            m: 0,
            p: 0,
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname,
          })}
        </Stack>
      </MobileNavItem>,
    );
  } else {
    acc.push(
      <MobileNavItem
        active={active}
        depth={depth}
        disabled={item.disabled}
        external={item.external}
        icon={item.icon}
        key={item.title}
        label={item.label}
        path={item.path}
        title={item.title}
      />,
    );
  }

  return acc;
};

type MobileNavSectionProps = {
  items: any[];
  pathname: string;
  subheader: string;
};

export const MobileNavSection = ({
  items = [],
  pathname,
  subheader = '',
  ...other
}: MobileNavSectionProps) => {
  return (
    <Stack
      component='ul'
      spacing={0.5}
      sx={{
        listStyle: 'none',
        m: 0,
        p: 0,
      }}
      {...other}
    >
      {subheader && (
        <Box
          component='li'
          sx={{
            color: 'var(--nav-section-title-color)',
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.66,
            mb: 1,
            ml: 1,
            textTransform: 'uppercase',
          }}
        >
          {subheader}
        </Box>
      )}
      {renderItems({ items, pathname })}
    </Stack>
  );
};
