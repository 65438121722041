import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { FormikProps } from 'formik';

import Loading from '~/components/ui/Loading';
import { trpc } from '~/lib/trpc';

import AppointmentExtras, {
  AppointmentExtrasFormikValues,
} from '../AppointmentExtras';
import { AppointmentCreateForm } from './AppointmentCreate';
import { AppointmentUpdateForm } from './AppointmentUpdate';

export default function AppointmentUpsertInvoicingView({
  formik,
  autofilledAppointmentId,
}: {
  formik:
    | FormikProps<AppointmentUpdateForm>
    | FormikProps<AppointmentCreateForm>;
  autofilledAppointmentId?: string;
}) {
  // @ts-ignore - Could not find a better way to do this for now :/
  const invoicingFormik = formik as FormikProps<AppointmentExtrasFormikValues>;

  const { data, isLoading } =
    trpc.appointments.getAppointmentBillingItems.useQuery(
      {
        appointmentId: formik.values.appointment.id,
        visitTypeId: formik.values.appointment.visit_type.id,
        autofilledAppointmentId,
      },
      {
        enabled:
          !!formik.values.appointment.id ||
          !!formik.values.appointment.visit_type.id ||
          !!autofilledAppointmentId,
      },
    );

  useEffect(() => {
    if (data) {
      invoicingFormik.setFieldValue('services', data.services);

      invoicingFormik.setFieldValue('products', data.products);

      invoicingFormik.setFieldValue(
        'prescribed_products',
        data.prescribed_products,
      );
    }
  }, [data]);

  if (isLoading || formik.values.services == null) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        overflowX: 'auto',
        pt: 1,
      }}
    >
      {formik.values.pet && formik.values.client ? (
        <AppointmentExtras
          formik={invoicingFormik}
          showPrescriptions
          pet={{
            id: formik.values.pet.id,
            clientId: formik.values.client.id,
          }}
        />
      ) : (
        <AppointmentExtras formik={invoicingFormik} />
      )}
    </Box>
  );
}
