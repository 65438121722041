import { APPOINTMENT_STATUS } from './enums';

export const FLOW_CHART_COLUMNS: Array<{
  id: string;
  index: number;
  value: StringWithAutocompleteOptions<APPOINTMENT_STATUS>;
}> = [
  {
    id: '0',
    index: 0,
    value: APPOINTMENT_STATUS.REQUESTED,
  },
  {
    id: '10',
    index: 10,
    value: APPOINTMENT_STATUS.CONFIRMED,
  },
  {
    id: '20',
    index: 20,
    value: APPOINTMENT_STATUS.CHECKED_IN,
  },
  {
    id: '30',
    index: 30,
    value: APPOINTMENT_STATUS.IN_PROGRESS,
  },
  {
    id: '40',
    index: 40,
    value: APPOINTMENT_STATUS.READY_FOR_CHECKOUT,
  },
  {
    id: '50',
    index: 50,
    value: APPOINTMENT_STATUS.COMPLETED,
  },
  {
    id: '60',
    index: 60,
    value: APPOINTMENT_STATUS.NO_SHOW,
  },
  {
    id: '70',
    index: 70,
    value: APPOINTMENT_STATUS.CANCELLED,
  },
];

export const IN_PROGRESS_STATUS_INDEX = 3;
