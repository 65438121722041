import React, { useMemo, useState } from 'react';

import { $generateNodesFromDOM } from '@lexical/html';
import {
  Autocomplete,
  Button,
  ButtonBase,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { IconTemplate } from '@tabler/icons-react';
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from 'lexical';

import DialogSection from '~/components/ui/DialogSection';
import { useDialog } from '~/hooks/use-dialog';
import { globalSingleton } from '~/singletons/globalSingleton';
import { STORE_TEMPLATE_TYPE } from '~/utils/enums';

function InsertTemplateDialog({
  open,
  handleClose,
  activeEditor,
  templateType,
}: {
  open: boolean;
  handleClose: () => void;
  activeEditor: LexicalEditor;
  templateType: STORE_TEMPLATE_TYPE;
}): JSX.Element {
  const [selectedTemplateId, setSelectedTemplateId] =
    useState<Nullish<string>>(null);

  const templates = useMemo(() => {
    return globalSingleton.currentStore?.templates.filter(
      (t) => t.type === templateType,
    );
  }, [templateType]);

  const onClick = () => {
    const selectedTemplate = globalSingleton.currentStore?.templates.find(
      (t) => t.id === selectedTemplateId,
    );

    if (!selectedTemplate) {
      return;
    }

    activeEditor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(selectedTemplate.value, 'text/html');
      const nodes = $generateNodesFromDOM(activeEditor, dom);
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        selection.insertNodes(nodes);
      } else {
        const root = $getRoot();
        root.append(...nodes);
      }
    });

    handleClose();
  };

  return (
    <DialogSection title='Insert Template' open={open} onClose={handleClose}>
      <Stack direction='column' alignItems='center' gap={2}>
        <Autocomplete
          options={templates}
          getOptionLabel={(option) => option.name}
          onChange={(event: any, newValue) => {
            setSelectedTemplateId(newValue?.id);
          }}
          renderInput={(params) => (
            <TextField {...params} label='Select a template' />
          )}
          sx={{ width: 300 }}
        />

        <Button
          variant='contained'
          disabled={!selectedTemplateId}
          onClick={onClick}
        >
          Confirm
        </Button>
      </Stack>
    </DialogSection>
  );
}

export default function EditorToolbarInsertTemplate({
  isEditable,
  activeEditor,
  templateType,
}: {
  isEditable: boolean;
  activeEditor: LexicalEditor;
  templateType?: STORE_TEMPLATE_TYPE;
}) {
  const insertTemplateDialog = useDialog();

  return (
    <>
      <Tooltip title='Insert template' placement='top'>
        <ButtonBase
          onClick={insertTemplateDialog.handleOpen}
          disabled={!isEditable}
          sx={{
            borderRadius: 1,
            padding: 0.5,
            color: 'black',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
        >
          <Stack
            direction='row'
            alignItems='center'
            gap={0.5}
            color='text.secondary'
          >
            <IconTemplate size={20} />
          </Stack>
        </ButtonBase>
      </Tooltip>

      {insertTemplateDialog.open && templateType != null && (
        <InsertTemplateDialog
          open={insertTemplateDialog.open}
          handleClose={insertTemplateDialog.handleClose}
          activeEditor={activeEditor}
          templateType={templateType}
        />
      )}
    </>
  );
}
