import { Address } from '#types/database-custom.types';

export const formatAddress = (address: Nullish<Address>) => {
  if (!address) {
    return null;
  }

  return [
    address.line_1,
    address.line_2,
    address.line_3,
    address.city,
    address.country,
    address.postcode,
  ]
    .filter(Boolean)
    .join(', ');
};
