import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

type NotFoundProps = {
  message?: string;
  showGoBack?: boolean;
};

export const NotFound = ({ message, showGoBack = false }: NotFoundProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Box
        component='img'
        src='/assets/errors/error-404.png'
        sx={{
          height: 'auto',
          maxWidth: 120,
        }}
      />
      <Typography color='text.secondary' sx={{ mt: 2 }} variant='subtitle2'>
        {message}
      </Typography>

      {showGoBack && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6,
          }}
        >
          <Button onClick={() => navigate(-1)}>Go Back</Button>
        </Box>
      )}
    </Box>
  );
};
