import React, { useEffect, useState } from 'react';

import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { INSERT_TABLE_COMMAND } from '@lexical/table';
import {
  Button,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  IconChevronDown,
  IconColumns,
  IconPageBreak,
  IconPlus,
  IconSeparator,
  IconTable,
} from '@tabler/icons-react';
import { LexicalEditor } from 'lexical';

import DialogSection from '~/components/ui/DialogSection';
import NumberInput from '~/components/ui/NumberInput';
import { useDialog } from '~/hooks/use-dialog';
import { usePopover } from '~/hooks/use-popover';

import { INSERT_LAYOUT_COMMAND } from '../LayoutPlugin';
import { INSERT_PAGE_BREAK } from '../PageBreakPlugin';

const LAYOUTS = [
  { label: '2 columns (equal width)', value: '1fr 1fr' },
  { label: '2 columns (25% - 75%)', value: '1fr 3fr' },
  { label: '3 columns (equal width)', value: '1fr 1fr 1fr' },
  { label: '3 columns (25% - 50% - 25%)', value: '1fr 2fr 1fr' },
  { label: '4 columns (equal width)', value: '1fr 1fr 1fr 1fr' },
];

function InsertLayoutDialog({
  open,
  handleClose,
  activeEditor,
}: {
  open: boolean;
  handleClose: () => void;
  activeEditor: LexicalEditor;
}) {
  const [layout, setLayout] = useState(LAYOUTS[0].value);

  const onClick = () => {
    activeEditor.dispatchCommand(INSERT_LAYOUT_COMMAND, layout);
    handleClose();
  };

  return (
    <DialogSection
      title='Insert Columns Layout'
      open={open}
      onClose={handleClose}
    >
      <Stack direction='column' alignItems='center' gap={2}>
        <Select
          value={layout}
          size='small'
          onChange={(event) => {
            const newValue = event.target.value as string;
            setLayout(newValue);
          }}
          displayEmpty
        >
          {LAYOUTS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          ))}
        </Select>

        <Button onClick={onClick} variant='contained'>
          Insert
        </Button>
      </Stack>
    </DialogSection>
  );
}

function InsertTableDialog({
  open,
  handleClose,
  activeEditor,
}: {
  open: boolean;
  handleClose: () => void;
  activeEditor: LexicalEditor;
}): JSX.Element {
  const [rows, setRows] = useState(5);
  const [columns, setColumns] = useState(5);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (rows > 0 && rows <= 500 && columns > 0 && columns <= 50) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [rows, columns]);

  const onClick = () => {
    activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
      columns: columns.toString(),
      rows: rows.toString(),
    });

    handleClose();
  };

  return (
    <DialogSection
      title='Insert Columns Layout'
      open={open}
      onClose={handleClose}
    >
      <Stack direction='column' alignItems='center' gap={2}>
        <NumberInput
          placeholder='# of rows (1-500)'
          label='Rows'
          onChange={setRows}
          value={rows}
          showArrows
          step={1}
          min={0}
          max={500}
        />

        <NumberInput
          placeholder='# of columns (1-50)'
          label='Columns'
          onChange={setColumns}
          value={columns}
          showArrows
          step={1}
          min={0}
          max={50}
        />

        <Button variant='contained' disabled={isDisabled} onClick={onClick}>
          Confirm
        </Button>
      </Stack>
    </DialogSection>
  );
}

export default function EditorToolbarInsertDropdown({
  isEditable,
  activeEditor,
}: {
  isEditable: boolean;
  activeEditor: LexicalEditor;
}) {
  const popover = usePopover();
  const insertLayoutDialog = useDialog();
  const insertTableDialog = useDialog();

  const handleInsert = (action: any) => {
    popover.handleClose();
    action();
  };

  return (
    <>
      <Tooltip title='Insert element' placement='top'>
        <ButtonBase
          ref={popover.anchorRef}
          onClick={popover.handleOpen}
          disabled={!isEditable}
          sx={{
            borderRadius: 1,
            padding: 0.5,
            color: 'black',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
        >
          <Stack
            direction='row'
            alignItems='center'
            gap={0.5}
            color='text.secondary'
          >
            <IconPlus size={20} />

            <IconChevronDown size={20} />
          </Stack>
        </ButtonBase>
      </Tooltip>

      <Menu
        anchorEl={popover.anchorRef.current}
        open={popover.open}
        onClose={popover.handleClose}
      >
        <MenuItem
          onClick={() =>
            handleInsert(() => {
              activeEditor.dispatchCommand(
                INSERT_HORIZONTAL_RULE_COMMAND,
                undefined,
              );
            })
          }
        >
          <ListItemIcon>
            <IconSeparator fontSize={18} />
          </ListItemIcon>
          <ListItemText>Horizontal Rule</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() =>
            handleInsert(() => {
              activeEditor.dispatchCommand(INSERT_PAGE_BREAK, undefined);
            })
          }
        >
          <ListItemIcon>
            <IconPageBreak fontSize={18} />
          </ListItemIcon>
          <ListItemText>Page Break</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.handleClose();
            insertTableDialog.handleOpen();
          }}
        >
          <ListItemIcon>
            <IconTable fontSize={18} />
          </ListItemIcon>
          <ListItemText>Table</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            popover.handleClose();
            insertLayoutDialog.handleOpen();
          }}
        >
          <ListItemIcon>
            <IconColumns fontSize={18} />
          </ListItemIcon>
          <ListItemText>Columns Layout</ListItemText>
        </MenuItem>
      </Menu>

      {insertLayoutDialog.open && (
        <InsertLayoutDialog
          open={insertLayoutDialog.open}
          handleClose={insertLayoutDialog.handleClose}
          activeEditor={activeEditor}
        />
      )}

      {insertTableDialog.open && (
        <InsertTableDialog
          open={insertTableDialog.open}
          handleClose={insertTableDialog.handleClose}
          activeEditor={activeEditor}
        />
      )}
    </>
  );
}
