import * as React from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export default function PrescriptionCancelDialog({
  open,
  isLoading,
  onDismiss,
  onCancelPrescription,
}: {
  open: boolean;
  isLoading: boolean;
  onDismiss: () => void;
  onCancelPrescription: () => void;
}) {
  return (
    <Dialog open={open} onClose={onDismiss}>
      <Box sx={{ p: 1 }}>
        <DialogTitle>Cancel Prescription?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will label the prescription as cancelled. This can't be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={onDismiss}>
            Back
          </Button>
          <LoadingButton
            loading={isLoading}
            variant='contained'
            onClick={onCancelPrescription}
            autoFocus
            color='error'
          >
            Cancel Prescription
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
