import z from 'zod';

import {
  APPOINTMENT_TYPE,
  DISCOUNT_TYPE,
  HEALTH_PLAN_ALLOWANCE_ITEM_TYPE,
  HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE,
  HEALTH_PLAN_ALLOWANCE_TYPE,
  PRODUCT_CATEGORY_TYPE,
} from '~/utils/enums';

export const allowanceUsageValidationSchema = z.object({
  id: z.string().uuid(),
  quantity: z.number(),
  allowance_id: z.string().uuid(),
  subscription_id: z.string().uuid(),
  store_id: z.string().uuid().nullish(),
  billing_product_id: z.string().uuid().nullish(),
  billing_service_id: z.string().uuid().nullish(),
  usage_date: z.string().nullish(),
  allowance: z
    .object({
      config: z.object({
        value: z.union([
          z.object({
            type: z.literal(HEALTH_PLAN_ALLOWANCE_TYPE.PERCENTAGE),
            value: z.number().min(0).max(100),
          }),
          z.object({
            type: z.literal(HEALTH_PLAN_ALLOWANCE_TYPE.FIXED_AMOUNT),
            value: z.number().nonnegative(),
          }),
        ]),
        limitPerPeriod: z.number().int().positive(),
        period: z.nativeEnum(HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE),
      }),
      item: z.string().nullable(),
      service: z.object({ name: z.string() }).nullable(),
      product: z.object({ name: z.string() }).nullable(),
      item_type: z.nativeEnum(HEALTH_PLAN_ALLOWANCE_ITEM_TYPE),
    })
    .nullish(),
});

export type AllowanceUsageDataType = z.infer<
  typeof allowanceUsageValidationSchema
>;

export const billingServiceValidationSchema = z.object({
  id: z.string(),
  service_id: z.string().nullish(),
  quantity: z.number().positive(),
  unit_price: z.number().min(0),
  discount: z.number().min(0),
  vat_percentage: z.number().int().min(0).max(100),
  price: z.number().min(0),
  name: z.string(),
  discount_type: z.nativeEnum(DISCOUNT_TYPE),
  allowance_usage: z.array(allowanceUsageValidationSchema).nullish(),
  service_detail: z
    .object({
      category: z.nativeEnum(APPOINTMENT_TYPE).nullish(),
    })
    .nullish(),
});

export type BillingServiceDataType = z.infer<
  typeof billingServiceValidationSchema
>;

export const billingProductValidationSchema = z.object({
  id: z.string(),
  product_id: z.string().nullish(),
  quantity: z.number().positive(),
  unit_price: z.number().min(0),
  discount: z.number().min(0),
  vat_percentage: z.number().int().min(0).max(100),
  price: z.number().min(0),
  name: z.string(),
  has_subunit: z.boolean().optional(),
  subunit_multiplier: z.number().nullish(),
  measure_unit: z.string().nullish(),
  unit: z.string().nullish(),
  subunit: z.string().nullish(),
  product_detail: z
    .object({
      category: z.nativeEnum(PRODUCT_CATEGORY_TYPE).nullish(),
    })
    .nullish(),
  batches: z
    .array(
      z.object({
        id: z.string().optional(),
        batch_id: z.string().optional(),
        batch_number: z.string(),
        expiry_date: z.string().nullish(),
        created_at: z.string().optional(),
        product_id: z.string(),
        quantity: z.number(),
      }),
    )
    .nullish(),
  discount_type: z.nativeEnum(DISCOUNT_TYPE),
  prescription_id: z.string().uuid().nullish(),
  prescription_dispense_ids: z.array(z.string().uuid()).nullish(),
  allowance_usage: z.array(allowanceUsageValidationSchema).nullish(),
  dispensing_fee: z.number().min(0).optional(),
});

export type BillingProductDataType = z.infer<
  typeof billingProductValidationSchema
>;
