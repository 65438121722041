import React, { useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { IconEdit } from '@tabler/icons-react';
import toast from 'react-hot-toast';

import { invalidatePetQueries } from '~/api/mutations';
import { trpc } from '~/lib/trpc';

import RichTextEditor from '../editors/RichTextEditor';

type PetInternalNotesProps = {
  petId: string;
  clientId: string;
  petNotes: Nullish<string>;
};

export default function PetInternalNotes({
  petId,
  clientId,
  petNotes,
}: PetInternalNotesProps) {
  const [editing, setEditing] = useState(false);
  const [notes, setNotes] = useState(petNotes);

  const updateNotesMutation = trpc.pets.updatePrivatePetNotes.useMutation({
    onSuccess: () => {
      invalidatePetQueries(petId);

      setEditing(false);
      toast.success('Internal pet notes updated');
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  return (
    <Card elevation={5} sx={{ height: '100%' }}>
      <CardContent
        sx={{
          pt: 0,
          '&:last-child': { pb: 2 },
        }}
      >
        <Stack pt={1.5} pb={1}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography variant='h6' color='#1C2536'>
              Internal Pet Notes
            </Typography>

            <IconButton
              size='small'
              onClick={() => {
                setEditing(!editing);
              }}
            >
              <IconEdit size={20} />
            </IconButton>
          </Stack>

          <Divider sx={{ borderColor: blueGrey[50] }} />
        </Stack>

        <RichTextEditor
          key={`${petId}-${editing}`}
          value={notes}
          placeholder='No notes'
          readOnly={!editing}
          onChange={(value) => {
            setNotes(value);
          }}
        />

        {editing && (
          <Stack direction='row' justifyContent='center' pt={1}>
            <LoadingButton
              variant='contained'
              size='small'
              loading={updateNotesMutation.isLoading}
              onClick={() => {
                if (notes == null) {
                  return;
                }

                updateNotesMutation.mutate({
                  petId,
                  clientId,
                  notes,
                });
              }}
            >
              Save
            </LoadingButton>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
