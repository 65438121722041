import React, { useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SxProps, styled } from '@mui/material/styles';
import { IconChevronDown } from '@tabler/icons-react';
import { match } from 'ts-pattern';

import { STORE_INVOICE_STATUS } from '@/types/enums';

import { APPOINTMENT_PAYMENT_STATUS, ORDER_ITEM_STATUS } from '~/utils/enums';

import { getPrimary } from '../theme/utils';
import {
  getAppointmentColor,
  getAppointmentPaymentStatusColor,
  getEmployeePermissionColor,
  getEmployeeStatusColor,
  getOrderItemStatusColor,
  getOrderStatusColor,
  getPaymentStatusColor,
  getStoreInvoicePaymentStatusColor,
  getStoreInvoiceStatusColor,
} from '../utils/get-colors';
import {
  getAppointmentPaymentStatusLabel,
  getAppointmentStatusLabel,
  getStoreInvoicePaymentStatusLabel,
} from '../utils/get-labels';
import { getAppointmentStatuses } from '../utils/get-statuses';
import LoadingIconButton from './ui/LoadingIconButton';

type StatusType =
  | 'appointment'
  | 'employee_status'
  | 'employee_permission'
  | 'appointment_payment_status'
  | 'invoice_payment_status'
  | 'invoice_status'
  | 'order_item'
  | 'order_status'
  | 'payment_status';

const getColor = (type: StatusType, status: any) => {
  return match(type)
    .with('appointment', () => getAppointmentColor(status))
    .with('employee_status', () => getEmployeeStatusColor(status))
    .with('employee_permission', () => getEmployeePermissionColor(status))
    .with('appointment_payment_status', () =>
      getAppointmentPaymentStatusColor(status),
    )
    .with('invoice_payment_status', () =>
      getStoreInvoicePaymentStatusColor(status),
    )
    .with('invoice_status', () => getStoreInvoiceStatusColor(status))
    .with('order_status', () => getOrderStatusColor(status))
    .with('order_item', () => getOrderItemStatusColor(status))
    .with('payment_status', () => getPaymentStatusColor(status))
    .exhaustive();
};

const getLabel = (type: StatusType, status: any) => {
  switch (type) {
    case 'appointment':
      return getAppointmentStatusLabel(status);
    case 'appointment_payment_status':
      return getAppointmentPaymentStatusLabel(status);
    case 'invoice_payment_status':
      return getStoreInvoicePaymentStatusLabel(status);
    default:
      return status;
  }
};

const getStatuses = (type: StatusType) => {
  switch (type) {
    case 'appointment':
      return getAppointmentStatuses();
    case 'appointment_payment_status':
      return Object.values(APPOINTMENT_PAYMENT_STATUS);
    case 'employee_status':
      return ['Active', 'Inactive'];
    case 'employee_permission':
      return ['Admin', 'Employee'];
    case 'order_item':
      return Object.values(ORDER_ITEM_STATUS);
    case 'invoice_status':
      return Object.values(STORE_INVOICE_STATUS);
    default:
      return [];
  }
};

type StatusPillRootProps = {
  theme?: any;
  color: string;
  size?: 'small' | 'big';
};

const StatusPillRoot = styled('span')<StatusPillRootProps>(({
  theme,
  color,
  size = 'small',
}: StatusPillRootProps) => {
  const paletteColor = theme.palette[color] ?? getPrimary(color);
  const backgroundColor = paletteColor.alpha12;
  const textColor =
    theme.palette.mode === 'dark' ? paletteColor.main : paletteColor.dark;
  const isSmall = size === 'small';

  return {
    alignItems: 'center',
    backgroundColor,
    borderRadius: isSmall ? 12 : 24,
    color: textColor,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: isSmall ? 2 : 3.5,
    fontWeight: 600,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    paddingLeft: isSmall ? theme.spacing(1) : theme.spacing(2),
    paddingRight: isSmall ? theme.spacing(1) : theme.spacing(2),
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  };
});

interface StatusPillProps extends React.HTMLAttributes<HTMLSpanElement> {
  status: string;
  type: StatusType;
  size?: 'small' | 'big';
}

export const StatusPill = ({ status, type, ...other }: StatusPillProps) => {
  const color = getColor(type, status);

  return (
    <StatusPillRoot color={color} {...other}>
      {getLabel(type, status)}
    </StatusPillRoot>
  );
};

interface StatusPillDropdownProps<T> {
  status: T;
  type: StatusType;
  setStatus: (newStatus: T, oldStatus: T) => void;
  statusPillSize?: 'small' | 'big';
  loading?: boolean;
  sx?: SxProps;
}

export function StatusPillDropdown<T extends string>({
  status,
  type,
  setStatus,
  loading = false,
  statusPillSize = 'small',
  ...other
}: StatusPillDropdownProps<T>) {
  const color = getColor(type, status);
  const statuses = getStatuses(type) as T[];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = (newStatus: T) => {
    setStatus(newStatus, status);
    handleClose();
  };

  return (
    <div>
      <StatusPillRoot color={color} size={statusPillSize} {...other}>
        {getLabel(type, status)}
        <LoadingIconButton size='small' loading={loading} onClick={handleClick}>
          <IconChevronDown />
        </LoadingIconButton>
      </StatusPillRoot>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {statuses
          .filter((s) => s !== status)
          .map((option) => (
            <MenuItem
              key={option}
              selected={option === status}
              onClick={() => handleStatusChange(option)}
            >
              <StatusPill status={option} type={type} size={statusPillSize} />
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
