import React, { useCallback, useMemo } from 'react';

import { InputAdornment, SxProps, TextField } from '@mui/material';
import { FormikProps, FormikValues } from 'formik';
import { Paths } from 'type-fest';

import useFormUtils from './useFormUtils';

interface FormTextFieldProps<Values> {
  name: Paths<Values>;
  size?: 'small' | 'medium';
  label: string;
  formik: FormikProps<Values>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  multiline?: boolean;
  minRows?: number;
  disabled?: boolean;
  sx?: SxProps;
  testId?: string;
  placeholder?: string;
  afterInputChange?: () => void;
}

export default function FormTextField<Values extends FormikValues>({
  name,
  size,
  label,
  formik,
  startIcon,
  endIcon,
  multiline,
  minRows,
  disabled = false,
  sx,
  testId,
  placeholder,
  afterInputChange,
}: FormTextFieldProps<Values>) {
  const fieldNameStr = name.toString();
  const { value, touched, error, helperText } = useFormUtils(
    formik,
    fieldNameStr,
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      formik.setFieldValue(fieldNameStr, value);
      if (afterInputChange != null) {
        afterInputChange();
      }
    },
    [],
  );

  return useMemo(
    () => (
      <TextField
        fullWidth
        disabled={disabled}
        size={size}
        label={label}
        variant='outlined'
        value={value ?? ''}
        name={fieldNameStr}
        onChange={handleInputChange}
        onBlur={formik.handleBlur}
        helperText={helperText}
        error={error}
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment position='start'>{startIcon}</InputAdornment>
          ) : undefined,
          endAdornment: endIcon ? (
            <InputAdornment position='end'>{endIcon}</InputAdornment>
          ) : undefined,
          ...(testId && { 'data-testid': testId }),
        }}
        multiline={multiline}
        minRows={minRows}
        placeholder={placeholder}
        sx={sx}
      />
    ),
    [disabled, value, fieldNameStr, error, touched, helperText],
  );
}
