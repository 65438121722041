import React, { ReactNode } from 'react';

import { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Sentry from '@sentry/browser';
import { useAtom } from 'jotai';
import { useAuth } from 'lupa-shared-ui/src/hooks/use-auth';
import { toast } from 'react-hot-toast';

import { trpc } from '~/lib/trpc';
import { IS_DEV } from '~/utils/networkUtils';

import { lastLoadedStoreIdAtom } from '../atoms/atoms';
import JerryChat from '../components/JerryChat';
import { SplashScreen } from '../components/SplashScreen';
import useInitializeSocket from '../hooks/use-initialize-socket';
import { VerticalLayout } from '../layouts/VerticalLayout';
import {
  globalSingleton,
  initializeGlobalSingleton,
} from '../singletons/globalSingleton';

// Define the type for props
interface RootPageProps {
  children: ReactNode;
}

export default function RootPage({ children }: RootPageProps) {
  const { user, isAuthenticated, signOut } = useAuth();
  const [lastLoadedStoreId, setLastLoadedStoreId] = useAtom(
    lastLoadedStoreIdAtom,
  );
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { isLoading } = trpc.store.getRoot.useQuery(undefined, {
    enabled: isAuthenticated && globalSingleton.accessToken != null,
    onSuccess: (data) => {
      if (data == null) {
        console.error('No data returned from store.getRoot');
        return;
      }

      const stores = data.stores;

      if (stores.length === 0) {
        toast.error('You are not linked to any stores');
        signOut();
        return;
      }

      let selectedStoreId =
        lastLoadedStoreId == null
          ? stores[stores.length - 1]?.store?.id
          : stores.find((dataStore) => dataStore.store.id === lastLoadedStoreId)
              ?.store?.id;

      if (selectedStoreId == null) {
        selectedStoreId = stores[stores.length - 1].store.id;
        setLastLoadedStoreId(selectedStoreId);
      }

      const selectedStore = stores.find(
        ({ store }) => store.id === selectedStoreId,
      );

      if (selectedStore == null) {
        console.error('No store found with id', selectedStoreId);
        return;
      }

      initializeGlobalSingleton(data, selectedStore.store, IS_DEV);
    },
    onError: (error) => {
      toast.error('Failed to load store - please log in again.');
      signOut();
      console.error(error);
    },
  });

  Sentry.setUser({
    id: user?.id,
    email: user?.email,
    username: globalSingleton.currentEmployee?.full_name,
    store_id: globalSingleton.currentStore?.id,
    employee_id: globalSingleton.currentEmployee?.id,
  });

  useInitializeSocket(isAuthenticated);

  if (
    isLoading ||
    !globalSingleton.currentStore ||
    (isAuthenticated && user?.id == null)
  ) {
    return <SplashScreen />;
  }

  return (
    <>
      <VerticalLayout>{children}</VerticalLayout>
      {isAuthenticated && mdUp && <JerryChat />}
    </>
  );
}
