import React from 'react';

import { trpc } from '~/lib/trpc';

import HistoryChangesVisualizer from '../HistoryChangesVisualizer';
import DialogSection from '../ui/DialogSection';
import Loading from '../ui/Loading';

interface InventoryProductHistoryDialogProps {
  open: boolean;
  onClose: () => void;
  productId: string;
}

export default function InventoryProductHistoryDialog({
  open,
  onClose,
  productId,
}: InventoryProductHistoryDialogProps) {
  const { data, isLoading } = trpc.products.getProductHistory.useQuery({
    productId,
  });

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <DialogSection
      title='Product History'
      open={open}
      onClose={onClose}
      maxWidth='xl'
    >
      <HistoryChangesVisualizer changes={data} />
    </DialogSection>
  );
}
