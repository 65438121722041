import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@mui/material/styles';

import { createOptions as createBaseOptions } from './base/create-options';
import { createOptions as createLightOptions } from './light/create-options';

export const createTheme = (config: any) => {
  let theme = createMuiTheme(
    // Base options available for both dark and light palette modes
    createBaseOptions(),
    // Options based on selected palette mode, color preset and contrast
    (config.paletteMode = createLightOptions({
      colorPreset: config.colorPreset,
      contrast: config.contrast,
    })),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
