import { globalSingleton } from '~/singletons/globalSingleton';

export function getCurrencySymbol(): string {
  const parts = globalSingleton.currencyFormatter.formatToParts(0);
  const currencySymbolPart = parts.find((part) => part.type === 'currency');
  return currencySymbolPart ? currencySymbolPart.value : '£';
}

export function getCurrencySymbolFromCode(currencyCode: string) {
  return (0)
    .toLocaleString('en', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

export function formatCurrency(number: Nullish<number>): string {
  return globalSingleton.currencyFormatter.format(number || 0);
}

export function formatCurrencyFromCode(
  number: Nullish<number>,
  currencyCode: string,
): string {
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
  }).format(number || 0);
}

export function formatCurrencyShort(number: number) {
  if (number >= 1e3) {
    let shortNumber = (number / 1e3).toFixed(1);
    // Remove .0 if it exists to avoid things like 152.0k
    shortNumber = shortNumber.endsWith('.0')
      ? shortNumber.slice(0, -2)
      : shortNumber;
    return `${getCurrencySymbol()}${shortNumber}k`;
  }
  return globalSingleton.currencyFormatter.format(number);
}

export const getSupportedCurrencies = (): {
  code: string;
  name: string;
  symbol: string;
}[] => {
  const currencyCodes = Intl.supportedValuesOf('currency');
  return currencyCodes.map((code) => ({
    code,
    name: new Intl.DisplayNames(['en'], { type: 'currency' }).of(code) || code,
    symbol: getCurrencySymbolFromCode(code),
  }));
};
