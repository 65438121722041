import React from 'react';

import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { toFormikValidate, toFormikValidationSchema } from 'zod-formik-adapter';

import { trpc } from '~/lib/trpc';
import { paths } from '~/paths';
import { PRODUCT_CATEGORY_TYPE } from '~/utils/enums';
import productValidators from '~/validators/productValidators.js';

import InventoryUpsertProductForm from './InventoryUpsertProductForm.js';

const validationSchema = productValidators.product;

export type ProductCreateForm = z.infer<typeof validationSchema>;
export type ProductUpdateFormInitialValues = ReturnType<
  typeof useGetInitialValues
>;

const useGetInitialValues = () => {
  return {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- This is necessary to avoid type errors
    category: null as unknown as PRODUCT_CATEGORY_TYPE,
    medical_category: null,
    name: '',
    item_code: null,
    description: null,

    supplier: null,
    supplier_product: null,

    unit: null,
    has_subunit: false,
    subunit_multiplier: 1,
    subunit: null,

    has_batches: false,
    quantity: 0,
    measure_unit: null,
    batches: [],

    price: 0,
    vat_code: 'standard',
    procurement_cost: 0,
    vat_percentage: 20,
    margin: 0,

    has_advanced_discounts: false,
    discount_1: 0,
    discount_2: 0,
    discount_3: 0,

    has_fees: false,
    dispensing_fee: 0,

    minimum_stock_level: 0,
    optimal_stock_level: 100,
  };
};

interface InventoryProductCreateProps {
  onComplete?: () => void;
}

export default function InventoryProductCreate({
  onComplete,
}: InventoryProductCreateProps) {
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();

  const createProductMutation = trpc.products.createProduct.useMutation({
    onSuccess: () => {
      trpcUtils.products.getProducts.invalidate();

      toast.success('Product created');
      formik.resetForm();

      if (onComplete) {
        onComplete();
        return;
      }

      navigate(paths.inventory.index);
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const initialValues = useGetInitialValues();

  const formik = useFormik<ProductCreateForm>({
    initialValues,
    validationSchema: toFormikValidationSchema(validationSchema),
    validate: toFormikValidate(validationSchema),
    onSubmit: async () => {
      await createProductMutation.mutateAsync({
        data: formik.values,
      });
    },
  });

  return (
    <Stack direction='column'>
      <InventoryUpsertProductForm action='create' formik={formik} />
    </Stack>
  );
}
