import React, { useMemo, useState } from 'react';

import { PetWeightRecords } from '#types/database-custom.types';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import {
  IconEdit,
  IconPlus,
  IconTrendingDown,
  IconTrendingUp,
} from '@tabler/icons-react';
import { format } from 'date-fns';
import { LupaNumberInput } from 'lupa-shared-ui';
import toast from 'react-hot-toast';
import * as R from 'remeda';

import { trpc } from '~/lib/trpc';
import mathUtils from '~/utils/math-utils';

import { updatePetProperties } from '../../api/mutations';
import { useDialog } from '../../hooks/use-dialog';
import { usePopover } from '../../hooks/use-popover';
import DialogSection from '../ui/DialogSection';
import PetWeightUpsert from './PetWeightUpsert';

function PetWeightTrackingContent({ weights }: { weights: PetWeightRecords }) {
  const latestWeight = weights[0];
  const latestsWeightsDiff =
    weights.length > 1
      ? mathUtils.getGrowthPercentage(weights[1].weight, weights[0].weight)
      : null;

  return (
    <Stack direction='row' alignItems='center' gap={1}>
      <Stack direction='column' flexGrow={1}>
        <Typography fontSize={32} color='primary'>
          {latestWeight.weight} kg
        </Typography>

        {weights.length > 1 && (
          <Typography variant='caption' color='text.secondary'>
            {`As on ${format(
              new Date(latestWeight.weight_date),
              'dd MMM yyyy',
            )}`}
          </Typography>
        )}

        {latestsWeightsDiff != null && (
          <Stack direction='row' alignItems='center' gap={0.5}>
            <Typography
              variant='subtitle1'
              color={latestsWeightsDiff > 0 ? 'success.main' : 'error.main'}
            >
              {latestsWeightsDiff >= 0 ? '+' : ''}
              {latestsWeightsDiff}%
            </Typography>

            <Typography
              color={latestsWeightsDiff >= 0 ? 'success.main' : 'error.main'}
            >
              {latestsWeightsDiff >= 0 ? (
                <IconTrendingUp size={16} style={{ marginTop: 4 }} />
              ) : (
                <IconTrendingDown size={16} style={{ marginTop: 4 }} />
              )}
            </Typography>

            <Typography variant='caption' color='text.secondary'>
              {`from ${format(
                new Date(weights[1].weight_date),
                'dd MMM yyyy',
              )} | ${weights[1].weight} kg`}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

type PetWeightTrackingProps = {
  petId: string;
  weights: PetWeightRecords | null;
};

export default function PetWeightTracking({
  petId,
  weights,
}: PetWeightTrackingProps) {
  const weightTrackingDialog = useDialog();
  const popover = usePopover();
  const [popoverWeight, setPopoverWeight] = useState<number>(0);

  const sortedWeights = useMemo(() => {
    return R.sort(
      weights ?? [],
      (a, b) =>
        new Date(b.weight_date).getTime() - new Date(a.weight_date).getTime(),
    );
  }, [weights]);

  const addWeightMutation = trpc.pets.updatePet.useMutation({
    onSuccess: ({ updatedPetId, data }) => {
      updatePetProperties(updatedPetId, { weights: data.weights });
      toast.success('Weight added');
      popover.handleClose();
    },

    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  return (
    <>
      <Card elevation={5} sx={{ py: 1, px: 2 }}>
        <Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            mt={1}
          >
            <Typography variant='h6' color='#1C2536'>
              Weight
            </Typography>

            <Stack direction='row' gap={1} alignItems='center'>
              <IconButton
                size='small'
                ref={popover.anchorRef}
                onClick={popover.handleOpen}
              >
                <IconPlus size={20} />
              </IconButton>

              <IconButton
                onClick={weightTrackingDialog.handleOpen}
                size='small'
              >
                <IconEdit size={20} />
              </IconButton>
            </Stack>
          </Stack>

          <Divider sx={{ borderColor: blueGrey[50] }} />
        </Stack>

        {sortedWeights && sortedWeights.length > 0 ? (
          <PetWeightTrackingContent weights={sortedWeights} />
        ) : (
          <Typography variant='body2' color='text.secondary' my={2}>
            No records available
          </Typography>
        )}
      </Card>

      {weightTrackingDialog.open && (
        <DialogSection
          title='Update Pet Weight'
          open={weightTrackingDialog.open}
          onClose={weightTrackingDialog.handleClose}
          maxWidth='xl'
        >
          <PetWeightUpsert
            petId={petId}
            weights={weights}
            onClose={weightTrackingDialog.handleClose}
          />
        </DialogSection>
      )}

      <Popover
        open={popover.open}
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Stack direction='column' spacing={1} p={2}>
          <LupaNumberInput
            name='weight'
            label='Weight (kg)'
            value={popoverWeight}
            isInt={false}
            fullWidth
            onChange={(value) => setPopoverWeight(value)}
          />

          <LoadingButton
            variant='contained'
            onClick={() => {
              const allWeights = [
                ...(weights ?? []),
                {
                  weight: popoverWeight,
                  weight_date: new Date().toISOString(),
                },
              ];

              const sortedWeights = allWeights.sort(
                (a, b) =>
                  new Date(b.weight_date).getTime() -
                  new Date(a.weight_date).getTime(),
              );

              addWeightMutation.mutate({
                petId,
                data: {
                  weights: sortedWeights,
                },
              });
            }}
            loading={addWeightMutation.isLoading}
            disabled={!popoverWeight}
          >
            Add Weight
          </LoadingButton>
        </Stack>
      </Popover>
    </>
  );
}
