import React, { useEffect, useState } from 'react';

import {
  MenuItem,
  OutlinedTextFieldProps,
  Select,
  SelectProps,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { blueGrey, purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { MergeDeep } from 'type-fest';

type CustomSelectProps = MergeDeep<
  SelectProps,
  {
    size: 'small' | 'big';
  }
>;

const CustomSelect = styled(({ ...otherProps }: CustomSelectProps) => (
  <Select {...(otherProps as SelectProps)} />
))(({ size }) => ({
  '& .MuiInputBase-root': {},
  '& .MuiSelect-select': {
    paddingLeft: 8,
    paddingRight: '20px !important',
    paddingTop: size === 'small' ? 6 : 14,
    paddingBottom: size === 'small' ? 6 : 14,
    minWidth: 20,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderLeftWidth: 0,
  },
  '& .MuiSvgIcon-root': {
    marginRight: -8,
  },
}));

type CustomTextFieldProps = MergeDeep<
  TextFieldProps,
  {
    size: 'small' | 'big';
    showArrows?: boolean;
    testIdPrefix?: string;
  }
>;

const CustomTextField = styled(
  (
    { showArrows, testIdPrefix = '', ...otherProps }: CustomTextFieldProps, // eslint-disable-line
  ) => (
    <TextField
      {...(otherProps as TextFieldProps)}
      data-testid={`${testIdPrefix}-text-field`}
    />
  ),
)(({ size, showArrows }) => ({
  '& .MuiInputBase-root': {
    paddingRight: 8,
    paddingLeft: '4px',
    borderRadius: showArrows ? 0 : 8,
    backgroundColor: 'white',
  },
  '& .MuiInputBase-input': {
    padding: size === 'small' ? '8px 4px' : '16px 8px',
  },
  '& .MuiInputAdornment-root .MuiTypography-root': {
    fontSize: '12px',
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
    {
      WebkitAppearance: 'none',
      margin: 0,
    },
  '& input[type=number]': {
    MozAppearance: 'textfield',
    textAlign: 'center',
  },
}));

interface StyledButtonProps {
  size: 'small' | 'big';
  position: 'left' | 'right';
  showSelect?: boolean;
}

const StyledButton = styled('button')<StyledButtonProps>(
  ({ size, position, showSelect }) => ({
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: '0.875rem',
    boxSizing: 'border-box',
    lineHeight: 1.5,
    border: '1px solid',
    borderColor: '#e5e7eb',
    borderTopLeftRadius: position === 'left' ? 8 : 0,
    borderBottomLeftRadius: position === 'left' ? 8 : 0,
    borderTopRightRadius: position === 'right' && !showSelect ? 8 : 0,
    borderBottomRightRadius: position === 'right' && !showSelect ? 8 : 0,
    borderLeftWidth: position === 'right' ? 0 : '1px',
    borderRightWidth: position === 'left' ? 0 : '1px',
    background: 'white',
    color: blueGrey[900],
    width: '24px',
    height: size === 'small' ? '36px' : 52,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '120ms',

    '&:hover': {
      cursor: 'pointer',
      background: purple[500],
      borderColor: purple[400],
      color: purple[50],
    },

    '&:focus-visible': {
      outline: 0,
    },

    '&.increment': {
      order: 1,
    },
  }),
);

export interface NumberInputProps
  extends Omit<OutlinedTextFieldProps, 'size' | 'onChange' | 'variant'> {
  value: Nullish<number>;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  isInt?: boolean;
  showArrows?: boolean;
  label?: string;
  disabled?: boolean;
  size?: 'small' | 'big';
  variant?: 'outlined' | 'filled' | 'standard';
  stackStyle?: React.CSSProperties;
  showSelect?: boolean;
  selectValue?: Nullish<string>;
  onSelectChange?: (event: any) => void;
  selectOptions?: Nullish<string>[];
  testIdPrefix?: string;
  onMinusClick?: (value: number) => void;
  onPlusClick?: (value: number) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export default function NumberInput({
  value = 0,
  onChange,
  min = 0,
  max = 10000,
  step = 1,
  isInt = true,
  showArrows = false,
  label,
  disabled,
  size = 'small',
  variant = 'outlined',
  stackStyle,
  showSelect = false,
  selectValue,
  onSelectChange,
  selectOptions = [],
  testIdPrefix = '',
  onMinusClick,
  onPlusClick,
  onBlur,
  ...props
}: NumberInputProps) {
  const [stringInputValue, setStringInputValue] = useState(value?.toString());

  useEffect(() => {
    setStringInputValue(value?.toString());
  }, [value]);

  return (
    <Stack
      direction='column'
      gap={0}
      style={stackStyle}
      data-testid={`${testIdPrefix}-amount-input-div`}
    >
      {showSelect && (
        <Typography variant='caption' color='text.secondary'>
          {label}
        </Typography>
      )}

      <Stack direction='row' alignItems='flex-start' justifyContent='center'>
        {showArrows && (
          <StyledButton
            size={size}
            position='left'
            showSelect={showSelect}
            onClick={() => {
              if (value! > min) {
                onChange(Number(value! - step));
                onMinusClick?.(value! - step);
              }
            }}
            disabled={disabled || value! <= min}
            data-testid={`${testIdPrefix}-left-button`}
          >
            <IconMinus fontSize='18px' />
          </StyledButton>
        )}

        <CustomTextField
          value={stringInputValue ?? ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setStringInputValue(e.target.value);
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            let currentInputValue: number = Number(stringInputValue);

            if (Number.isNaN(currentInputValue) || currentInputValue === null) {
              setStringInputValue(value?.toString() ?? '');
              return;
            }

            currentInputValue = Math.min(Math.max(currentInputValue, min), max);
            const finalValue = isInt
              ? Math.round(currentInputValue)
              : currentInputValue;

            onChange(finalValue ?? 0);
            setStringInputValue(finalValue.toString());
            onBlur?.(e);
          }}
          variant={variant}
          type='number'
          disabled={disabled}
          label={showSelect ? '' : label}
          placeholder={label}
          onWheel={(e: any) => e.target.blur()}
          size={size}
          showArrows={showArrows}
          style={{ width: '100%' }}
          autoComplete='off'
          testIdPrefix={`${testIdPrefix}-custom-input`}
          {...props}
        />

        {showArrows && (
          <StyledButton
            size={size}
            position='right'
            showSelect={showSelect}
            onClick={() => {
              if (value! < max) {
                onChange(Number(value! + step));
                onPlusClick?.(value! + step);
              }
            }}
            disabled={disabled || value! >= max}
            data-testid={`${testIdPrefix}-right-button`}
          >
            <IconPlus fontSize='18px' />
          </StyledButton>
        )}

        {showSelect && (
          <CustomSelect
            size={size}
            value={selectValue}
            disabled={disabled}
            onChange={onSelectChange}
            data-testid={`${testIdPrefix}-custom-select`}
          >
            {selectOptions.map((option, idx) => (
              <MenuItem
                key={idx}
                value={option!}
                data-testid={`${testIdPrefix}-custom-select-menu-item-{idx}`}
              >
                {option}
              </MenuItem>
            ))}
          </CustomSelect>
        )}
      </Stack>
    </Stack>
  );
}
