import { Session } from '@supabase/supabase-js';
import { QueryClient } from '@tanstack/react-query';
import { getFrontendFeatureFlagValue } from 'lupa-shared-ui/src/hooks/use-feature-flags';
import { Socket } from 'socket.io-client';

import { TrpcRouterOutputs } from '~/lib/trpc';
import { EMPLOYEE_PERMISSION_LEVEL } from '~/utils/enums';

const ENABLE_QUERIES_WHEN_OFFLINE = getFrontendFeatureFlagValue(
  'ENABLE_QUERIES_WHEN_OFFLINE',
);

export type ConnectedEmailConfig = {
  email: string;
  aliases: string[] | null;
  shared_emails: string[] | null;
};

type Employee = {
  id: string;
  full_name: string;
  email: string;
  phone: Nullable<string>;
  role: string;
  permission: EMPLOYEE_PERMISSION_LEVEL;
  profile_id: string;
  avatar_url: Nullable<string>;
  employee_email_configs: ConnectedEmailConfig[];
  intercomHash: Nullable<string>;
};

interface GlobalSingleton {
  accessToken: Nullable<string>;
  userId: Nullable<string>;
  socket: Nullable<Socket>;
  currentStore: TrpcRouterOutputs['store']['getRoot']['stores'][number]['store'];
  currentEmployee: Employee;
  isTestUser: Nullable<boolean>;
  stores: {
    store: TrpcRouterOutputs['store']['getRoot']['stores'][number]['store'];
  }[];
  queryClient: QueryClient;
  currencyFormatter: Intl.NumberFormat;
}

const trpcNetworkModeOverride = ENABLE_QUERIES_WHEN_OFFLINE
  ? { networkMode: 'always' as const }
  : {};

export const globalSingleton: GlobalSingleton = {
  accessToken: null,
  userId: null,
  socket: null, // @ts-ignore - globalSingleton is populated at the beginning
  currentStore: null, // @ts-ignore - globalSingleton is populated at the beginning
  currentEmployee: null,
  isTestUser: null,
  stores: [],
  queryClient: new QueryClient({
    defaultOptions: {
      queries: {
        ...trpcNetworkModeOverride,
        staleTime: 3 * (60 * 1000), // 3 mins
      },
      mutations: {
        ...trpcNetworkModeOverride,
      },
    },
  }),
  currencyFormatter: new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
  }),
};

export const initializeGlobalSingleton = (
  data: NonNullable<TrpcRouterOutputs['store']['getRoot']>,
  selectedStore: NonNullable<
    TrpcRouterOutputs['store']['getRoot']['stores'][number]['store']
  >,
  isDevEnv: boolean,
) => {
  globalSingleton.currentEmployee = {
    id: data.id,
    profile_id: data.profile_id,
    full_name: data.full_name,
    role: data.role,
    phone: data.phone,
    email: data.email,
    avatar_url: data.avatar_url,
    permission: data.permission as EMPLOYEE_PERMISSION_LEVEL,
    employee_email_configs: data.employee_email_configs,
    intercomHash: data.userIntercomHash,
  };

  globalSingleton.currentStore = { ...selectedStore };

  globalSingleton.isTestUser =
    isDevEnv || data.id === 'b13f8b8a-04d2-43e0-9a9a-3d41c8de5465';

  globalSingleton.currencyFormatter = new Intl.NumberFormat(
    selectedStore.locale,
    {
      style: 'currency',
      currency: selectedStore.currency_code,
      minimumFractionDigits: 0,
    },
  );
};

export const updateGlobalSingletonWithSession = (session: Session | null) => {
  globalSingleton.accessToken = session?.access_token ?? null;
  globalSingleton.userId = session?.user.id ?? null;
};
