import React from 'react';

import { getQueryKey } from '@trpc/react-query';

import { trpc } from '~/lib/trpc';

import { invalidateAllQueriesStartingWith } from '../../api/mutations';
import InventoryProductCreate from '../inventory/InventoryProductCreate';

interface CreateProductDialogProps {
  onClose: () => void;
}

export default function CreateProductDialog({
  onClose,
}: CreateProductDialogProps) {
  const onComplete = () => {
    invalidateAllQueriesStartingWith(
      getQueryKey(trpc.products.searchProducts)[0],
    );
    invalidateAllQueriesStartingWith(
      getQueryKey(trpc.products.searchProductBatches)[0],
    );
    onClose();
  };

  return <InventoryProductCreate onComplete={onComplete} />;
}
