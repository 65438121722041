import { z } from 'zod';

export const zIsoDateString = z.string().date().brand('IsoDateString');
export type IsoDateString = z.infer<typeof zIsoDateString>;

export const zIsoTimeString = z.string().time().brand('IsoTimeString');
export type IsoTimeString = z.infer<typeof zIsoTimeString>;

export const zIsoDateTimeString = z
  .string()
  .datetime({ offset: true })
  .brand('IsoDateTimeString');
export type IsoDateTimeString = z.infer<typeof zIsoDateTimeString>;

export const zIsoDurationString = z
  .string()
  .duration()
  .brand('IsoDurationString');
export type IsoDurationString = z.infer<typeof zIsoDurationString>;
