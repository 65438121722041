import React, { CSSProperties, ReactNode } from 'react';

import { Stack } from '@mui/material';

type StyledSplitPillsProps = {
  children: ReactNode;
  style?: CSSProperties;
};

const StyledSplitPills: React.FC<StyledSplitPillsProps> = ({
  children,
  style,
}) => {
  return (
    <Stack
      direction='column'
      spacing={2}
      p={2}
      sx={{
        color: 'rgba(255, 255, 255, 0.8)',
        borderWidth: 1,
        borderColor: 'rgba(255, 255, 255, 0.2)',
        borderStyle: 'solid',
        flexGrow: 1,
        borderRadius: 2,
        ...style,
      }}
    >
      {children}
    </Stack>
  );
};

export default StyledSplitPills;
