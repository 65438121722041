import React, { ForwardedRef, forwardRef } from 'react';

import { Link, LinkProps } from 'react-router-dom';

interface RouterLinkProps extends Omit<LinkProps, 'to'> {
  href: string;
}

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  function RouterLink(
    { href, ...other }: RouterLinkProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) {
    return <Link ref={ref} to={href} {...other} />;
  },
);
