import React from 'react';

import {
  Checkbox,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  TypographyProps,
} from '@mui/material';
import { IconX } from '@tabler/icons-react';
import {
  ListChildComponentProps,
  FixedSizeList as VirtualizedList,
} from 'react-window';

export interface PickerListItem<TKey extends string> {
  id: TKey;
  primaryText: string;
  secondaryText?: string;
  isSelected: boolean;
}

interface PickerListProps<TKey extends string> {
  items: PickerListItem<TKey>[];
  onToggle: (itemId: TKey) => void;
  listType: 'TOGGLEABLE' | 'REMOVABLE';
  rowHeight: number;
  listHeight: number;
  actionAppearsOn: 'LEFT' | 'RIGHT';
  primaryTypographyProps?: TypographyProps;
  secondaryTypographyProps?: TypographyProps;
}

const ListItemComponent = <TKey extends string>({
  index,
  style,
  data,
}: ListChildComponentProps<
  Pick<
    PickerListProps<TKey>,
    'items' | 'onToggle' | 'listType' | 'actionAppearsOn'
  > & {
    primaryTypographyProps?: TypographyProps;
    secondaryTypographyProps?: TypographyProps;
  }
>) => {
  const {
    items,
    onToggle,
    listType,
    actionAppearsOn,

    primaryTypographyProps,
    secondaryTypographyProps,
  } = data;
  const item = items[index];

  const action =
    listType === 'REMOVABLE' ? (
      <IconButton aria-label='delete' onClick={() => onToggle(item.id)}>
        <IconX size={20} />
      </IconButton>
    ) : (
      <Checkbox
        checked={item.isSelected}
        tabIndex={-1}
        disableRipple
        inputProps={{ 'aria-labelledby': `item-${item.id}` }}
      />
    );

  return (
    <div style={{ ...style }}>
      <ListItem
        disableGutters
        // @ts-ignore
        button={listType === 'TOGGLEABLE'}
        onClick={
          listType === 'TOGGLEABLE' ? () => onToggle(item.id) : undefined
        }
        secondaryAction={actionAppearsOn === 'RIGHT' && action}
        sx={{ height: '100%' }}
      >
        {actionAppearsOn === 'LEFT' && action}
        <ListItemText
          id={`item-${item.id}`}
          primary={item.primaryText}
          primaryTypographyProps={{ noWrap: true, ...primaryTypographyProps }}
          secondary={item.secondaryText && item.secondaryText}
          secondaryTypographyProps={{
            noWrap: true,
            color: 'textSecondary',
            variant: 'body2',
            ...secondaryTypographyProps,
          }}
        />
      </ListItem>
      {index !== items.length - 1 && <Divider />}
    </div>
  );
};

function PickerList<TKey extends string>({
  items,
  onToggle,
  listType,
  rowHeight,
  listHeight,
  actionAppearsOn,
  primaryTypographyProps,
  secondaryTypographyProps,
}: PickerListProps<TKey>) {
  return (
    <VirtualizedList
      height={listHeight}
      itemCount={items.length}
      itemSize={rowHeight}
      width='100%'
      itemData={{
        items,
        onToggle,
        listType,
        actionAppearsOn,
        primaryTypographyProps,
        secondaryTypographyProps,
      }}
    >
      {ListItemComponent<TKey>}
    </VirtualizedList>
  );
}

export default PickerList;
