import React from 'react';

import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';

import { globalSingleton } from '~/singletons/globalSingleton';
import {
  PrescriptionUpsert,
  formatPrescriptionDosage,
} from '~/validators/prescriptionValidators';

import registerFonts from '../pdf/fontRegistration';
import { PDFIconPaw, PDFIconUser } from '../pdf/pdfIcons';

registerFonts();

// PDF document size is specified in points (1/72 inch).
// We use mm for sizing, and convert to points when rendering the PDF.
const POINTS_PER_INCH = 72;
const INCHES_PER_MM = 1 / 25.4;
const POINTS_PER_MM = INCHES_PER_MM * POINTS_PER_INCH;

interface DosageLabelsPDFProps {
  prescription: Pick<
    PrescriptionUpsert,
    | 'id'
    | 'product_name'
    | 'quantity'
    | 'unit'
    | 'dosage_specification'
    | 'status'
  >;
  petName: string;
  clientName: string;
  clientAddress?: string;
  prescriberName: string;
  size?: {
    width: number;
    height: number;
    margins: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    };
  };
}

export const dosageLabelSize = () =>
  globalSingleton.currentStore.printing_settings?.dosageLabels ?? {
    width: 105,
    height: 29,
    margins: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };

export default function DosageLabelsPDF({
  prescription,
  petName,
  prescriberName,
  clientName,
  clientAddress,
  size: sizeMM = dosageLabelSize(),
}: DosageLabelsPDFProps) {
  const currentStore = globalSingleton.currentStore;
  const pdfSize = {
    width: sizeMM.width * POINTS_PER_MM,
    height: sizeMM.height * POINTS_PER_MM,
    margins: {
      top: sizeMM.margins.top * POINTS_PER_MM,
      right: sizeMM.margins.right * POINTS_PER_MM,
      bottom: sizeMM.margins.bottom * POINTS_PER_MM,
      left: sizeMM.margins.left * POINTS_PER_MM,
    },
  };

  const haveMargins =
    pdfSize.margins.top > 0 ||
    pdfSize.margins.right > 0 ||
    pdfSize.margins.bottom > 0 ||
    pdfSize.margins.left > 0;

  const labelSize = {
    width: pdfSize.width - pdfSize.margins.left - pdfSize.margins.right,
    height: pdfSize.height - pdfSize.margins.top - pdfSize.margins.bottom,
  };

  const fontSizes = {
    normal: 7,
    small: 6,
    quiteSmall: 5.5,
    verySmall: 5,
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      paddingTop: pdfSize.margins.top,
      paddingRight: pdfSize.margins.right,
      paddingBottom: pdfSize.margins.bottom,
      paddingLeft: pdfSize.margins.left,
      fontFamily: 'Poppins',
    },
    labelContainer: {
      border: haveMargins ? '1px solid black' : undefined,
      borderRadius: haveMargins ? 8 : undefined,
      width: '100%',
      height: '100%',
    },
    hstack: {
      flexDirection: 'row',
      height: '100%',
    },
    sidebarContainer: {
      width: 16,
      borderRight: '0.25mm solid black',
      justifyContent: 'center',
      alignItems: 'center',
    },
    warningText: {
      transform: 'rotate(-90deg)',
      width: labelSize.height,
      textAlign: 'center',
      fontSize: fontSizes.verySmall,
      textTransform: 'uppercase',
      letterSpacing: -0.1,
      lineHeight: 1.25,
    },
    mainContent: {
      flex: 1,
      flexDirection: 'column',
      paddingLeft: 2,
      paddingRight: haveMargins ? 2 : 0,
      paddingTop: haveMargins ? 2 : 0,
      paddingBottom: haveMargins ? 2 : 0,
    },
    header: {
      paddingBottom: 0,
      marginBottom: 0,
    },
    storeName: {
      fontSize: fontSizes.small,
      fontWeight: 'bold',
      lineHeight: 1.2,
    },
    address: {
      fontSize: fontSizes.verySmall,
    },
    subHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: fontSizes.small,
    },
    borderedBox: {
      border: '1px solid black',
      borderBottom: 'none',
      display: 'flex',
      flexDirection: 'column',
    },
    borderedRow: {
      flexDirection: 'row',
      borderBottom: '1px solid black',
      flexShrink: 0,
    },
    borderedRowPrimary: {
      flex: 1,
      padding: 1,
    },
    borderedRowSecondary: {
      padding: 1,
      borderLeft: '1px solid black',
      fontSize: fontSizes.small,
    },
    content: {
      fontSize: fontSizes.normal,
    },
    boldContent: {
      fontSize: fontSizes.normal,
      fontWeight: 'bold',
    },
  } as const);

  if (prescription.status === 'cancelled') {
    console.error(
      'Attempted to render a dosage label for a cancelled prescription. Id:',
      prescription.id,
    );
    return null;
  }

  return (
    <Document title='Label'>
      <Page
        size={[pdfSize.width, pdfSize.height]}
        style={styles.page}
        // Required to prevent a potential infinite loop due to a bug in @react-pdf/renderer
        // https://github.com/diegomura/react-pdf/issues/2659
        wrap={false}
        dpi={POINTS_PER_INCH}
      >
        <View style={styles.labelContainer}>
          <View style={styles.hstack}>
            <View style={styles.sidebarContainer}>
              <Text style={styles.warningText}>
                For animal treatment only
                {'\n'}
                Keep out of reach of children
              </Text>
            </View>

            <View style={styles.mainContent}>
              <View style={styles.header}>
                <Text style={styles.storeName}>{currentStore.name}</Text>
                <Text style={styles.address}>
                  {currentStore.address?.formatted_address}
                </Text>
                <View style={styles.subHeader}>
                  <Text>{currentStore.phone}</Text>
                  <Text>{prescriberName}</Text>
                  <Text>{format(new Date(), 'dd MMM yyyy')}</Text>
                </View>
              </View>

              <View style={styles.borderedBox}>
                <View style={styles.borderedRow}>
                  <View style={styles.borderedRowPrimary}>
                    <Text style={styles.boldContent}>
                      {prescription.product_name}
                    </Text>
                  </View>
                  <View style={styles.borderedRowSecondary}>
                    <Text style={styles.content}>
                      QTY: {prescription.quantity} {prescription.unit}
                    </Text>
                  </View>
                </View>
                <View style={styles.borderedRow}>
                  <View style={styles.borderedRowPrimary}>
                    <Text style={styles.content}>
                      {formatPrescriptionDosage({ prescription })}
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: fontSizes.small,
                  marginTop: 'auto',
                }}
              >
                <PDFIconPaw height='6pt' width='7pt' />
                <Text
                  style={[
                    {
                      marginRight: 4,
                      marginLeft: 1,
                      flexShrink: 0,
                      flexGrow: 0,
                    },
                  ]}
                >
                  {petName}
                </Text>
                <PDFIconUser height='7pt' width='7pt' />
                <Text
                  style={[
                    {
                      marginLeft: 1,
                      flex: 1,
                    },
                  ]}
                >
                  {clientName}
                  {clientAddress && `, ${clientAddress}`}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
