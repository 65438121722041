import React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IconBuilding, IconChevronDown } from '@tabler/icons-react';
import { useAtom } from 'jotai';

import { trpc } from '~/lib/trpc';

import { lastLoadedStoreIdAtom } from '../atoms/atoms';
import FallbackAvatar from '../components/ui/FallbackAvatar';
import Loading from '../components/ui/Loading';
import { usePopover } from '../hooks/use-popover';
import { globalSingleton } from '../singletons/globalSingleton';
import { getImagePublicUrl } from '../utils/get-image-public-url';

export const TenantSwitch = () => {
  const { currentStore } = globalSingleton;
  const [, setLastLoadedStoreId] = useAtom(lastLoadedStoreIdAtom);
  const popover = usePopover();

  const { data, isLoading } = trpc.store.getRoot.useQuery(undefined, {
    enabled: globalSingleton.accessToken != null,
  });

  const onChangeStore = (store: any) => {
    setLastLoadedStoreId(store.id);
    window.location.reload();
  };

  if (isLoading || currentStore == null || data == null) {
    return (
      <Loading
        style={{ height: 64, flexGrow: 0, paddingTop: 16, paddingBottom: 16 }}
      />
    );
  }

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      direction='row'
      spacing={1}
      px={{
        xs: 3,
        sm: 2,
      }}
      pt={{
        xs: 3,
        sm: 2,
      }}
      pb={{
        xs: 3,
        sm: 1.5,
      }}
    >
      <FallbackAvatar
        src={getImagePublicUrl(currentStore?.logo_url)}
        fallbackIcon={<IconBuilding />}
        size={36}
      />

      <Box sx={{ flexGrow: 1 }}>
        <Typography color='inherit' variant='h6' style={{ fontSize: '0.9rem' }}>
          {`${currentStore?.name} ${currentStore?.is_draft ? 'DRAFT' : ''}`}
        </Typography>
      </Box>

      {data.stores?.length > 1 && (
        <IconButton
          ref={popover.anchorRef}
          onClick={popover.handleOpen}
          style={{ margin: 0, padding: 0 }}
        >
          <IconChevronDown />
        </IconButton>
      )}

      <Menu
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={popover.handleClose}
        open={popover.open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {data.stores
          .filter(({ store }: any) => store.id !== currentStore.id)
          .map((option: any, idx: any) => (
            <MenuItem key={idx} onClick={() => onChangeStore(option.store)}>
              {option.store.name}
            </MenuItem>
          ))}
      </Menu>
    </Stack>
  );
};
