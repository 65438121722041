import { Font } from '@react-pdf/renderer';

import PoppinsBlack from '../../assets/fonts/Poppins-Black.ttf';
import PoppinsBlackItalic from '../../assets/fonts/Poppins-BlackItalic.ttf';
import PoppinsBold from '../../assets/fonts/Poppins-Bold.ttf';
import PoppinsBoldItalic from '../../assets/fonts/Poppins-BoldItalic.ttf';
import PoppinsExtraBold from '../../assets/fonts/Poppins-ExtraBold.ttf';
import PoppinsExtraBoldItalic from '../../assets/fonts/Poppins-ExtraBoldItalic.ttf';
import PoppinsExtraLight from '../../assets/fonts/Poppins-ExtraLight.ttf';
import PoppinsExtraLightItalic from '../../assets/fonts/Poppins-ExtraLightItalic.ttf';
import PoppinsItalic from '../../assets/fonts/Poppins-Italic.ttf';
import PoppinsLight from '../../assets/fonts/Poppins-Light.ttf';
import PoppinsLightItalic from '../../assets/fonts/Poppins-LightItalic.ttf';
import PoppinsMedium from '../../assets/fonts/Poppins-Medium.ttf';
import PoppinsMediumItalic from '../../assets/fonts/Poppins-MediumItalic.ttf';
import PoppinsRegular from '../../assets/fonts/Poppins-Regular.ttf';
import PoppinsSemiBold from '../../assets/fonts/Poppins-SemiBold.ttf';
import PoppinsSemiBoldItalic from '../../assets/fonts/Poppins-SemiBoldItalic.ttf';
import PoppinsThin from '../../assets/fonts/Poppins-Thin.ttf';
import PoppinsThinItalic from '../../assets/fonts/Poppins-ThinItalic.ttf';

const registerFonts = () => {
  const fonts = [
    { src: PoppinsRegular, fontWeight: 400 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsSemiBold, fontWeight: 600 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsExtraBold, fontWeight: 800 },
    { src: PoppinsBlack, fontWeight: 900 },
    { src: PoppinsThin, fontWeight: 100 },
    { src: PoppinsExtraLight, fontWeight: 200 },
    { src: PoppinsLight, fontWeight: 300 },
    { src: PoppinsItalic, fontWeight: 400, fontStyle: 'italic' },
    { src: PoppinsMediumItalic, fontWeight: 500, fontStyle: 'italic' },
    { src: PoppinsSemiBoldItalic, fontWeight: 600, fontStyle: 'italic' },
    { src: PoppinsBoldItalic, fontWeight: 700, fontStyle: 'italic' },
    { src: PoppinsExtraBoldItalic, fontWeight: 800, fontStyle: 'italic' },
    { src: PoppinsBlackItalic, fontWeight: 900, fontStyle: 'italic' },
    { src: PoppinsThinItalic, fontWeight: 100, fontStyle: 'italic' },
    { src: PoppinsExtraLightItalic, fontWeight: 200, fontStyle: 'italic' },
    { src: PoppinsLightItalic, fontWeight: 300, fontStyle: 'italic' },
  ];

  Font.register({
    family: 'Poppins',
    fonts,
  });
};

export default registerFonts;
