import React, { useEffect, useMemo, useState } from 'react';

import { IconButton, TableCell, TableRow } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import { useFormik } from 'formik';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { toFormikValidate, toFormikValidationSchema } from 'zod-formik-adapter';

import NumberInput from '~/components/ui/NumberInput';
import FormSelectField from '~/components/ui/form/FormSelectField';
import { HEALTH_PLAN_ALLOWANCE_TYPE } from '~/utils/enums';
import {
  ALLOWANCES_UNLIMITED_QUANTITY,
  RemainingAllowanceQuantityDataType,
  getAllowanceNameLabel,
  hasAllowances,
  hasRemainingAllowances,
} from '~/utils/healthplan-utils';
import { formatCurrency } from '~/utils/i18n';
import {
  AllowanceUsageDataType,
  BillingProductDataType,
  BillingServiceDataType,
} from '~/validators/appointmentValidators';

const validationSchema = z.object({
  allowanceId: z.string().uuid().nullable(),
  quantity: z.number().int().min(0),
});

interface EditHealthPlanAllowanceUsageRowProps {
  storeItemId: string;
  storeItemCategory: string;
  itemDetails: BillingProductDataType | BillingServiceDataType;
  availableAllowances: Record<string, RemainingAllowanceQuantityDataType[]>;
  onAllowanceApplied: ({
    allowanceUsed,
    availableAllowances,
  }: {
    allowanceUsed: AllowanceUsageDataType | null;
    availableAllowances: Record<string, RemainingAllowanceQuantityDataType[]>;
  }) => void;
}

export function EditHealthPlanAllowanceUsageRow({
  storeItemId,
  storeItemCategory,
  itemDetails,
  availableAllowances,
  onAllowanceApplied,
}: EditHealthPlanAllowanceUsageRowProps) {
  const allowanceKeys = useMemo(
    () => [storeItemId, storeItemCategory],
    [storeItemId, storeItemCategory],
  );
  const flatAllowances = useMemo(
    () => allowanceKeys.flatMap((key) => availableAllowances[key] || []),
    [allowanceKeys, availableAllowances],
  );
  const existingAllowance = useMemo(
    () =>
      itemDetails.allowance_usage && itemDetails.allowance_usage.length > 0
        ? itemDetails.allowance_usage[0]
        : null,
    [itemDetails.allowance_usage],
  );
  const [selectedAllowance, setSelectedAllowance] =
    useState<RemainingAllowanceQuantityDataType | null>(null);

  // Sets the selected allowance when the existing allowance changes or the available allowances changes.
  useEffect(() => {
    setSelectedAllowance(null);
    if (existingAllowance) {
      const matchingAllowance = flatAllowances.find(
        (allowance) =>
          allowance.allowance_id === existingAllowance.allowance_id,
      );
      if (matchingAllowance != null) {
        setSelectedAllowance(matchingAllowance);
      }
    }
  }, [existingAllowance, flatAllowances]);

  const formik = useFormik({
    initialValues: {
      allowanceId: existingAllowance?.allowance_id || null,
      quantity: existingAllowance?.quantity || 0,
    },
    validationSchema: toFormikValidationSchema(validationSchema),
    validate: toFormikValidate(validationSchema),
    onSubmit: (values) => {
      const newSelectedAllowance = flatAllowances.find(
        (allowance) => allowance.allowance_id === values.allowanceId,
      );

      const updatedAllowances = produce(availableAllowances, (draft) => {
        // First, give back the quantity from the existing applied allowance
        if (existingAllowance) {
          allowanceKeys.forEach((key) => {
            if (draft[key]) {
              const index = draft[key].findIndex(
                (a) => a.allowance_id === existingAllowance.allowance_id,
              );
              if (
                index !== -1 &&
                draft[key][index].remainingQuantity !==
                  ALLOWANCES_UNLIMITED_QUANTITY
              ) {
                draft[key][index].remainingQuantity +=
                  existingAllowance.quantity;
              }
            }
          });
        }

        // Then, apply the new allowance if selected
        if (newSelectedAllowance) {
          allowanceKeys.forEach((key) => {
            if (draft[key]) {
              const index = draft[key].findIndex(
                (a) => a.allowance_id === values.allowanceId,
              );
              if (
                index !== -1 &&
                draft[key][index].remainingQuantity !==
                  ALLOWANCES_UNLIMITED_QUANTITY
              ) {
                draft[key][index].remainingQuantity = Math.max(
                  0,
                  draft[key][index].remainingQuantity - values.quantity,
                );
              }
            }
          });
        }
      });

      setSelectedAllowance(newSelectedAllowance || null);

      if (newSelectedAllowance) {
        const allowanceUsage: AllowanceUsageDataType = {
          id: existingAllowance?.id || uuidv4(),
          allowance_id: newSelectedAllowance.allowance_id,
          subscription_id: newSelectedAllowance.subscription_id,
          quantity: values.quantity,
          allowance: {
            item: newSelectedAllowance.item,
            item_type: newSelectedAllowance.item_type,
            config: newSelectedAllowance.config,
            service: newSelectedAllowance.service,
            product: newSelectedAllowance.product,
          },
        };

        onAllowanceApplied({
          allowanceUsed: allowanceUsage,
          availableAllowances: updatedAllowances,
        });
      } else {
        onAllowanceApplied({
          allowanceUsed: null,
          availableAllowances: updatedAllowances,
        });
      }
    },
  });

  useEffect(() => {
    if (selectedAllowance && formik.values.quantity > itemDetails.quantity) {
      formik.setFieldValue('quantity', itemDetails.quantity).then(() => {
        formik.submitForm();
      });
    }
  }, [itemDetails.quantity, selectedAllowance, formik.values.quantity]);

  if (
    (itemDetails.allowance_usage == null ||
      itemDetails.allowance_usage.length === 0) &&
    (!hasRemainingAllowances(availableAllowances) ||
      !hasAllowances(itemDetails, availableAllowances))
  ) {
    return null;
  }

  const handleRemoveAllowance = () => {
    const updatedAllowances = produce(availableAllowances, (draft) => {
      if (existingAllowance) {
        allowanceKeys.forEach((key) => {
          if (draft[key]) {
            const index = draft[key].findIndex(
              (a) => a.allowance_id === existingAllowance.allowance_id,
            );
            if (
              index !== -1 &&
              draft[key][index].remainingQuantity !==
                ALLOWANCES_UNLIMITED_QUANTITY
            ) {
              draft[key][index].remainingQuantity += existingAllowance.quantity;
            }
          }
        });
      }
    });

    formik.setValues({ allowanceId: null, quantity: 0 });
    setSelectedAllowance(null);
    onAllowanceApplied({
      allowanceUsed: null,
      availableAllowances: updatedAllowances,
    });
  };

  const renderAllowanceOption = (
    allowance: RemainingAllowanceQuantityDataType,
  ) => {
    const typeLabel = getAllowanceNameLabel({ allowance });
    const quantityLabel =
      allowance.remainingQuantity === ALLOWANCES_UNLIMITED_QUANTITY
        ? 'Unlmited'
        : `${allowance.remainingQuantity} remaining`;
    const valueLabel =
      allowance.config.value.type === HEALTH_PLAN_ALLOWANCE_TYPE.PERCENTAGE
        ? `${allowance.config.value.value}%`
        : formatCurrency(allowance.config.value.value);
    return `${typeLabel} (${valueLabel}) | ${quantityLabel}`;
  };

  function getMaxQuantity() {
    if (selectedAllowance) {
      if (
        selectedAllowance.remainingQuantity === ALLOWANCES_UNLIMITED_QUANTITY
      ) {
        return itemDetails.quantity;
      }
      return Math.min(
        selectedAllowance.remainingQuantity +
          (existingAllowance?.quantity || 0),
        itemDetails.quantity,
      );
    }
    return 0;
  }

  return (
    <TableRow
      sx={{
        '& td, & th': {
          paddingLeft: (theme) => theme.spacing(4),
        },
      }}
    >
      <TableCell style={{ width: '60%' }}>
        <FormSelectField
          name='allowanceId'
          label='Select Allowance'
          formik={formik}
          options={flatAllowances}
          getOptionValue={(o) => o.allowance_id}
          getOptionLabel={(o) => renderAllowanceOption(o)}
          afterInputChange={() => {
            formik.setFieldValue('quantity', 1).then(() => formik.submitForm());
          }}
        />
      </TableCell>
      {selectedAllowance && (
        <>
          <TableCell style={{ width: '20%' }}>
            <NumberInput
              value={formik.values.quantity}
              showArrows
              onChange={(value) => {
                formik
                  .setFieldValue('quantity', value)
                  .then(() => formik.submitForm());
              }}
              min={0}
              max={getMaxQuantity()}
              style={{ width: 60 }}
            />
          </TableCell>
          <TableCell style={{ width: '20%' }}>
            <IconButton onClick={handleRemoveAllowance} color='error'>
              <IconTrash />
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
