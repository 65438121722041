import React from 'react';

import { Stack, Typography } from '@mui/material';

import { formatCurrency } from '~/utils/i18n';

interface AppointmentsBillingSummaryRowProps {
  label: string;
  sublabel?: string;
  value: number;
  isDiscount?: boolean;
  isTotal?: boolean;
}

export default function AppointmentsBillingSummaryRow({
  label,
  sublabel,
  value,
  isDiscount = false,
  isTotal = false,
}: AppointmentsBillingSummaryRowProps) {
  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Stack direction='column'>
        <Typography
          color={isTotal ? 'primary' : 'inherit'}
          variant={isTotal ? 'subtitle1' : 'body1'}
        >
          {label}
        </Typography>
        {sublabel && (
          <Typography variant='caption' color='text.secondary'>
            {sublabel}
          </Typography>
        )}
      </Stack>
      <Typography
        variant='body2'
        sx={{
          borderRadius: 1,
          backgroundColor: '#F8F9FA',
          padding: 1,
        }}
      >
        {isDiscount
          ? value
            ? `-${formatCurrency(value)}`
            : '-'
          : formatCurrency(value)}
      </Typography>
    </Stack>
  );
}
