import React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { blueGrey } from '@mui/material/colors';
import { IconMenu2, IconUser } from '@tabler/icons-react';

import { trpc } from '~/lib/trpc';

import Loading from '../components/ui/Loading';
import { usePopover } from '../hooks/use-popover';
import { globalSingleton } from '../singletons/globalSingleton';
import { getImagePublicUrl } from '../utils/get-image-public-url';
import { AccountPopover } from './AccountPopover';

export const AccountButton = () => {
  const { data, isLoading } = trpc.store.getRoot.useQuery(undefined, {
    enabled: globalSingleton.accessToken != null,
  });

  const popover = usePopover();

  if (isLoading || data == null) {
    return <Loading />;
  }

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          flexDirection: 'row',
          borderColor: 'white',
          borderStyle: 'solid',
          borderRadius: 4,
          gap: 1.5,
          padding: 0.5,
          pl: 1,
          backgroundColor: '#1c2536',
          boxShadow: '2px 2px 2px rgba(255, 255, 255, 0.2)',
        }}
      >
        <IconMenu2 color={blueGrey[500]} size={16} />

        <Avatar
          sx={{
            height: 28,
            width: 28,
          }}
          src={getImagePublicUrl(data.avatar_url)}
        >
          <IconUser />
        </Avatar>
      </Box>

      <AccountPopover
        employee={data}
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
