import React from 'react';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { FormikProps } from 'formik';

import { AutocompleteSearch } from '~/components/ui/AutocompleteSearch';
import LupaDatePicker from '~/components/ui/LupaDatePicker';
import { TrpcRouterOutputs, trpc } from '~/lib/trpc';
import { getFieldError, getFieldHelperText } from '~/utils/formik-utils';

import { SPECIES_OPTIONS } from '../../../data/species';
import { ItemsSearch } from '../../ui/ItemsSearch';
import { AppointmentCreateForm } from './AppointmentCreate';

type AppointmentUpsertCreatePetProps = {
  formik: FormikProps<AppointmentCreateForm>;
  isWidthXs: boolean;
};

export default function AppointmentUpsertCreatePet({
  formik,
  isWidthXs,
}: AppointmentUpsertCreatePetProps) {
  return (
    <Grid xs={12} p={0}>
      <Grid container spacing={2} direction='row' p={0}>
        <Grid xs={12} md={isWidthXs ? 12 : 6}>
          <TextField
            fullWidth
            variant='outlined'
            label='Name'
            name='newPet.name'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.newPet?.name ?? ''}
            error={getFieldError(formik, 'newPet.name')}
            helperText={getFieldHelperText(formik, 'newPet.name')}
          />
        </Grid>

        <Grid xs={12} md={isWidthXs ? 12 : 6}>
          <ItemsSearch
            name='newPet.species'
            placeholder='Species'
            items={formik.values.newPet?.species}
            localOptions={SPECIES_OPTIONS}
            getOptionLabel={(option) =>
              option.species ? option.species : option
            }
            groupBy={(option) => option.group}
            handleOnChange={(item) => {
              formik.setFieldValue('newPet.species', item?.species);
              formik.setFieldValue('newPet.breed', '');
            }}
            multiple={false}
            formik={formik}
          />
        </Grid>

        <Grid xs={12} md={isWidthXs ? 12 : 6}>
          <ItemsSearch
            name='newPet.sex'
            placeholder='Sex'
            items={formik.values.newPet?.sex}
            localOptions={['Male', 'Female', 'Unknown']}
            getOptionLabel={(option) => option}
            handleOnChange={(item) => {
              formik.setFieldValue(`newPet.sex`, item);
            }}
            multiple={false}
            formik={formik}
          />
        </Grid>

        <Grid xs={12} md={isWidthXs ? 12 : 6}>
          <AutocompleteSearch<{
            ItemType: TrpcRouterOutputs['pets']['searchBreeds'][0];
            FreeSolo: true;
          }>
            trpcFunction={trpc.pets.searchBreeds}
            extraTrpcInput={{
              species: formik.values.newPet?.species ?? '',
            }}
            label='Breed (Select or type)'
            getOptionLabel={(option) =>
              typeof option === 'string' ? (option ?? '') : option.breed
            }
            freeSolo
            value={formik.values.newPet?.breed}
            onSelect={(item) => {
              formik.setFieldValue('newPet.breed', item?.breed ?? item);
            }}
            freeSoloOnTextChange={(text) => {
              formik.setFieldValue('newPet.breed', text);
            }}
            groupBy={(option) => option.group}
            disabled={!formik.values.newPet?.species}
            name='newPet.breed'
            formik={formik}
          />
        </Grid>

        <Grid xs={12} md={isWidthXs ? 12 : 6}>
          <TextField
            type='number'
            fullWidth
            variant='outlined'
            label='Weight - kg (optional)'
            name='newPet.weight'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.newPet?.weight}
            helperText={getFieldHelperText(formik, 'newPet.weight')}
            error={getFieldError(formik, 'newPet.weight')}
          />
        </Grid>

        <Grid xs={12} md={isWidthXs ? 12 : 6}>
          <LupaDatePicker
            label='Date of Birth (Optional)'
            value={new Date(formik.values.newPet?.dob ?? '')}
            onChange={(value) => {
              if (!value) {
                formik.setFieldValue(`newPet.dob`, null);
                return;
              }

              formik.setFieldValue(`newPet.dob`, value.toISOString());
            }}
            maxDate={new Date()}
            slotProps={{
              textField: {
                helperText: getFieldHelperText(formik, 'newPet.dob'),
                fullWidth: true,
                error: getFieldError(formik, 'newPet.dob'),
                variant: 'outlined',
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
