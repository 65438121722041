import { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';

export default function useGetComponentWidth(
  containerRef: React.RefObject<HTMLElement>,
) {
  const theme = useTheme();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();
    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [containerRef]);

  return {
    isWidthXs: width < theme.breakpoints.values.sm,
    isWidthSm: width < theme.breakpoints.values.md,
    isWidthMd: width < theme.breakpoints.values.lg,
    isWidthLg: width < theme.breakpoints.values.xl,
    isWidthXl: width >= theme.breakpoints.values.xl,
  };
}
