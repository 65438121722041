import React from 'react';

import {
  Button,
  ButtonProps,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
} from '@mui/material';
import { IconChevronDown } from '@tabler/icons-react';

import { usePopover } from '~/hooks/use-popover';

type DropdownMenuButtonProps = {
  options: {
    name: string;
    onClick: () => void;
    icon?: React.ReactNode;
    dataTestId?: string;
  }[];
  testId?: string;
  size?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
} & ButtonProps;

export default function DropdownMenuButton({
  options,
  testId,
  children,
  size = 'small',
  ...props
}: DropdownMenuButtonProps) {
  const createPopover = usePopover();

  return (
    <>
      <Button
        data-testid={testId}
        ref={createPopover.anchorRef}
        color='primary'
        size={size}
        onClick={createPopover.handleOpen}
        sx={children ? undefined : { minWidth: 'auto', padding: '6px' }}
        {...props}
      >
        {children ?? <IconChevronDown size={16} />}
      </Button>

      <Popover
        open={createPopover.open}
        anchorEl={createPopover.anchorRef.current}
        onClose={createPopover.handleClose}
        elevation={20}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList autoFocusItem>
          {options.map((option) => (
            <MenuItem
              key={option.name}
              onClick={() => {
                createPopover.handleClose();
                option.onClick();
              }}
              data-testid={option.dataTestId}
            >
              {option.icon && (
                <ListItemIcon sx={{ m: 0 }}>{option.icon}</ListItemIcon>
              )}
              <ListItemText>{option.name}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
}
