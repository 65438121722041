export const SPECIES_OPTIONS = [
  { group: 'Avian', species: 'Budgie' },
  { group: 'Avian', species: 'Canary' },
  { group: 'Avian', species: 'Chicken' },
  { group: 'Avian', species: 'Parrot' },
  { group: 'Avian', species: 'Avian - Other' },
  { group: 'Cat', species: 'Cat' },
  { group: 'Dog', species: 'Dog' },
  { group: 'Exotic Pets', species: 'Tarantula' },
  { group: 'Exotic Pets', species: 'Exotic Pets - Other' },
  { group: 'Equine', species: 'Horse' },
  { group: 'Equine', species: 'Donkey' },
  { group: 'Equine', species: 'Mule' },
  { group: 'Fish', species: 'Freshwater Fish' },
  { group: 'Fish', species: 'Saltwater Fish' },
  { group: 'Livestock & Farm', species: 'Bovine' },
  { group: 'Livestock & Farm', species: 'Caprine' },
  { group: 'Livestock & Farm', species: 'Ovine' },
  { group: 'Livestock & Farm', species: 'Porcine' },
  { group: 'Livestock & Farm', species: 'Livestock & Farm - Other' },
  { group: 'Reptile & Amphibian', species: 'Frog' },
  { group: 'Reptile & Amphibian', species: 'Lizard' },
  { group: 'Reptile & Amphibian', species: 'Snake' },
  { group: 'Reptile & Amphibian', species: 'Tortoise' },
  { group: 'Reptile & Amphibian', species: 'Turtle' },
  { group: 'Reptile & Amphibian', species: 'Reptile & Amphibian - Other' },
  { group: 'Small Mammals', species: 'Chinchilla' },
  { group: 'Small Mammals', species: 'Ferret' },
  { group: 'Small Mammals', species: 'Guinea Pig' },
  { group: 'Small Mammals', species: 'Hamster' },
  { group: 'Small Mammals', species: 'Mouse' },
  { group: 'Small Mammals', species: 'Rabbit' },
  { group: 'Small Mammals', species: 'Rat' },
  { group: 'Small Mammals', species: 'Sugar Glider' },
  { group: 'Small Mammals', species: 'Small Mammals - Other' },
  { group: 'Wildlife', species: 'Wildlife' },
];
