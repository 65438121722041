type OpeningTime = {
  open: string;
  close: string;
};

type OpeningTimes = {
  times: OpeningTime[];
}[];

export const getMinMaxOpeningHours = (openingTimes: OpeningTimes) => {
  let min = '24:00';
  let max = '00:00';

  openingTimes.forEach((openingTime) => {
    openingTime.times.forEach((time) => {
      if (time.open < min) {
        min = time.open;
      }
      if (time.close > max) {
        max = time.close;
      }
    });
  });

  // Add 'padding' to the min and max times
  min = Number(min.split(':')[0]) - 1 + min.slice(2);
  max = Number(max.split(':')[0]) + 2 + max.slice(2);

  return { min, max };
};

const ID_TO_COLOR: Record<string, string> = {};
let LAST_USED_COLOR_INDEX = 0;
const COLORS = [
  'rgba(137, 196, 194, 0.9)',
  'rgba(208, 235, 197, 0.9)',
  'rgba(243, 247, 193, 0.9)',
  'rgba(249, 212, 164, 0.9)',
  'rgba(245, 162, 162, 0.9)',
  'rgba(188, 120, 158, 0.9)',
];

export const employeeIdToColor = (str: string) => {
  if (ID_TO_COLOR[str]) {
    return ID_TO_COLOR[str];
  }

  const index = LAST_USED_COLOR_INDEX % COLORS.length;
  LAST_USED_COLOR_INDEX += 1;
  const color = COLORS[index];
  ID_TO_COLOR[str] = color;

  return color;
};
