import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

type TableRowsSkeletonProps = {
  numRows: number;
  numCols: number;
};

export default function TableRowsSkeleton({
  numRows,
  numCols,
}: TableRowsSkeletonProps) {
  return (
    <>
      {Array(numRows)
        .fill(null)
        .map((_, index) => (
          <TableRow key={index}>
            {Array(numCols)
              .fill(null)
              .map((_, idx) => (
                <TableCell key={idx} component='th' scope='row'>
                  <Skeleton animation='wave' variant='text' />
                </TableCell>
              ))}
          </TableRow>
        ))}
    </>
  );
}
