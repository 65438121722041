// Keep in sync with expo/src/consts/companyCategories.js
import { capitalize } from '../utils/capitalize';
import { APPOINTMENT_TYPE, STORE_CATEGORY } from '../utils/enums';

const APPOINTMENT_DEFAULT_COLOR = 'rgba(188, 120, 158, 0.9)';

const appointmentTypesCategories = {
  [APPOINTMENT_TYPE.ADMIN]: {
    value: APPOINTMENT_TYPE.ADMIN,
    label: 'Admin',
    store_category: STORE_CATEGORY.OTHER,
    color: 'rgba(188, 120, 158, 0.9)',
  },
  [APPOINTMENT_TYPE.CONSULT]: {
    value: APPOINTMENT_TYPE.CONSULT,
    label: 'Consult',
    store_category: STORE_CATEGORY.VET,
    color: 'rgba(137, 196, 194, 0.9)',
  },
  [APPOINTMENT_TYPE.DAYCARE]: {
    value: APPOINTMENT_TYPE.DAYCARE,
    label: 'Daycare',
    store_category: STORE_CATEGORY.BOARDING,
    color: 'rgba(137, 196, 194, 0.9)',
  },
  [APPOINTMENT_TYPE.DENTAL]: {
    value: APPOINTMENT_TYPE.DENTAL,
    label: 'Dental',
    store_category: STORE_CATEGORY.VET,
    color: 'rgba(249, 212, 164, 0.9)',
  },
  [APPOINTMENT_TYPE.DIAGNOSTICS]: {
    value: APPOINTMENT_TYPE.DIAGNOSTICS,
    label: 'Diagnostics',
    store_category: STORE_CATEGORY.VET,
    color: 'rgba(249, 212, 164, 0.9)',
  },
  [APPOINTMENT_TYPE.EUTHANASIA]: {
    value: APPOINTMENT_TYPE.EUTHANASIA,
    label: 'Euthanasia',
    store_category: STORE_CATEGORY.VET,
    color: 'rgba(188, 120, 158, 0.9)',
  },
  [APPOINTMENT_TYPE.GROOMING]: {
    value: APPOINTMENT_TYPE.GROOMING,
    label: 'Grooming',
    store_category: STORE_CATEGORY.GROOMING,
    color: 'rgba(137, 196, 194, 0.9)',
  },
  [APPOINTMENT_TYPE.GROUP_TRAINING]: {
    value: APPOINTMENT_TYPE.GROUP_TRAINING,
    label: 'Group',
    store_category: STORE_CATEGORY.TRAINING,
    color: 'rgba(137, 196, 194, 0.9)',
  },
  [APPOINTMENT_TYPE.GROUP_WALKING]: {
    value: APPOINTMENT_TYPE.GROUP_WALKING,
    label: 'Group',
    store_category: STORE_CATEGORY.WALKING,
    color: 'rgba(137, 196, 194, 0.9)',
  },
  [APPOINTMENT_TYPE.HEALTH]: {
    value: APPOINTMENT_TYPE.HEALTH,
    label: 'Health & Hygiene',
    store_category: STORE_CATEGORY.GROOMING,
    color: 'rgba(208, 235, 197, 0.9)',
  },
  [APPOINTMENT_TYPE.HOSPITAL]: {
    value: APPOINTMENT_TYPE.HOSPITAL,
    label: 'Hospital',
    store_category: STORE_CATEGORY.VET,
    color: 'rgba(243, 247, 193, 0.9)',
  },
  [APPOINTMENT_TYPE.IN_HOME]: {
    value: APPOINTMENT_TYPE.IN_HOME,
    label: 'In Home',
    store_category: STORE_CATEGORY.BOARDING,
    color: 'rgba(243, 247, 193, 0.9)',
  },
  [APPOINTMENT_TYPE.INDIVIDUAL_TRAINING]: {
    value: APPOINTMENT_TYPE.INDIVIDUAL_TRAINING,
    label: 'Individual',
    store_category: STORE_CATEGORY.TRAINING,
    color: 'rgba(208, 235, 197, 0.9)',
  },
  [APPOINTMENT_TYPE.INDIVIDUAL_WALKING]: {
    value: APPOINTMENT_TYPE.INDIVIDUAL_WALKING,
    label: 'Individual',
    store_category: STORE_CATEGORY.WALKING,
    color: 'rgba(208, 235, 197, 0.9)',
  },
  [APPOINTMENT_TYPE.NURSE]: {
    value: APPOINTMENT_TYPE.NURSE,
    label: 'Nurse',
    store_category: STORE_CATEGORY.VET,
    color: 'rgba(208, 235, 197, 0.9)',
  },
  [APPOINTMENT_TYPE.OTHER]: {
    value: APPOINTMENT_TYPE.OTHER,
    label: 'Other',
    store_category: STORE_CATEGORY.OTHER,
    color: 'rgba(188, 120, 158, 0.9)',
  },
  [APPOINTMENT_TYPE.OVERNIGHT]: {
    value: APPOINTMENT_TYPE.OVERNIGHT,
    label: 'Overnight',
    store_category: STORE_CATEGORY.BOARDING,
    color: 'rgba(208, 235, 197, 0.9)',
  },
  [APPOINTMENT_TYPE.SURGICAL]: {
    value: APPOINTMENT_TYPE.SURGICAL,
    label: 'Surgical',
    store_category: STORE_CATEGORY.VET,
    color: 'rgba(245, 162, 162, 0.9)',
  },
  [APPOINTMENT_TYPE.VACCINATION]: {
    value: APPOINTMENT_TYPE.VACCINATION,
    label: 'Vaccination',
    store_category: STORE_CATEGORY.VET,
    color: 'rgba(243, 247, 193, 0.9)',
  },
};

export const storeCategories = Object.values(STORE_CATEGORY);

export const getAppointmentCategoriesForStoreCategories = (
  categories: STORE_CATEGORY[],
): APPOINTMENT_TYPE[] => {
  let appointmentCategories: APPOINTMENT_TYPE[] = Object.values(
    appointmentTypesCategories,
  )
    .filter((appointmentType) =>
      categories.includes(appointmentType.store_category),
    )
    .map((appointmentType) => appointmentType.value);

  if (appointmentCategories.length === 0) {
    appointmentCategories = Object.values(APPOINTMENT_TYPE);
  }

  appointmentCategories.push(APPOINTMENT_TYPE.OTHER);

  return appointmentCategories;
};

export const getAppointmentTypeColor = (value: APPOINTMENT_TYPE | null) => {
  if (!value) {
    return APPOINTMENT_DEFAULT_COLOR;
  }

  const appointmentType = appointmentTypesCategories[value];
  return appointmentType ? appointmentType.color : APPOINTMENT_DEFAULT_COLOR;
};

export const getAppointmentCategoryLabel = (value: APPOINTMENT_TYPE) => {
  const appointmentType = appointmentTypesCategories[value];
  return appointmentType
    ? appointmentType.label
    : value
      ? capitalize(value)
      : '';
};
