import React, { useEffect, useRef, useState } from 'react';

import {
  Avatar,
  ButtonBase,
  Card,
  Grow,
  IconButton,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconSend, IconX } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { trcpProxyClient } from '~/lib/trpc';
import { globalSingleton } from '~/singletons/globalSingleton';

import LoadingIconButton from './ui/LoadingIconButton';

export default function JerryChat() {
  const { appointmentId, petId } = useParams();

  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme: any = useTheme();
  const [popperAnchor, setPopperAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<
    {
      content: string;
      role: 'assistant' | 'user';
    }[]
  >([
    {
      content: 'Hey! My name is Jerry, I am your personal AI assistant! 🚀',
      role: 'assistant',
    },
  ]);
  const [inputValue, setInputValue] = useState('');
  const open = Boolean(popperAnchor);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop =
          scrollableDivRef.current.scrollHeight;
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [open, messages]);

  useEffect(() => {
    setMessages([
      {
        content:
          'Hey! My name is Jerry, I am your personal AI assistant! 🚀\n\nHow can I help?',
        role: 'assistant',
      },
    ]);
    setPopperAnchor(null);
  }, [appointmentId, petId]);

  // New effect to handle input focus when popper opens
  useEffect(() => {
    if (open) {
      const timeoutId = setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [open]);

  if (!globalSingleton.currentStore.features?.jerry_chat_enabled) {
    return null;
  }

  const handleSendMessage = async () => {
    setLoading(true);
    const newMessages = [
      ...messages,
      { content: inputValue, role: 'user' as const },
    ];
    setMessages(newMessages);
    setInputValue('');

    const data = await trcpProxyClient.store.messageJerry.mutate({
      appointmentId,
      petId,
      messages: newMessages,
    });

    if (data == null) {
      toast.error('Something went wrong :( - Try again later!');
      setLoading(false);
      return;
    }

    setMessages([...newMessages, { content: data, role: 'assistant' }]);

    setLoading(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div>
      <ButtonBase
        id='jerry-chat'
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setPopperAnchor(popperAnchor == null ? event.currentTarget : null);
        }}
        style={{
          position: 'fixed',
          bottom: 20,
          left: 20,
          width: 60,
          height: 60,
          borderRadius: '100%',
          zIndex: 1000,
          userSelect: 'none',
        }}
      >
        <img
          alt='jerry-chat'
          width={60}
          height={60}
          src='/assets/morphing-fluid.gif'
          style={{
            borderRadius: '100%',
            position: 'absolute',
            border: '3px solid',
            borderColor: theme.palette.primary.main,
          }}
        />

        <img
          alt='jerry'
          width={50}
          height={50}
          src='/assets/jerry.png'
          style={{ borderRadius: '100%', position: 'absolute', bottom: 2 }}
        />
      </ButtonBase>

      <Popper
        open={open}
        anchorEl={popperAnchor}
        placement='top-end'
        sx={{ paddingBottom: '10px', zIndex: 10000 }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: '0 100% 0' }}>
            <Card style={{ borderRadius: 16 }}>
              <Stack justifyContent='space-between' height={600} width={300}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  p={2}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <Stack direction='row' alignItems='center'>
                    <Avatar src='/assets/jerry.png' />

                    <Stack direction='column'>
                      <Typography
                        variant='h6'
                        color='white'
                        style={{ marginLeft: 8 }}
                      >
                        Jerry
                      </Typography>
                      <Typography
                        variant='body2'
                        color='white'
                        style={{ marginLeft: 8 }}
                      >
                        AI Assistant
                      </Typography>
                    </Stack>
                  </Stack>

                  <IconButton onClick={() => setPopperAnchor(null)}>
                    <IconX color='white' />
                  </IconButton>
                </Stack>

                <Stack>
                  <div
                    ref={scrollableDivRef}
                    style={{
                      maxHeight: 470,
                      overflowY: 'scroll',
                      paddingTop: 8,
                    }}
                  >
                    <Stack flexGrow={1} px={2} spacing={1}>
                      {messages.map((message, index) => {
                        const isAssistant = message.role === 'assistant';
                        return (
                          <Stack
                            key={index}
                            alignSelf={isAssistant ? 'flex-start' : 'flex-end'}
                            paddingRight={isAssistant ? 3 : 0}
                            paddingLeft={isAssistant ? 0 : 3}
                          >
                            <div
                              style={{
                                backgroundColor: isAssistant
                                  ? theme.palette.primary.light
                                  : theme.palette.neutral[200],
                                padding: 8,
                                borderRadius: 16,
                                alignSelf: isAssistant
                                  ? 'flex-start'
                                  : 'flex-end',
                                alignItems: 'flex-start',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              <Typography>{message.content}</Typography>
                            </div>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </div>

                  <Stack direction='row' alignItems='center' p={1}>
                    <TextField
                      inputRef={inputRef}
                      fullWidth
                      variant='outlined'
                      placeholder='Ask anything'
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyPress}
                      size='small'
                      style={{ margin: '0 8px 0 0' }}
                      multiline
                    />
                    <LoadingIconButton
                      color='primary'
                      size='small'
                      onClick={handleSendMessage}
                      disabled={inputValue.trim() === ''}
                      loading={loading}
                      loadingStyle={{ marginLeft: 14 }}
                    >
                      <IconSend />
                    </LoadingIconButton>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
