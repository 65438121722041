export const API_KEYS = {
  CONVERSATIONS: 'conversations',
  CONVERSATION: 'conversation',
  PRODUCTS: 'products',

  UPSERT_APPOINTMENT_REMINDER: 'upsertAppointmentReminder',
  UPSERT_TREATMENT: 'upsertTreatment',

  DELETE_APPOINTMENT_FILE: 'deleteAppointmentFile',
  DELETE_STORE_QUESTIONNAIRE: 'deleteStoreQuestionnaire',
  DELETE_APPOINTMENT_REMINDER: 'deleteAppointmentReminder',
  DELETE_TREATMENT: 'deleteTreatment',
  DELETE_STORE_XERO: 'deleteStoreXero',

  ADD_TO_CHAT: 'addToChat',

  UPDATE_APPOINTMENT_FILES: 'updateAppointmentFiles',
  UPDATE_PET_FILES: 'updatePetFiles',
  DELETE_PET_FILE: 'deletePetFile',

  UPLOAD_CLAIM_FILES: 'uploadClaimFiles',

  UPDATE_STORE_BANK_ACCOUNT: 'updateStoreBankAccount',
  UPDATE_STORE_QUESTIONNAIRE: 'updateStoreQuestionnaire',
  UPDATE_STORE_BOOKING_CONFIRMATION: 'updateStoreBookingConfirmation',
  UPDATE_STORE_XERO_ACCOUNTS: 'updateStoreXeroAccounts',

  GET_OR_CREATE_CONVERSATION: 'getOrCreateConversation',

  STORE_UPLOAD_IMAGES: 'storeUploadImages',

  FETCH_APPOINTMENT_PROCESSED_DATA: 'fetchAppointmentProcessedData',
};
