import React from 'react';

import { Theme } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box, { BoxProps } from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  IconBell,
  IconHelp,
  IconLayoutSidebarLeftCollapse,
  IconMenu2,
} from '@tabler/icons-react';
import { useAtom } from 'jotai';

import { globalSingleton } from '~/singletons/globalSingleton';

import { useGetConversations } from '../api/chatApi';
import { mainDrawerOpenAtom } from '../atoms/atoms';
import { RouterLink } from '../components/RouterLink';
import useGetNotifications from '../hooks/use-get-notifications';
import { usePathname } from '../hooks/use-pathname';
import { AccountButton } from './AccountButton';
import { SearchButton } from './SearchButton';
import { useCssVars } from './SideNav';

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;
const APPS_PATHS = [
  'analytics',
  'client-comms',
  'debts',
  'diagnostics',
  'claims',
  'inventory',
  'rota',
];

interface TopNavProps extends BoxProps {
  onMobileNavOpen: () => void;
  sections: any[];
}

export const TopNav = ({
  onMobileNavOpen,
  sections,
  ...other
}: TopNavProps) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const pathname = usePathname();
  const cssVars: any = useCssVars('evident');
  const [mainDrawerOpen, setMainDrawerOpen] = useAtom(mainDrawerOpenAtom);

  const { data: notifications } = useGetNotifications();
  const { data: conversations } = useGetConversations();

  const unreadCount = conversations?.reduce(
    (acc: number, conversation: any) => {
      const lastMessage = conversation.last_message;
      const participant = conversation.participants.find(
        (p: any) => p.profile_id === globalSingleton.currentEmployee.profile_id,
      );

      if (
        participant &&
        lastMessage &&
        lastMessage.length &&
        lastMessage[0].message &&
        new Date(lastMessage[0].message.created_at) >
          new Date(participant.read_at)
      ) {
        return acc + 1;
      }
      return acc;
    },
    0,
  );

  return (
    <Box
      component='header'
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme: any) => theme.palette.neutral[800],
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: mainDrawerOpen ? `calc(100% - ${SIDE_NAV_WIDTH}px)` : '100%',
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
        }}
      >
        <Stack alignItems='center' direction='row' spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <IconMenu2 />
            </IconButton>
          )}

          {lgUp && (
            <Badge
              color='primary'
              overlap='circular'
              sx={{
                '& .MuiBadge-badge': { fontSize: 9, height: 15, minWidth: 15 },
              }}
              badgeContent={mainDrawerOpen ? 0 : notifications?.length}
            >
              <IconButton onClick={() => setMainDrawerOpen(!mainDrawerOpen)}>
                {mainDrawerOpen ? (
                  <IconLayoutSidebarLeftCollapse />
                ) : (
                  <IconBell />
                )}
              </IconButton>
            </Badge>
          )}
        </Stack>

        {lgUp && (
          <Stack
            sx={{
              ...cssVars,
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
            alignItems='center'
            direction='row'
            spacing={2}
          >
            {sections[0].items.map((item: any, index: number) => {
              const pathSegments = pathname.split('?')[0].split('/');
              const itemPathSegments = item.path.split('?')[0].split('/');
              let active = false;

              if (
                (item.path === '/' && pathname === '/') ||
                (pathSegments[1] === 'calendar' && item.path === '/')
              ) {
                active = true;
              } else if (
                pathSegments[1] === 'pets' &&
                item.path.startsWith('/clients')
              ) {
                active = true;
              } else if (
                APPS_PATHS.includes(pathSegments[1]) &&
                item.path.startsWith('/apps')
              ) {
                active = true;
              } else if (
                item.path !== '/' &&
                pathname !== '/' &&
                pathSegments[1] === itemPathSegments[1]
              ) {
                active = true;
              }

              return (
                <Stack
                  key={index}
                  direction='column'
                  justifyContent='center'
                  style={{ height: 64, position: 'relative' }}
                >
                  <Tooltip title={item.title} enterDelay={200}>
                    <ButtonBase
                      component={RouterLink}
                      href={item.path}
                      data-testid={item.testId}
                      sx={{
                        alignItems: 'center',
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: `16px`,
                        pr: '16px',
                        py: '12px',
                        textAlign: 'left',
                        width: '100%',
                        ...(active && {
                          ...{
                            backgroundColor: 'var(--nav-item-active-bg)',
                          },
                        }),
                        '&:hover': {
                          backgroundColor: 'var(--nav-item-hover-bg)',
                        },
                      }}
                    >
                      <Stack
                        direction='column'
                        spacing={0.5}
                        sx={{
                          color: active
                            ? 'var(--nav-item-icon-active-color)'
                            : 'var(--nav-section-title-color)',
                          mx: 2,
                          textTransform: 'uppercase',
                          '& svg': {
                            fontSize: '24px !important',
                          },
                        }}
                      >
                        {item.title === 'Chat' ? (
                          <Badge color='primary' badgeContent={unreadCount}>
                            {item.icon}
                          </Badge>
                        ) : (
                          item.icon
                        )}
                      </Stack>
                    </ButtonBase>
                  </Tooltip>

                  <div
                    style={{
                      position: 'absolute',
                      height: '4px',
                      bottom: 0,
                      width: '100%',
                      backgroundColor: active
                        ? 'var(--nav-item-icon-active-color)'
                        : 'transparent',
                      borderRadius: '2px',
                      transition: 'background-color 0.3s ease-in-out',
                      ...(!active && {
                        ':hover': {
                          backgroundColor: 'var(--nav-item-hover-bg)',
                        },
                      }),
                    }}
                  />
                </Stack>
              );
            })}
          </Stack>
        )}

        <Stack
          justifySelf='flex-end'
          alignItems='center'
          direction='row'
          spacing={2}
        >
          <Tooltip title='Help'>
            <IconButton id='intercom-help-button'>
              <IconHelp />
            </IconButton>
          </Tooltip>
          <SearchButton />
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};
