import React from 'react';

import { formatCurrency } from '~/utils/i18n';
import mathUtils from '~/utils/math-utils';

import { Chart } from '../Chart';

interface InventoryProductFormDiscountsChartProps {
  procurementCost: number;
  discount1: number;
  discount2: number;
  discount3: number;
  markUp: number;
  vat: number;
}

export default function InventoryProductFormDiscountsChart({
  procurementCost,
  discount1,
  discount2,
  discount3,
  markUp,
  vat,
}: InventoryProductFormDiscountsChartProps) {
  const discount1Value = procurementCost * mathUtils.toPercentage(discount1);
  const discount2Value = procurementCost * mathUtils.toPercentage(discount2);
  const discount3Value = procurementCost * mathUtils.toPercentage(discount3);

  /*
    Discounts are applied as a percentage of the procurement cost.
    The price after discounts is called NNN price.
    Mark up is applied on the NNN price.
    VAT is applied on the price after mark up.
    Final price is the price after VAT, and cannot be less than 0.
  */
  const dataPoint1 = procurementCost;
  const dataPoint2 = procurementCost - discount1Value;
  const dataPoint3 = dataPoint2 - discount2Value;
  const dataPoint4 = dataPoint3 - discount3Value;
  const dataPoint5 = dataPoint4 * (1 + markUp / 100);
  const dataPoint6 = dataPoint5 * (1 + vat / 100);
  const dataPoint7 = mathUtils.roundCurrency(Math.max(dataPoint6, 0));

  const labels = [
    'Procurement Cost',
    'Discount 1',
    'Discount 2',
    'Discount 3',
    'NNN Price',
    'Mark Up vs NNN',
    'VAT',
    'Price',
  ];

  const dataPointsArray = [
    dataPoint1,
    dataPoint2,
    dataPoint3,
    dataPoint4,
    dataPoint4,
    dataPoint5,
    dataPoint6,
    dataPoint7,
  ];

  const shouldIndexBeFullBar = (index: number) => {
    return index === 0 || index === dataPointsArray.length - 1 || index === 4;
  };

  return (
    <Chart
      height={500}
      options={{
        chart: {
          height: 500,
          type: 'rangeBar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: 'end',
          },
        },
        tooltip: {
          enabled: false,
        },
        colors: ['#7C3AED'],
        dataLabels: {
          enabled: true,
          formatter: (value, { dataPointIndex }): string | number => {
            const previous = shouldIndexBeFullBar(dataPointIndex)
              ? 0
              : dataPointsArray[dataPointIndex - 1];
            const diff = dataPointsArray[dataPointIndex] - previous;

            if (typeof value === 'number') {
              return formatCurrency(diff);
            }

            return String(value);
          },
        },
        yaxis: {
          show: false,
        },
      }}
      series={[
        {
          data: dataPointsArray.map((dataPoint, index) => {
            const previous = shouldIndexBeFullBar(index)
              ? 0
              : dataPointsArray[index - 1];

            return {
              x: labels[index],
              y: [previous, dataPoint],
            };
          }),
        },
      ]}
      merge
      type='rangeBar'
    />
  );
}
