import React from 'react';

import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export const CalendarSvgIcon = (): React.ReactNode => {
  return (
    <Svg width='12' height='12' viewBox='0 0 12 12'>
      <G clip-path='url(#clip0_2468_1200)'>
        <Path
          d='M9.5 1H9V0.5C9 0.367392 8.94732 0.240215 8.85355 0.146447C8.75979 0.0526784 8.63261 0 8.5 0C8.36739 0 8.24021 0.0526784 8.14645 0.146447C8.05268 0.240215 8 0.367392 8 0.5V1H4V0.5C4 0.367392 3.94732 0.240215 3.85355 0.146447C3.75979 0.0526784 3.63261 0 3.5 0C3.36739 0 3.24021 0.0526784 3.14645 0.146447C3.05268 0.240215 3 0.367392 3 0.5V1H2.5C1.8372 1.00079 1.20178 1.26444 0.73311 1.73311C0.264441 2.20178 0.000793929 2.8372 0 3.5L0 9.5C0.000793929 10.1628 0.264441 10.7982 0.73311 11.2669C1.20178 11.7356 1.8372 11.9992 2.5 12H9.5C10.1628 11.9992 10.7982 11.7356 11.2669 11.2669C11.7356 10.7982 11.9992 10.1628 12 9.5V3.5C11.9992 2.8372 11.7356 2.20178 11.2669 1.73311C10.7982 1.26444 10.1628 1.00079 9.5 1ZM1 3.5C1 3.10218 1.15804 2.72064 1.43934 2.43934C1.72064 2.15804 2.10218 2 2.5 2H9.5C9.89782 2 10.2794 2.15804 10.5607 2.43934C10.842 2.72064 11 3.10218 11 3.5V4H1V3.5ZM9.5 11H2.5C2.10218 11 1.72064 10.842 1.43934 10.5607C1.15804 10.2794 1 9.89782 1 9.5V5H11V9.5C11 9.89782 10.842 10.2794 10.5607 10.5607C10.2794 10.842 9.89782 11 9.5 11Z'
          fill='white'
        />
        <Path
          d='M6 8.25C6.41421 8.25 6.75 7.91421 6.75 7.5C6.75 7.08579 6.41421 6.75 6 6.75C5.58579 6.75 5.25 7.08579 5.25 7.5C5.25 7.91421 5.58579 8.25 6 8.25Z'
          fill='white'
        />
        <Path
          d='M3.5 8.25C3.91421 8.25 4.25 7.91421 4.25 7.5C4.25 7.08579 3.91421 6.75 3.5 6.75C3.08579 6.75 2.75 7.08579 2.75 7.5C2.75 7.91421 3.08579 8.25 3.5 8.25Z'
          fill='white'
        />
        <Path
          d='M8.5 8.25C8.91421 8.25 9.25 7.91421 9.25 7.5C9.25 7.08579 8.91421 6.75 8.5 6.75C8.08579 6.75 7.75 7.08579 7.75 7.5C7.75 7.91421 8.08579 8.25 8.5 8.25Z'
          fill='white'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_2468_1200'>
          <Rect width='12' height='12' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export const ClockSvgIcon = (): React.ReactNode => {
  return (
    <Svg width='12' height='12' viewBox='0 0 12 12'>
      <G>
        <Path
          d='M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C11.9983 4.40923 11.3656 2.88411 10.2407 1.75926C9.1159 0.634414 7.59077 0.00172054 6 0V0ZM6 11C5.0111 11 4.0444 10.7068 3.22215 10.1573C2.39991 9.60794 1.75904 8.82705 1.38061 7.91342C1.00217 6.99979 0.90315 5.99445 1.09608 5.02455C1.289 4.05464 1.76521 3.16373 2.46447 2.46447C3.16373 1.7652 4.05465 1.289 5.02455 1.09607C5.99446 0.903148 6.99979 1.00216 7.91342 1.3806C8.82705 1.75904 9.60794 2.3999 10.1574 3.22215C10.7068 4.04439 11 5.01109 11 6C10.9985 7.32564 10.4713 8.59656 9.53393 9.53393C8.59656 10.4713 7.32564 10.9985 6 11Z'
          fill='white'
        />
        <Path
          d='M5.99973 3C5.86712 3 5.73994 3.05268 5.64618 3.14645C5.55241 3.24021 5.49973 3.36739 5.49973 3.5V5.6625L3.81423 6.71849C3.70151 6.78891 3.62138 6.90122 3.59147 7.03071C3.56156 7.1602 3.58431 7.29628 3.65473 7.40899C3.72514 7.52171 3.83745 7.60184 3.96695 7.63175C4.09644 7.66166 4.23251 7.63891 4.34523 7.56849L6.26523 6.36849C6.33777 6.32304 6.39743 6.25973 6.43849 6.18461C6.47955 6.1095 6.50064 6.0251 6.49973 5.9395V3.5C6.49973 3.36739 6.44705 3.24021 6.35328 3.14645C6.25951 3.05268 6.13234 3 5.99973 3Z'
          fill='white'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_2468_1207'>
          <Rect width='12' height='12' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export const UserSvgIcon = (): React.ReactNode => {
  return (
    <Svg width='12' height='12' viewBox='0 0 12 12'>
      <G>
        <Path
          d='M6 6C6.59334 6 7.17336 5.82405 7.66671 5.49441C8.16006 5.16477 8.54458 4.69623 8.77164 4.14805C8.9987 3.59987 9.05811 2.99667 8.94236 2.41473C8.8266 1.83279 8.54088 1.29824 8.12132 0.878681C7.70176 0.459123 7.16721 0.173401 6.58527 0.0576455C6.00333 -0.0581102 5.40013 0.00129986 4.85195 0.228363C4.30377 0.455426 3.83524 0.839943 3.50559 1.33329C3.17595 1.82664 3 2.40666 3 3C3.00079 3.79541 3.31712 4.55801 3.87956 5.12044C4.44199 5.68288 5.20459 5.99921 6 6ZM6 1C6.39556 1 6.78224 1.1173 7.11114 1.33706C7.44004 1.55682 7.69638 1.86918 7.84776 2.23463C7.99914 2.60009 8.03874 3.00222 7.96157 3.39018C7.8844 3.77814 7.69392 4.13451 7.41421 4.41422C7.13451 4.69392 6.77814 4.8844 6.39018 4.96157C6.00222 5.03874 5.60009 4.99914 5.23463 4.84776C4.86918 4.69639 4.55682 4.44004 4.33706 4.11114C4.1173 3.78224 4 3.39556 4 3C4 2.46957 4.21071 1.96086 4.58579 1.58579C4.96086 1.21072 5.46957 1 6 1Z'
          fill='white'
        />
        <Path
          d='M6 7C4.80693 7.00132 3.66311 7.47585 2.81948 8.31948C1.97585 9.16311 1.50132 10.3069 1.5 11.5C1.5 11.6326 1.55268 11.7598 1.64645 11.8536C1.74021 11.9473 1.86739 12 2 12C2.13261 12 2.25979 11.9473 2.35355 11.8536C2.44732 11.7598 2.5 11.6326 2.5 11.5C2.5 10.5717 2.86875 9.6815 3.52513 9.02513C4.1815 8.36875 5.07174 8 6 8C6.92826 8 7.8185 8.36875 8.47487 9.02513C9.13125 9.6815 9.5 10.5717 9.5 11.5C9.5 11.6326 9.55268 11.7598 9.64645 11.8536C9.74021 11.9473 9.86739 12 10 12C10.1326 12 10.2598 11.9473 10.3536 11.8536C10.4473 11.7598 10.5 11.6326 10.5 11.5C10.4987 10.3069 10.0241 9.16311 9.18052 8.31948C8.33689 7.47585 7.19307 7.00132 6 7Z'
          fill='white'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_2468_1203'>
          <Rect width='12' height='12' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export const CheckboxSvgIcon = ({
  width = 10,
  height = 10,
}: {
  width: number;
  height: number;
}): React.ReactNode => {
  return (
    <Svg width={width} height={height} viewBox='0 0 10 10'>
      <Path
        d='M0.5 0.5 H9.5 V9.5 H0.5 Z'
        stroke='black'
        fill='none'
        strokeWidth='1'
      />
    </Svg>
  );
};
