/* eslint-disable react/no-array-index-key */
import React, { ComponentProps } from 'react';

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { format } from 'date-fns';

import { globalSingleton } from '~/singletons/globalSingleton';
import { getImagePublicUrl } from '~/utils/get-image-public-url';
import { formatNumberAsWords, formatQuantityAndUnit } from '~/utils/units';
import {
  PrescriptionUpsert,
  formatPrescriptionDosage,
} from '~/validators/prescriptionValidators';

import { CheckboxSvgIcon } from '../PdfSvgs';
import registerFonts from '../pdf/fontRegistration';

registerFonts();

const BORDER_WIDTH = 0.75;

const styles = StyleSheet.create({
  boldText: {
    fontWeight: 'bold',
  },
  detailsBox: {
    border: `${BORDER_WIDTH} solid #333`,
    borderBottomWidth: 2,
    borderRadius: 5,
  },
});

function Header() {
  const { currentStore } = globalSingleton;

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        borderBottomWidth: BORDER_WIDTH,
        borderBottomColor: 'black',
        borderBottomStyle: 'solid',
        paddingBottom: 5,
        fontSize: 10,
      }}
    >
      <View>
        <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
          {currentStore?.name}
        </Text>
        <Text>{currentStore?.address?.formatted_address}</Text>
        <Text>{currentStore?.email}</Text>
        <Text>{currentStore?.phone}</Text>
      </View>
      <View>
        <Image
          src={getImagePublicUrl(currentStore?.logo_url)}
          style={{
            borderRadius: '50%',
            width: 40,
            height: 40,
          }}
        />
      </View>
    </View>
  );
}

function DetailBoxTitle({ children }: { children: React.ReactNode }) {
  return (
    <View
      style={{
        borderBottomWidth: BORDER_WIDTH,
        borderBottomColor: 'black',
        textAlign: 'center',
        padding: 2,
      }}
    >
      <Text style={styles.boldText}>{children}</Text>
    </View>
  );
}

function DetailBoxContent({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: ComponentProps<typeof View>['style'];
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'column',
          padding: 10,
          paddingTop: 5,
        },
        ...(style ? (style instanceof Array ? style : [style]) : []),
      ]}
    >
      {children}
    </View>
  );
}

function KVRows({
  keyWidth,
  kv,
}: {
  keyWidth: number;
  kv: { key: string; value: string }[];
}) {
  return (
    <View style={{ flexDirection: 'column' }}>
      {kv.map(({ key, value }, index) => (
        <View
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 5,
          }}
        >
          <Text
            style={{
              color: '#555',
              width: keyWidth,
              textAlign: 'right',
            }}
          >
            {key}:
          </Text>
          <Text
            style={{
              flexWrap: 'wrap',
              flexGrow: 1,
              flexShrink: 1,
              width: '100%',
            }}
          >
            <Text>{value}</Text>
          </Text>
        </View>
      ))}
    </View>
  );
}

export default function WrittenPrescriptionPDF({
  prescription,
  pet,
  ownerOrKeeper,
  prescriber,
}: {
  prescription: PrescriptionUpsert;
  pet: {
    name: string;
    species: string;
    breed?: string;
  };
  ownerOrKeeper: {
    name: string;
    address?: Nullish<string>;
    phone?: Nullish<string>;
    email?: Nullish<string>;
  };
  prescriber: {
    name: string;
    rcvs_number: Nullish<string>;
  };
}) {
  if (prescription.status === 'cancelled') {
    console.error(
      'Attempted to render a prescription letter for a cancelled prescription. Id: ',
      prescription.id,
    );
    return null;
  }
  const { currentStore } = globalSingleton;

  return (
    <Document title='Prescription'>
      <Page
        size='A4'
        style={{
          flexDirection: 'column',
          gap: 10,
          padding: 30,
          fontFamily: 'Poppins',
          fontSize: 10,
          marginBottom: 5,
        }}
      >
        <Header />

        <Text
          style={{
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Veterinary Prescription
        </Text>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'stretch',
            gap: 10,
          }}
        >
          <View style={[styles.detailsBox, { flex: 1 }]}>
            <DetailBoxTitle>Animal</DetailBoxTitle>
            <DetailBoxContent>
              <KVRows
                keyWidth={60}
                kv={[
                  { key: 'Name', value: pet.name },
                  { key: 'Species', value: pet.species },
                  ...(pet.breed != null
                    ? [{ key: 'Breed', value: pet.breed }]
                    : []),
                ]}
              />
            </DetailBoxContent>
          </View>
          <View style={[styles.detailsBox, { flex: 1 }]}>
            <DetailBoxTitle>Owner/Keeper</DetailBoxTitle>
            <DetailBoxContent>
              <KVRows
                keyWidth={60}
                kv={[
                  { key: 'Name', value: ownerOrKeeper.name },
                  ...(ownerOrKeeper.address != null
                    ? [
                        {
                          key: 'Address',
                          value: ownerOrKeeper.address,
                        },
                      ]
                    : []),
                  ...(ownerOrKeeper.phone != null
                    ? [{ key: 'Phone', value: ownerOrKeeper.phone }]
                    : []),
                  ...(ownerOrKeeper.email != null
                    ? [{ key: 'Email', value: ownerOrKeeper.email }]
                    : []),
                ]}
              />
            </DetailBoxContent>
          </View>
        </View>

        <View
          style={[
            styles.detailsBox,
            { flexDirection: 'column', gap: 10, flexGrow: 1 },
          ]}
        >
          <DetailBoxTitle>Prescription Details</DetailBoxTitle>
          <DetailBoxContent style={{ gap: 10 }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
              }}
            >
              <View style={{ flexDirection: 'column', gap: 5 }}>
                <View style={{ flexDirection: 'column' }}>
                  <Text>Product</Text>
                  <Text style={styles.boldText}>
                    {prescription.product_name}
                  </Text>
                </View>
                <View style={{ flexDirection: 'column' }}>
                  <Text>Quantity</Text>
                  <Text style={styles.boldText}>
                    {formatQuantityAndUnit({
                      quantity: prescription.quantity,
                      unit: prescription.unit,
                    })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'column' }}>
                  <Text>Permitted Repeats</Text>
                  <Text style={styles.boldText}>
                    {prescription.refill_limit > 0
                      ? `${formatNumberAsWords(prescription.refill_limit, {
                          includeNumericToo: true,
                        })} only`
                      : 'None'}
                    {prescription.refills_permitted_until != null
                      ? `. Permitted until ${format(
                          new Date(prescription.refills_permitted_until),
                          'dd/MM/yyyy',
                        )}`
                      : ''}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column' }}>
                <Text>Instructions</Text>
                <Text style={styles.boldText}>
                  {formatPrescriptionDosage({ prescription })}
                </Text>
              </View>
            </View>
            <View
              style={{
                border: `${BORDER_WIDTH} solid #999`,
                padding: 10,
                borderRadius: 5,
              }}
            >
              <Text>
                For animal treatment only. Keep out of reach of children.
              </Text>
            </View>
            <View
              style={{
                border: `${BORDER_WIDTH} solid #999`,
                padding: 10,
                borderRadius: 5,
                flexDirection: 'column',
                gap: 5,
              }}
            >
              <Text>Tick as appropriate:</Text>
              <View
                style={{ flexDirection: 'row', gap: 3, alignItems: 'center' }}
              >
                <CheckboxSvgIcon width={8} height={8} />
                <Text>
                  This prescription relates to a product prescribed under the
                  cascade.
                </Text>
              </View>
              <View
                style={{ flexDirection: 'row', gap: 3, alignItems: 'center' }}
              >
                <CheckboxSvgIcon width={8} height={8} />
                <Text>
                  This prescription relates to an antibiotic prescribed for
                  prophylactic or metaphylactic purposes.
                </Text>
              </View>
            </View>
          </DetailBoxContent>
          <View style={{ flexGrow: 1 }} />
          <DetailBoxContent
            style={{
              borderTopWidth: BORDER_WIDTH,
              borderTopColor: '#000',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '48%' }}>
              <Text>Prescriber: {prescriber.name}</Text>
              {prescriber.rcvs_number != null && (
                <Text>RCVS number {prescriber.rcvs_number}</Text>
              )}
              <Text style={{ fontSize: 8, color: '#666' }}>
                {currentStore?.name}, {currentStore?.address?.formatted_address}
                , {currentStore?.phone},
              </Text>
              <Text
                style={{
                  marginTop: 5,
                }}
              >
                This prescription is for an animal under my care.
              </Text>
            </View>
            <View style={{ width: '48%' }}>
              <Text style={styles.boldText}>Prescriber's Signature</Text>
              <View
                style={{
                  borderBottomWidth: BORDER_WIDTH,
                  borderBottomColor: '#000',
                  marginTop: '0.75cm',
                  marginBottom: 5,
                }}
              />
              <View style={{ flexDirection: 'row', marginTop: 5 }}>
                <Text>Issued on: </Text>
              </View>
            </View>
          </DetailBoxContent>
        </View>

        <Text style={[{ fontStyle: 'italic', textAlign: 'center' }]}>
          It is an offence under the Veterinary Medicines Regulations 2013 for a
          person to alter a written prescription unless authorised to do so by
          the person who signed it.
        </Text>
        <Text style={[{ textAlign: 'center', color: '#666' }]}>
          Powered by <Text style={[{ fontWeight: 'bold' }]}>Lupa</Text>
        </Text>
      </Page>
    </Document>
  );
}
