import axios from 'axios';

import { globalSingleton } from '../singletons/globalSingleton';
import { API_ENDPOINT } from '../utils/networkUtils';

const api = axios.create({
  baseURL: API_ENDPOINT,
});

api.interceptors.request.use(
  (config) => {
    const token = globalSingleton.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      // const refreshToken = localStorage.getItem('refreshToken'); // Or wherever you store your refresh token
      // // Implement your token refresh logic here. For example:
      // try {
      //   const response = await axios.post('http://yourapi.com/auth/refresh', { refreshToken });
      //   const { accessToken } = response.data;
      //   localStorage.setItem('accessToken', accessToken); // Update the token in storage
      //   api.defaults.headers['Authorization'] = `Bearer ${accessToken}`; // Update the token in Axios instance
      //   return api(originalRequest); // Retry the original request with the new token
      // } catch (refreshError) {
      //   // Handle token refresh error (e.g., logout the user, redirect to login)
      //   return Promise.reject(refreshError);
      // }
      console.info('Token expired!', error);
    }
    return Promise.reject(error);
  },
);

export default api;
