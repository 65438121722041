import { differenceInMonths, differenceInYears } from 'date-fns';

export function calculateAge(
  dob: Nullish<string>,
  isSimpleFormat = false,
): string {
  if (dob == null) {
    return 'N/A';
  }

  const birthDate = new Date(dob);
  const now = new Date();
  const years = differenceInYears(now, birthDate);
  const months = differenceInMonths(now, birthDate) % 12;

  let formattedAge = '';

  if (years > 0) {
    formattedAge += `${years} year${years > 1 ? 's' : ''}`;

    if (isSimpleFormat) {
      return formattedAge;
    }
  }

  if (isSimpleFormat) {
    return `${months} month${months > 1 ? 's' : ''}`;
  }

  if (months > 0 && years < 5) {
    if (years > 0) {
      formattedAge += `, `;
    }
    formattedAge += `${months} month${months > 1 ? 's' : ''}`;
  }

  return formattedAge || '0 months';
}
