import ToggleButton, {
  ToggleButtonProps,
  toggleButtonClasses,
} from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme, exclusive }) => ({
    [`&.${toggleButtonGroupClasses.root}`]: {
      border: '1px solid',
      borderRadius: 32,
      borderColor: theme.palette.grey[300],
      overflow: 'hidden',
      padding: '0px !important',
    },
    [`&.${toggleButtonGroupClasses.grouped}`]: {
      padding: 0,
    },
    ...(exclusive && {
      [`& .${toggleButtonClasses.root}`]: {
        borderRadius: 32,
        borderWidth: 0,
      },
    }),

    ...(!exclusive && {
      [`& .${toggleButtonGroupClasses.firstButton},& .${toggleButtonGroupClasses.middleButton}`]:
        {
          borderRight: `1px solid ${theme.palette.grey[300]} !important`,

          [`&${toggleButtonGroupClasses.disabled}`]: {
            borderRight: `1px solid ${theme.palette.action.disabled} !important`,
          },
        },
    }),
  }),
);

interface StyledToggleButtonProps extends ToggleButtonProps {
  width?: number | string;
}

export const StyledToggleButton = styled(ToggleButton)<StyledToggleButtonProps>(
  ({ width }) => ({
    [`&.${toggleButtonClasses.root}`]: {
      ...(width != null
        ? {
            width,
            minWidth: width,
            padding: '4px 0px',
          }
        : {
            padding: '4px 12px',
          }),
      textTransform: 'none',
      fontSize: 13,
      border: 'none',
    },
  }),
);
