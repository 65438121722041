import React from 'react';

import { AuthGuard } from 'lupa-shared-ui/src/guards/AuthGuard';

import { paths } from '../paths';

export const withAuthGuard = (Component: React.ComponentType<any>) => {
  const WithAuthGuard: React.FC<any> = (props) => {
    return (
      <AuthGuard loginPagePath={paths.auth.login}>
        <Component {...props} />
      </AuthGuard>
    );
  };
  return WithAuthGuard;
};
