import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { IconBulb } from '@tabler/icons-react';

type TipRootProps = {
  theme?: any;
};

const TipRoot = styled('div')<TipRootProps>(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.neutral[800]
      : theme.palette.neutral[100],
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  padding: theme.spacing(1),
}));

type TipProps = {
  message: string;
};

export const Tip = ({ message }: TipProps) => {
  return (
    <TipRoot>
      <SvgIcon color='primary' sx={{ mr: 1 }}>
        <IconBulb />
      </SvgIcon>
      <Typography
        color='text.secondary'
        sx={{
          alignItems: 'center',
          display: 'flex',
          '& span': {
            fontWeight: 700,
            mr: 0.5,
          },
        }}
        variant='caption'
      >
        <span>Tip.</span> {message}
      </Typography>
    </TipRoot>
  );
};
