import React, { useRef, useState } from 'react';

import { UpsertAction } from '#types/common.types';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { IconX } from '@tabler/icons-react';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { FormikProps } from 'formik';
import toast from 'react-hot-toast';

import { AppointmentUpdateForm } from '~/components/appointments/upsert/AppointmentUpdate';
import { AutocompleteSearch } from '~/components/ui/AutocompleteSearch';
import TelephoneField from '~/components/ui/TelephoneField';
import { TrpcRouterOutputs, trpc } from '~/lib/trpc';
import { isCreateAppointmentFormik } from '~/utils/appointment-utils';
import { getFieldError, getFieldHelperText } from '~/utils/formik-utils';
import useGetComponentWidth from '~/utils/use-get-component-width';

import { useDialog } from '../../../hooks/use-dialog';
import { useRouter } from '../../../hooks/use-router';
import { paths } from '../../../paths';
import { getEmployeeRoleLabel } from '../../../utils/get-labels';
import PetAndClientDetails from '../../pets/PetAndClientDetails';
import DialogSection from '../../ui/DialogSection';
import { AppointmentCreateForm } from './AppointmentCreate';
import AppointmentUpsertCreatePet from './AppointmentUpsertCreatePet';

type AppointmentUpsertDetailsCardProps = {
  formik:
    | FormikProps<AppointmentUpdateForm>
    | FormikProps<AppointmentCreateForm>;
  action: UpsertAction;
  handleClose?: () => void;
  showCreateClient: boolean;
  setShowCreateClient: (show: boolean) => void;
  setAutofilledAppointmentId?: (id: string) => void;
};

export default function AppointmentUpsertDetailsCard({
  formik,
  action,
  handleClose,
  showCreateClient,
  setShowCreateClient,
  setAutofilledAppointmentId,
}: AppointmentUpsertDetailsCardProps) {
  const [isAutoFilling, setIsAutoFilling] = useState(false);
  const theme = useTheme();
  const router = useRouter();
  const detailsDialog = useDialog();
  const trpcUtils = trpc.useUtils();

  const containerRef = useRef(null);
  const { isWidthXs } = useGetComponentWidth(containerRef);

  return (
    <Box ref={containerRef}>
      <Card
        style={{
          width: '100%',
        }}
      >
        <CardContent style={{ padding: 16 }}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <AutocompleteSearch<{
                ItemType: TrpcRouterOutputs['pets']['searchPets'][0];
              }>
                trpcFunction={trpc.pets.searchPets}
                label='Search a Pet'
                getOptionLabel={(option) =>
                  `${option.name} • ${option.owner_full_name}`
                }
                value={formik.values.pet}
                onSelect={(pet) => {
                  formik.setFieldValue('newClient', null);
                  formik.setFieldValue('newPet', null);

                  if (pet == null) {
                    formik.setFieldValue('pet', null);
                    formik.setFieldValue('client', null);
                    return;
                  }

                  formik.setFieldValue('pet', pet);
                  formik.setFieldValue('client', pet.owner[0].client);
                }}
                disabled={showCreateClient || action === 'update'}
                handleExtraOptionOnClick={() => {
                  formik.setFieldValue('client', null);
                  formik.setFieldValue('newClient', {});
                  formik.setFieldValue('pet', null);
                  formik.setFieldValue('newPet', {});
                  setShowCreateClient(true);
                }}
                extraOptionText='Create Client'
                formik={formik}
                data-testid='appointment-input-pet'
              />
            </Grid>

            {formik.values.pet?.id != null &&
              formik.values?.client?.id != null && (
                <Grid xs={12} mb={-1.5} mt={1.5}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Button
                      variant='text'
                      onClick={() => {
                        detailsDialog.handleOpen({
                          petId: formik.values.pet!.id,
                          clientId: formik.values.client!.id,
                        });
                      }}
                      size='small'
                      sx={{ px: 1, py: 0.5, mt: -3 }}
                    >
                      See pet & client details
                    </Button>

                    {action === 'create' ? (
                      <LoadingButton
                        loading={isAutoFilling}
                        variant='text'
                        onClick={async () => {
                          setIsAutoFilling(true);
                          const fetchedMostRecentAppointment =
                            await trpcUtils.pets.getMostRecentAppointment.fetch(
                              {
                                petId: formik.values.pet!.id,
                              },
                            );

                          setIsAutoFilling(false);

                          if (!fetchedMostRecentAppointment) {
                            toast.error('No recent appointments found for pet');
                            return;
                          }

                          const visitType =
                            fetchedMostRecentAppointment.visit_type;
                          const recentAppointmentDuration = Math.abs(
                            differenceInMinutes(
                              new Date(fetchedMostRecentAppointment.start),
                              new Date(fetchedMostRecentAppointment.end),
                            ),
                          );

                          formik.setFieldValue('appointment', {
                            ...formik.values.appointment,
                            visit_type: visitType,
                            price: visitType.price,
                            title: visitType.name,

                            end: addMinutes(
                              new Date(formik.values.appointment.start),
                              recentAppointmentDuration,
                            ).toISOString(),
                          });

                          formik.setFieldValue(
                            'employee',
                            fetchedMostRecentAppointment.main_employee[0]
                              .employee_details,
                          );

                          formik.setFieldValue(
                            'apply_discount',
                            fetchedMostRecentAppointment.apply_discount,
                          );

                          formik.setFieldValue(
                            'discount',
                            fetchedMostRecentAppointment.discount,
                          );

                          formik.setFieldValue(
                            'discount_type',
                            fetchedMostRecentAppointment.discount_type,
                          );

                          setAutofilledAppointmentId?.(
                            fetchedMostRecentAppointment.id,
                          );
                        }}
                        size='small'
                        sx={{ px: 1, py: 0.5, mt: -3 }}
                      >
                        Autofill
                      </LoadingButton>
                    ) : (
                      <div />
                    )}
                  </Stack>
                </Grid>
              )}

            {showCreateClient && (
              <Grid xs={12}>
                <Card sx={{ bgcolor: theme.palette.grey[100] }}>
                  <CardHeader
                    action={
                      <IconButton
                        onClick={() => {
                          formik.setFieldValue('client', {});
                          formik.setFieldValue('newClient', null);
                          formik.setFieldValue('pet', null);
                          formik.setFieldValue('appointment.newPet', null);
                          setShowCreateClient(false);
                        }}
                      >
                        <IconX />
                      </IconButton>
                    }
                    title='Create Client'
                    sx={{ pt: 2 }}
                  />

                  {isCreateAppointmentFormik(formik) && (
                    <CardContent sx={{ pt: 0 }}>
                      <Grid container spacing={2} direction='row'>
                        <Grid xs={12} md={isWidthXs ? 12 : 6}>
                          <TextField
                            fullWidth
                            variant='outlined'
                            label='First Name'
                            name='newClient.first_name'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.newClient?.first_name ?? ''}
                            error={getFieldError(
                              formik,
                              'newClient.first_name',
                            )}
                            helperText={getFieldHelperText(
                              formik,
                              'newClient.first_name',
                            )}
                          />
                        </Grid>
                        <Grid xs={12} md={isWidthXs ? 12 : 6}>
                          <TextField
                            fullWidth
                            variant='outlined'
                            label='Last Name'
                            name='newClient.last_name'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.newClient?.last_name ?? ''}
                            error={getFieldError(formik, 'newClient.last_name')}
                            helperText={getFieldHelperText(
                              formik,
                              'newClient.last_name',
                            )}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            fullWidth
                            variant='outlined'
                            label='Email (Optional)'
                            name='newClient.email'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.newClient?.email ?? ''}
                            error={getFieldError(formik, 'newClient.email')}
                            helperText={getFieldHelperText(
                              formik,
                              'newClient.email',
                            )}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <TelephoneField
                            name='newClient.phone'
                            value={formik.values.newClient?.phone}
                            onChange={(value) => {
                              formik.setFieldValue('newClient.phone', value);
                            }}
                            onBlur={formik.handleBlur}
                            error={getFieldError(formik, 'newClient.phone')}
                            helperText={getFieldHelperText(
                              formik,
                              'newClient.phone',
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  )}
                </Card>
              </Grid>
            )}

            {showCreateClient && isCreateAppointmentFormik(formik) && (
              <Grid xs={12}>
                <Card sx={{ bgcolor: theme.palette.grey[100] }}>
                  <CardHeader title='Create Pet' sx={{ pt: 2 }} />
                  <CardContent sx={{ pt: 0 }}>
                    <AppointmentUpsertCreatePet
                      formik={formik}
                      isWidthXs={isWidthXs}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid md={isWidthXs ? 12 : 6} xs={12}>
              <AutocompleteSearch<{
                ItemType: TrpcRouterOutputs['store']['searchVisitTypes'][0];
              }>
                trpcFunction={trpc.store.searchVisitTypes}
                label='Appointment Type'
                getOptionLabel={(option) => option.name}
                value={formik.values.appointment.visit_type}
                onSelect={(item) => {
                  if (item == null) {
                    formik.setFieldValue('totalPrice', 0);
                    formik.setFieldValue('appointment', {
                      ...formik.values.appointment,
                      visit_type: null,
                      title: null,
                      services: [],
                      products: [],
                    });

                    return;
                  }

                  formik.setFieldValue('totalPrice', item.price);
                  formik.setFieldValue('appointment', {
                    ...formik.values.appointment,
                    visit_type: item,
                    title: item.name,
                    end: addMinutes(
                      new Date(formik.values.appointment.start),
                      item.duration,
                    ).toISOString(),
                  });
                }}
                name='appointment.visit_type.id'
                formik={formik}
                sx={{ flexGrow: 1 }}
                data-testid='appointment-input-visit-type'
              />
            </Grid>

            <Grid md={isWidthXs ? 12 : 6} xs={12}>
              <AutocompleteSearch<{
                ItemType: TrpcRouterOutputs['employees']['searchEmployees'][0];
              }>
                trpcFunction={trpc.employees.searchEmployees}
                label='Employee'
                getOptionLabel={(option) =>
                  option.role
                    ? `${option.full_name} • ${getEmployeeRoleLabel(option.role)}`
                    : option.full_name
                }
                value={formik.values.employee}
                onSelect={(employee) => {
                  formik.setFieldValue('employee', employee);
                }}
                name='employee'
                formik={formik}
              />
            </Grid>

            <Grid md={isWidthXs ? 12 : 6} xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                label='Title'
                InputLabelProps={{
                  shrink: !!formik.values.appointment.title, // This will ensure the label shrinks if there is a value
                }}
                value={formik.values.appointment.title ?? ''}
                onChange={(e) => {
                  formik.setFieldValue(`appointment.title`, e.target.value);
                }}
              />
            </Grid>

            <Grid md={isWidthXs ? 12 : 6} xs={12}>
              <TextField
                fullWidth
                label='Location (optional)'
                value={formik.values.appointment.location ?? ''}
                onChange={(e) => {
                  formik.setFieldValue(`appointment.location`, e.target.value);
                }}
                data-testid='appointment-input-location'
              />
            </Grid>

            <Grid md={isWidthXs ? 12 : 6} xs={12}>
              <DateTimePicker
                label='Start time'
                onChange={(value) => {
                  if (!value) {
                    formik.setFieldValue('appointment.start', null);
                    formik.setFieldValue('appointment.end', null);
                    return;
                  }

                  const duration = Math.abs(
                    differenceInMinutes(
                      new Date(formik.values.appointment.start),
                      new Date(formik.values.appointment.end),
                    ),
                  );

                  const newEnd = addMinutes(new Date(value), duration);

                  formik.setFieldValue(
                    'appointment.start',
                    value.toISOString(),
                  );
                  formik.setFieldValue('appointment.end', newEnd.toISOString());
                }}
                value={new Date(formik.values.appointment.start)}
                sx={{ width: '100%' }}
              />
            </Grid>

            <Grid md={isWidthXs ? 12 : 6} xs={12}>
              <DateTimePicker
                label='End time'
                onChange={(value) => {
                  formik.setFieldValue('appointment.end', value?.toISOString());
                }}
                value={new Date(formik.values.appointment.end)}
                sx={{ width: '100%' }}
                minDateTime={new Date(formik.values.appointment.start)}
              />
            </Grid>

            <Grid xs={12}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Stack direction='row' alignItems='center' gap={1}>
                  {action === 'update' && (
                    <Button
                      color='primary'
                      variant='outlined'
                      onClick={() => {
                        handleClose?.();
                        router.push(
                          paths.appointments.details(
                            formik.values.appointment.id!,
                          ),
                        );
                      }}
                    >
                      View Appointment File
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {detailsDialog.open && (
        <DialogSection
          open={detailsDialog.open}
          onClose={detailsDialog.handleClose}
          title='Client & Pet Details'
          maxWidth='xl'
        >
          <Stack direction='column' spacing={2}>
            <PetAndClientDetails
              petId={detailsDialog.data.petId}
              clientId={detailsDialog.data.clientId}
            />
          </Stack>
        </DialogSection>
      )}
    </Box>
  );
}
