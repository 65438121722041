export enum CALENDAR_VIEW_TYPE {
  TIME_GRID_WORK_WEEK = 'timeGridWorkWeek',
  TIME_GRID_WEEK = 'timeGridWeek',
  TIME_GRID_DAY = 'timeGridDay',
  RESOURCE_TIME_GRID_DAY = 'resourceTimeGridDay',
  RESOURCE_TIME_GRID_WEEK = 'resourceTimeGridWeek',
  RESOURCE_TIMELINE_WEEK = 'resourceTimelineWeek',
  RESOURCE_TIME_GRID_THREE_DAYS = 'resourceTimeGridThreeDays',
  TIME_GRID_THREE_DAYS = 'timeGridThreeDays',
}

export enum CALENDAR_MODE_TYPE {
  MERGED = 'merged',
  SPLIT = 'split',
}

export enum CALENDAR_TIME_TYPE {
  DAY = 'day',
  WEEK = 'week',
  THREE_DAYS = 'threeDays',
  TIMELINE = 'timeline',
}

export enum CALENDAR_COLOUR_TYPE {
  EMPLOYEE = 'employee',
  CATEGORY = 'category',
}
