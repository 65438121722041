import React, { useState } from 'react';

import {
  Alert,
  Autocomplete,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { match } from 'ts-pattern';

import { TrpcRouterOutputs, trpc } from '~/lib/trpc';
import { BillingProductDataType } from '~/validators/appointmentValidators';
import { formatPrescriptionDosage } from '~/validators/prescriptionValidators';

import { getPrescriptionRefillsEnabled } from '../prescriptions/PrescriptionForm';
import PrescriptionUpsert from '../prescriptions/PrescriptionUpsert';
import DividerSection from '../ui/DividerSection';
import Loading from '../ui/Loading';

type AppointmentUpsertPrescriptionDialogProps = {
  product: BillingProductDataType;
  petId: string;
  clientId: string;
  setProduct: (product: BillingProductDataType) => void;
  handleClose: () => void;
};

type AppointmentUpsertPrescriptionDialogState =
  | {
      stage: 'UPSERT_PRESCRIPTION';
      prescriptionId: string | null;
    }
  | {
      stage: 'SELECT_PRESCRIPTION';
      selected: TrpcRouterOutputs['prescriptions']['getForPet'][0] | null;
    };

export default function AppointmentUpsertPrescriptionDialog({
  product,
  petId,
  clientId,
  setProduct,
  handleClose,
}: AppointmentUpsertPrescriptionDialogProps) {
  const onSaved = async ({
    prescriptionId,
    updatesForParentForm,
  }: {
    prescriptionId: string;
    updatesForParentForm?: {
      dispensesIdsToAddToContextBillingProduct: string[];
    };
  }) => {
    const newDispenseIds = new Set([
      ...(product.prescription_dispense_ids ?? []),
      ...(updatesForParentForm?.dispensesIdsToAddToContextBillingProduct ?? []),
    ]);

    setProduct({
      ...product,
      prescription_id: prescriptionId,
      prescription_dispense_ids:
        newDispenseIds.size > 0 ? Array.from(newDispenseIds) : undefined,
    });

    handleClose();
  };

  const [state, setState] = useState<AppointmentUpsertPrescriptionDialogState>(
    !getPrescriptionRefillsEnabled() || product.prescription_id != null
      ? {
          stage: 'UPSERT_PRESCRIPTION',
          prescriptionId: product.prescription_id ?? null,
        }
      : { stage: 'SELECT_PRESCRIPTION', selected: null },
  );

  const prescriptionsQuery = trpc.prescriptions.getForPet.useQuery(
    { petId },
    { enabled: state.stage === 'SELECT_PRESCRIPTION' },
  );

  return match({ state, prescriptionsQuery })
    .with(
      { state: { stage: 'UPSERT_PRESCRIPTION' } },
      ({ state: { prescriptionId } }) => (
        <PrescriptionUpsert
          prescription={
            prescriptionId != null
              ? { type: 'existing', id: prescriptionId }
              : { type: 'new', sourceProduct: product, petId, clientId }
          }
          onSaved={onSaved}
          handleClose={handleClose}
          billingProductContext={{
            id: product.id,
            display_name: product.name,
            prescription_dispense_ids:
              product.prescription_dispense_ids ?? undefined,
          }}
        />
      ),
    )
    .with({ prescriptionsQuery: { isLoading: true } }, () => <Loading />)
    .with({ prescriptionsQuery: { isError: true } }, () => (
      <Alert severity='error'>Error loading prescriptions</Alert>
    ))
    .with(
      {
        state: { stage: 'SELECT_PRESCRIPTION' },
        prescriptionsQuery: { isError: false, isLoading: false },
      },
      ({ state, prescriptionsQuery: { data: petPrescriptions } }) => (
        <Stack direction='column' spacing={2}>
          <Stack direction='column' alignItems='center' width='100%'>
            <Button
              variant='outlined'
              onClick={() => {
                setState({
                  stage: 'UPSERT_PRESCRIPTION',
                  prescriptionId: null,
                });
              }}
            >
              Create new prescription
            </Button>
          </Stack>
          <DividerSection title='or' textAlign='center' />
          <Typography>Refill an existing prescription:</Typography>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Autocomplete
              options={petPrescriptions}
              getOptionLabel={(option) => option.product_name}
              renderOption={(props, option) => (
                <Stack component='li' {...props}>
                  <Typography variant='subtitle2'>
                    {option.product_name}
                  </Typography>

                  <Typography variant='caption' color='text.secondary'>
                    {new Date(option.created_at).toLocaleDateString()} |{' '}
                    {formatPrescriptionDosage({
                      prescription: option,
                    })}
                  </Typography>
                </Stack>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: '400px' }}
                  label='Prescription'
                  variant='outlined'
                />
              )}
              value={state.selected}
              onChange={(_event, newValue) =>
                setState({
                  stage: 'SELECT_PRESCRIPTION',
                  selected: newValue,
                })
              }
            />
            <Button
              disabled={state.selected == null}
              variant='contained'
              onClick={() => {
                if (state.selected == null) {
                  return;
                }

                setState({
                  stage: 'UPSERT_PRESCRIPTION',
                  prescriptionId: state.selected.id,
                });
              }}
            >
              Refill
            </Button>
          </Stack>
        </Stack>
      ),
    )
    .exhaustive();
}
