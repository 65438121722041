import React, { useMemo } from 'react';

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormik } from 'formik';
import {
  LupaFormikAutocompleteField,
  LupaFormikNumberInput,
} from 'lupa-shared-ui';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { toFormikValidate, toFormikValidationSchema } from 'zod-formik-adapter';

import { invalidateAllQueries } from '~/api/mutations';
import { TrpcRouterOutputs, trpc } from '~/lib/trpc';
import { globalSingleton } from '~/singletons/globalSingleton';
import { APPOINTMENT_TYPE } from '~/utils/enums';
import { getFieldError, getFieldHelperText } from '~/utils/formik-utils';
import { getCurrencySymbolFromCode } from '~/utils/i18n';

import {
  getAppointmentCategoriesForStoreCategories,
  getAppointmentCategoryLabel,
} from '../../consts/storeCategories';
import { getIsVetStore } from '../../utils/store-utils';
import { AutocompleteSearch } from '../ui/AutocompleteSearch';

type Service = TrpcRouterOutputs['store']['getServices']['data'][0];

const useGetInitialValues = (service?: Service) => {
  return useMemo(() => {
    return {
      id: service?.id ?? uuidv4(),
      category: service?.category ?? ('' as APPOINTMENT_TYPE),
      name: service?.name ?? '',
      price: service?.price ?? 0,
      venom_code: service?.venom_code ?? null,
    };
  }, [service]);
};

const validationSchema = z.object({
  id: z.string().uuid(),
  category: z.nativeEnum(APPOINTMENT_TYPE, { message: 'Invalid category' }),
  name: z.string().min(1, 'Name is required'),
  price: z.number().min(0, 'Invalid price'),
  venom_code: z.number().nullish(),
});

interface UpsertStoreServiceSettingsModalProps {
  service?: Service;
  onClose: () => void;
}

export default function UpsertStoreServiceSettingsModal({
  service,
  onClose,
}: UpsertStoreServiceSettingsModalProps) {
  const initialValues = useGetInitialValues(service);

  const upsertServiceMutation = trpc.store.upsertStoreService.useMutation({
    onSuccess: () => {
      invalidateAllQueries();
      toast.success('Service updated');
      onClose();
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(validationSchema),
    validate: toFormikValidate(validationSchema),
    onSubmit: async (values) => {
      upsertServiceMutation.mutate({
        data: values,
      });
    },
  });

  const isVetStore = getIsVetStore();

  return (
    <Stack direction='column' spacing={2}>
      <Stack spacing={1} direction='column' alignItems='center'>
        <Stack spacing={1} direction='column'>
          <Grid container spacing={1} px={1} width={{ sx: 'auto', md: 900 }}>
            <Grid xs={12} md={2.5}>
              <LupaFormikAutocompleteField
                name='category'
                formik={formik}
                value={formik.values.category}
                label='Category'
                getOptionLabel={(option) =>
                  getAppointmentCategoryLabel(option.label)
                }
                options={getAppointmentCategoriesForStoreCategories(
                  globalSingleton.currentStore.categories,
                ).map((label) => ({
                  id: label,
                  label,
                }))}
                onChange={(value) => {
                  formik.setFieldValue('category', value?.id ?? null);
                }}
              />
            </Grid>

            <Grid xs={12} md={4}>
              {isVetStore ? (
                <AutocompleteSearch<{
                  ItemType: TrpcRouterOutputs['store']['searchVenomCodes'][0];
                  FreeSolo: true;
                }>
                  trpcFunction={trpc.store.searchVenomCodes}
                  extraTrpcInput={{ venomType: 4 }}
                  label='Name (Select or type)'
                  getOptionLabel={(option) =>
                    typeof option === 'string'
                      ? (option ?? '')
                      : (option.label ?? '')
                  }
                  value={formik.values.name ?? ''}
                  onSelect={(item) => {
                    formik.setFieldValue('name', item?.label ?? null);

                    formik.setFieldValue(
                      'venom_code',
                      item?.venom_code ?? null,
                    );
                  }}
                  freeSolo
                  freeSoloOnTextChange={(text) => {
                    formik.setFieldValue('name', text);
                    formik.setFieldValue('venom_code', null);
                  }}
                  name='name'
                  formik={formik}
                />
              ) : (
                <TextField
                  fullWidth
                  name='name'
                  label='Name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={getFieldError(formik, 'name')}
                  helperText={getFieldHelperText(formik, 'name')}
                />
              )}
            </Grid>

            {isVetStore && (
              <Grid xs={12} md={3}>
                <LupaFormikNumberInput
                  name='venom_code'
                  formik={formik}
                  value={formik.values.venom_code}
                  label='Venom Code (Optional)'
                  max={Number.MAX_SAFE_INTEGER}
                />
              </Grid>
            )}

            <Grid xs={12} md={2.5}>
              <LupaFormikNumberInput
                name='price'
                formik={formik}
                value={formik.values.price}
                label='Price (incl. VAT)'
                isInt={false}
                startIcon={getCurrencySymbolFromCode(
                  globalSingleton.currentStore.currency_code,
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>

      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        pt={2}
        spacing={2}
      >
        <LoadingButton
          variant='contained'
          color='primary'
          onClick={() => {
            formik.handleSubmit();
          }}
          disabled={!formik.dirty}
          loading={upsertServiceMutation.isLoading}
        >
          Save
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
