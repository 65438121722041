import * as React from 'react';

import { ContentEditable } from '@lexical/react/LexicalContentEditable';

import './ContentEditable.css';

type Props = {
  className?: string;
  placeholder: string;
};

export default function LexicalContentEditable({
  className,
  placeholder,
}: Props): JSX.Element {
  return (
    <ContentEditable
      className={className ?? 'ContentEditable__root'}
      aria-placeholder={placeholder}
      placeholder={placeholder}
    />
  );
}
