import React, { useEffect, useMemo } from 'react';

import {
  DateSelectArg,
  DayCellMountArg,
  EventClickArg,
  EventDropArg,
} from '@fullcalendar/core';
import enLocale from '@fullcalendar/core/locales/en-gb';
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import scrollPlugin from '@fullcalendar/scrollgrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import { Box, Link, Stack, Tooltip, Typography } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { IconPaw, IconUserShield } from '@tabler/icons-react';
import { format, parse } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { globalSingleton } from '~/singletons/globalSingleton';
import { getMinMaxOpeningHours } from '~/utils/calendar-utils';
import { EMPLOYEES_WORKING_HOURS_STATUS, EMPLOYEE_ROLE } from '~/utils/enums';
import {
  getAppointmentPaymentStatusLabel,
  getAppointmentStatusLabel,
} from '~/utils/get-labels';
import { CALENDAR_VIEW_TYPE } from '~/utils/local-enums';

import { useCalendar } from '../../hooks/use-calendar';
import { paths } from '../../paths';
import { getImagePublicUrl } from '../../utils/get-image-public-url';
import {
  dayNameToNumber,
  getEmployeeExceptionOnDay,
  getEmployeeNonWorkingIntervals,
} from '../../utils/rota-utils';
import { RouterLink } from '../RouterLink';
import FallbackAvatar from '../ui/FallbackAvatar';
import Loading from '../ui/Loading';
import { CalendarContainer } from './CalendarContainer';
import { CalendarToolbar } from './CalendarToolbar';

const displayName = (fullName: Nullish<string>) => {
  if (!fullName) {
    return '';
  }

  const [firstName, lastName] = fullName.split(' ');

  return `${firstName} ${lastName?.[0]}.`;
};

export enum CALENDAR_EVENT_TYPE {
  BLOCK = 'block',
  APPOINTMENT = 'appointment',
  NON_WORKING_HOURS = 'non-working-hours',
}

type Event = {
  id: string;
  title: string;
  start: string;
  end: string;
  type: CALENDAR_EVENT_TYPE;
  status?: string;
  payment_status?: string;
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
  editable?: boolean;
  employees?: {
    id: string;
    full_name: string;
    role?: Nullable<string>;
    avatar_url?: Nullable<string>;
    working_hours?: any;
  }[];
  pet?: {
    name: string;
  };
  client?: {
    last_name: Nullable<string>;
  };
  resourceIds?: string[];
  petName?: string;
  clientLastName?: string;
};

type Employees = Record<
  string,
  {
    id: string;
    full_name: string;
    role: EMPLOYEE_ROLE;
    avatar_url: Nullable<string>;
    working_hours: any;
  }
>;

type CalendarViewProps = {
  initialDate: Date;
  date: Date;
  setDate: (date: Date) => void;
  view: CALENDAR_VIEW_TYPE;
  setView: (view: CALENDAR_VIEW_TYPE) => void;
  setDateRange: (dateRange: { startDate: Date; endDate: Date }) => void;
  height: number | string;
  events: Event[];
  select: (event: DateSelectArg) => void;
  eventResize: (event: EventResizeDoneArg) => void;
  eventDrop: (event: EventDropArg) => void;
  handleEventSelect?: (event: EventClickArg) => void;
  isDisplayed?: boolean;
  isLoading?: boolean;
  isThreeDaysView?: boolean;
  calendarRef: any;
  selectedEmployees: any[];
  setSelectedEmployees: (employees: any[]) => void;
  employees: Employees;
  shouldHideDays?: boolean;
  interfaceType?: 'default' | 'appointment';
};

const CalendarView = ({
  initialDate,
  date,
  setDate,
  view,
  setView,
  setDateRange,
  height,
  events,
  select,
  eventResize,
  eventDrop,
  handleEventSelect,
  isDisplayed = true,
  isLoading = false,
  isThreeDaysView = false,
  calendarRef,
  selectedEmployees,
  setSelectedEmployees,
  employees,
  shouldHideDays = true,
  interfaceType = 'default', // where calender is rendered from - ['default', 'appointment']
}: CalendarViewProps) => {
  const {
    handleViewChange,
    handleDateToday,
    handleDatePrev,
    handleDateNext,
    handleDateChange,
  } = useCalendar(calendarRef, setDate, setView);
  const { currentStore } = globalSingleton;

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const { currentStart, currentEnd } = calendarApi.view;
      setDateRange({
        startDate: new Date(currentStart),
        endDate: new Date(currentEnd),
      });
    }
  }, [view, date]);

  const isMergedView =
    view === CALENDAR_VIEW_TYPE.TIME_GRID_DAY ||
    view === CALENDAR_VIEW_TYPE.TIME_GRID_WEEK ||
    view === CALENDAR_VIEW_TYPE.TIME_GRID_THREE_DAYS ||
    view === CALENDAR_VIEW_TYPE.TIME_GRID_WORK_WEEK;

  const minMaxOpeningTimes = useMemo(() => {
    return getMinMaxOpeningHours(currentStore.hours.openingTimes);
  }, [currentStore]);

  const holidaysDatesFormatted = useMemo(() => {
    return currentStore.hours.holidays.map((holiday) => holiday.date);
  }, [currentStore.hours.holidays]);

  const hiddenDays = useMemo(() => {
    if (!shouldHideDays) {
      return [];
    }

    const closedDaysStrings = currentStore.hours.openingTimes.filter(
      (day) => day.times.length === 0,
    );

    return closedDaysStrings.map((item) => dayNameToNumber(item.day));
  }, [shouldHideDays, currentStore.hours.openingTimes]);

  const employeesSpecialHours: Event[] = useMemo(() => {
    if (!calendarRef.current || isMergedView) {
      return [];
    }

    const calendarApi = calendarRef.current.getApi();
    const { currentStart, currentEnd } = calendarApi.view;

    const dates: Date[] = [];
    const currentDate = new Date(currentStart);
    while (currentDate <= currentEnd) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const employeesEvents = [];

    for (const employee of Object.values(employees)) {
      for (const currentDate of dates) {
        const exception = getEmployeeExceptionOnDay(employee, currentDate);

        if (
          exception == null ||
          exception.status !== EMPLOYEES_WORKING_HOURS_STATUS.AVAILABLE
        ) {
          continue;
        }

        const companyTimesOnDay = currentStore.hours.openingTimes.find(
          (time) => time.day === format(currentDate, 'EEEE'),
        )?.times;

        if (!companyTimesOnDay?.length || !exception.intervals?.length) {
          continue;
        }

        const employeeNonWorkingIntervals = getEmployeeNonWorkingIntervals(
          companyTimesOnDay,
          exception.intervals,
        );

        employeesEvents.push(
          employeeNonWorkingIntervals.map((interval) => {
            const startTime = parse(
              interval.open,
              'HH:mm',
              new Date(currentDate),
            );
            const endTime = parse(
              interval.close,
              'HH:mm',
              new Date(currentDate),
            );

            return {
              id: uuidv4(),
              resourceId: employee.id,
              title: 'Non-working Hours',
              start: startTime.toISOString(),
              end: endTime.toISOString(),
              display: 'background',
              type: CALENDAR_EVENT_TYPE.NON_WORKING_HOURS,
            };
          }),
        );
      }
    }

    return employeesEvents.flat();
  }, [employees, date, isMergedView, currentStore.hours.openingTimes]);

  const handleDayCellDidMount = (info: DayCellMountArg) => {
    const { date, resource } = info;

    if (holidaysDatesFormatted.includes(format(date, 'dd-MM-yyyy'))) {
      info.el.style.backgroundImage =
        'repeating-linear-gradient(45deg, #f2f2f2, #f2f2f2 10px, #fff 10px, #fff 20px)';
      return;
    }

    if (resource && resource.extendedProps) {
      const exception = getEmployeeExceptionOnDay(resource.extendedProps, date);

      if (!exception || exception.status === 'available') {
        return;
      }

      // Apply striped background for the cell
      info.el.style.backgroundImage =
        'repeating-linear-gradient(45deg, #f2f2f2, #f2f2f2 10px, #fff 10px, #fff 20px)';
    }
  };

  return (
    <Stack spacing={2}>
      <CalendarToolbar
        date={date}
        isThreeDaysView={isThreeDaysView}
        onDateNext={handleDateNext}
        onDatePrev={handleDatePrev}
        onDateToday={handleDateToday}
        onViewChange={handleViewChange}
        onDateChange={handleDateChange}
        view={view}
        selectedEmployees={selectedEmployees}
        setSelectedEmployees={setSelectedEmployees}
      />
      <CalendarContainer
        style={{
          ...(!isDisplayed
            ? { display: 'none' }
            : {
                position: 'relative',
              }),
          ...(interfaceType === 'appointment'
            ? {
                backgroundColor: '#F7F8F9',
                borderRadius: '24px',
                overflow: 'hidden',
              }
            : {}),
        }}
      >
        <FullCalendar
          allDaySlot={false}
          datesAboveResources
          dayCellDidMount={handleDayCellDidMount}
          dayMaxEvents
          dayMinWidth={view === CALENDAR_VIEW_TYPE.TIME_GRID_WEEK ? 0 : 120}
          defaultTimedEventDuration='00:05:00'
          droppable
          editable
          eventClick={handleEventSelect}
          eventContent={(eventInfo) => {
            if (
              eventInfo.event.extendedProps.type ===
              CALENDAR_EVENT_TYPE.NON_WORKING_HOURS
            ) {
              return (
                <div
                  style={{
                    backgroundImage:
                      'repeating-linear-gradient(45deg, #f2f2f2, #f2f2f2 10px, #fff 10px, #fff 20px)',
                    height: 'calc(100% + 2px)',
                    width: 'calc(100% + 2px)',
                    marginLeft: -2,
                    marginTop: -2,
                  }}
                />
              );
            }

            if (
              eventInfo.event.extendedProps.type === CALENDAR_EVENT_TYPE.BLOCK
            ) {
              return (
                <Stack
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    maxHeight: '100%',
                    color: 'white',
                    fontSize: 12,
                  }}
                >
                  <b>{eventInfo.event.title}</b>
                  <span>{eventInfo.timeText}</span>
                </Stack>
              );
            }

            return (
              <Tooltip
                key={eventInfo.event.id}
                disableFocusListener
                disableTouchListener
                placement='top'
                enterDelay={1000}
                leaveDelay={0}
                title={
                  <Stack direction='column'>
                    <Typography color='inherit' variant='body2'>
                      {eventInfo.event.title}
                    </Typography>

                    <span>
                      {`${eventInfo.event.extendedProps.pet?.name} | ${eventInfo.event.extendedProps.client?.last_name}`}
                    </span>

                    <span>
                      {`${getAppointmentStatusLabel(eventInfo.event.extendedProps.status)} | ${getAppointmentPaymentStatusLabel(eventInfo.event.extendedProps.payment_status)}`}
                    </span>

                    <span>
                      {eventInfo.event.extendedProps.employees[0]?.full_name}
                    </span>

                    {eventInfo.event.start && eventInfo.event.end && (
                      <Stack direction='column'>
                        <span style={{ fontSize: 10 }}>{`${format(
                          eventInfo.event.start,
                          'HH:mm',
                        )} - ${format(eventInfo.event.end, 'HH:mm')}`}</span>
                        <span />
                      </Stack>
                    )}
                  </Stack>
                }
              >
                <Stack
                  data-testid={eventInfo.event.title}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    maxHeight: '100%',
                    color: eventInfo.textColor ?? 'black',
                    fontSize: 12,
                  }}
                >
                  <b>{eventInfo.event.title}</b>

                  <Stack direction='column' alignItems='flex-start'>
                    <Stack direction='row' alignItems='center' gap={0.2}>
                      <IconPaw
                        size={16}
                        color={eventInfo.textColor ?? deepPurple.A400}
                        style={{ flexShrink: 0 }}
                      />

                      <span>{`${eventInfo.event.extendedProps.pet?.name} ${eventInfo.event.extendedProps.client?.last_name ?? ''}`}</span>
                    </Stack>

                    <Stack direction='row' alignItems='center' gap={0.2}>
                      <IconUserShield
                        size={16}
                        color={eventInfo.textColor ?? deepPurple.A400}
                        style={{ flexShrink: 0 }}
                      />

                      <span>
                        {eventInfo.event.extendedProps.employees[0]?.full_name}
                      </span>
                    </Stack>

                    <span style={{ fontSize: 10 }}>{eventInfo.timeText}</span>
                  </Stack>
                </Stack>
              </Tooltip>
            );
          }}
          eventDrop={eventDrop}
          eventOrder='start,-duration,allDay,title'
          eventResize={eventResize}
          events={[...events, ...employeesSpecialHours]}
          headerToolbar={false}
          height={height}
          hiddenDays={hiddenDays}
          initialDate={initialDate}
          initialView={view}
          locale={enLocale}
          nowIndicator
          plugins={[
            interactionPlugin,
            listPlugin,
            timeGridPlugin,
            timelinePlugin,
            resourceTimeGridPlugin,
            resourceTimelinePlugin,
            scrollPlugin,
          ]}
          ref={calendarRef}
          rerenderDelay={10}
          resourceAreaWidth={200}
          resourceLabelContent={(arg) => {
            const { resource } = arg;
            return (
              <Stack direction='row' alignItems='center' spacing={1}>
                <FallbackAvatar
                  src={getImagePublicUrl(resource.extendedProps.avatar_url)}
                  name={resource.extendedProps.full_name}
                  size={28}
                />
                <Stack direction='column'>
                  <Link
                    color='inherit'
                    component={RouterLink}
                    href={paths.employees.details(resource.id)}
                    variant='subtitle2'
                    style={{
                      lineHeight: 1,
                      fontSize: 12,
                      textTransform: 'none',
                    }}
                  >
                    {resource.title}
                  </Link>
                </Stack>
              </Stack>
            );
          }}
          resources={Object.values(employees)?.map((employee) => ({
            id: employee.id.toString(),
            title: displayName(employee.full_name),
            role: employee.role,
            avatar_url: employee.avatar_url,
            working_hours: employee.working_hours,
            full_name: employee.full_name,
          }))}
          resourceOrder='role,full_name'
          scrollTime={format(date, 'HH:mm:ss')}
          select={select}
          selectable
          slotEventOverlap={false}
          slotDuration='00:15:00'
          slotLabelInterval='01:00'
          slotMaxTime={minMaxOpeningTimes.max}
          slotMinTime={minMaxOpeningTimes.min}
          views={{
            timeGridThreeDays: {
              type: 'timeGrid',
              duration: { days: 3 },
              buttonText: '3 day',
            },
            resourceTimeGridThreeDays: {
              type: 'resourceTimeGrid',
              duration: { days: 3 },
              buttonText: '3 day',
            },
          }}
        />

        <Box
          sx={{
            display: isLoading ? 'flex' : 'none',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)', // for Safari
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
          }}
        >
          <Loading />
        </Box>
      </CalendarContainer>
    </Stack>
  );
};

export default CalendarView;
