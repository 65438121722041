import React from 'react';

import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export const SplashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundImage: 'url("/assets/gradient-bg.svg")',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 1000,
    }}
  >
    <Box
      sx={{
        display: 'inline-flex',
      }}
    >
      <ButtonBase
        onClick={() => {
          window.location.href = '/';
        }}
      >
        <img
          alt='logo'
          src='/assets/full-logo.png'
          width={250}
          style={{ cursor: 'pointer', marginRight: 8 }}
        />
      </ButtonBase>
    </Box>
    <Box sx={{ width: '200px', mt: 1 }}>
      <LinearProgress />
    </Box>
  </Box>
);
